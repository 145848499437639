import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function AppFilePicker({text, file, caricaFile}) {

    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'4px', sm:'10px'}
            }}>{text}</Typography>
            <Box sx={{width:'calc(100% - 8px)', borderRadius:'12px', border:'1px solid #DCDCDC', 
                display:'flex', alignItems:'center', padding:'5px 4px'
            }}>
                <input 
                    id="raised-button-file-img"
                    label="Image"
                    value=""
                    onChange={(event) => caricaFile(event)}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                />
                <label htmlFor="raised-button-file-img">
                    <Box
                        component="span"
                        type="submit"
                        sx={{display:'block', borderRadius:'10px', backgroundColor:'#dcdcdc', p:'5px 8px', cursor:'pointer'}}
                    >
                        <Typography noWrap sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal'
                        }}>{file ? 'Cambia file' : 'Seleziona file'}</Typography>
                    </Box>
                </label>
                <Typography noWrap sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', marginLeft:'10px'
                }}>{file ? file.name : 'Nessun file selezionato'}</Typography>
            </Box>
        </Box>
    );
}

export default AppFilePicker;