import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AppTextField from '../generale/AppTextField';
import IconaRazzo from '../../images/razzo.png';

function ZonaCreaChatbot({isPrimo, conferma, loading, agency}) {

    const schema = yup.object().shape({
        chatbotName: yup.string().max(50, "Massimo 50 caratteri.").required('Campo obbligatorio.'),
        scope: yup.string().max(150, "Massimo 150 caratteri.").required('Campo obbligatorio.'),
        openaiKey: yup.string().nullable()
    });
    const {register, handleSubmit, formState: { errors }} = useForm({resolver: yupResolver(schema)});

    return (
        <React.Fragment>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column',
                alignItems:'center', padding:{xs:'40px 0 8px', sm:'25px 0 8px'}
            }}>
                <Box sx={{position:'relative', width:'fit-content'}}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:agency.titleColor,
                        fontSize:{xs:'32px', sm:'45px'}, fontWeight:'700', lineHeight:{xs:'1.1', sm:'normal'}
                    }}>{isPrimo ? 'Crea il tuo primo Chatbot' : 'Crea nuovo Chatbot'}</Typography>
                </Box>
                <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'18px', sm:'22px'}, fontWeight:'600', lineHeight:{xs:'1.1', sm:'1.1'}, 
                    marginTop:{xs:'24px', sm:'25px'}, maxWidth:{xs:'86%', sm:'100%'}
                }}>
                    Inserisci il nome dell'attività per cui <br className='newline-no-mobile'/>
                    sarà utilizzato questo chatbot
                </Typography>
            </Box>
            
            <form
                id="newchatbot-form"
                name="newchatbot-form"
                data-name="New Chatbot Form"
                onSubmit={handleSubmit(conferma)}
            >
                <Box sx={{width:{xs:'calc(100%-16px)', sm:'100%'}, display:'flex', justifyContent:'center', padding:{xs:'0 8px', sm:0}}}>
                    <AppTextField
                        isBianco={true}
                        name={'chatbotName'}
                        isLabel={false}
                        width={'600px'}
                        error={errors.chatbotName?.message}
                        placeholder={'Nome dell\'attività'}
                        register={register("chatbotName", { required: true })}
                    ></AppTextField>
                </Box>

                <Box sx={{padding:{xs:'28px 0 8px', sm:'45px 0 8px'}, display:'flex',
                    flexDirection:'column', alignItems:'center'
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'18px', sm:'22px'}, fontWeight:'600', lineHeight:{xs:'1.1', sm:'1.1'}, 
                        marginTop:{xs:'8px', sm:0}, maxWidth:{xs:'86%', sm:'100%'}
                    }}>
                        Descrivi l'ambito dell'attività <br className='newline-no-mobile'/>
                        per cui sarà utilizzato questo chatbot
                    </Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'12px', sm:'15px'}, fontWeight:'500', width:'95%', marginTop:'4px'
                    }}>
                        ES) una software house e web agency
                    </Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'12px', sm:'15px'}, fontWeight:'500', width:'95%'
                    }}>
                        ES) un azienda di prodotti per la cura della pelle
                    </Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'12px', sm:'15px'}, fontWeight:'500', width:'95%'
                    }}>
                        ES) un'azienda che produce e installa schermature solari
                    </Typography>
                </Box>
                <Box sx={{width:{xs:'calc(100%-16px)', sm:'100%'}, display:'flex', justifyContent:'center', padding:{xs:'0 8px', sm:0}}}>
                    <AppTextField
                        isBianco={true}
                        name={'Scope'}
                        isLabel={false}
                        width={'600px'}
                        error={errors.scope?.message}
                        placeholder={'Ambito dell\'attività'}
                        register={register("scope", { required: true })}
                        multiline={2}
                    ></AppTextField>
                </Box>

                <Box sx={{padding:{xs:'28px 0 8px', sm:'45px 0 8px'}, display:'flex',
                    flexDirection:'column', alignItems:'center'
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'18px', sm:'22px'}, fontWeight:'600', lineHeight:{xs:'1.1', sm:'1.1'}, 
                        marginTop:{xs:'8px', sm:0}, maxWidth:{xs:'86%', sm:'100%'}
                    }}>
                        Inserisci la chiave di OpenAI sulla quale <br className='newline-no-mobile'/>
                        saranno addebitati i costi di AI di questo chatbot
                    </Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'12px', sm:'15px'}, fontWeight:'500', width:'95%', marginTop:'4px'
                    }}>
                        Puoi modificare la chiave anche in seguito, dalla scheda Impostazioni del tuo Chatbot
                    </Typography>
                </Box>
                <Box sx={{width:{xs:'calc(100%-16px)', sm:'100%'}, display:'flex', justifyContent:'center', padding:{xs:'0 8px', sm:0}}}>
                    <AppTextField
                        isBianco={true}
                        name={'openaiKey'}
                        isLabel={false}
                        width={'600px'}
                        error={errors.openaiKey?.message}
                        placeholder={'Chiave OpenAI'}
                        register={register("openaiKey", { required: true, value:agency?.openaiKey })}
                    ></AppTextField>
                </Box>

                <Box sx={{width:'100%', display:'flex', justifyContent:'center', marginTop:{xs:'35px', sm:'45px'}}}>
                    <LoadingButton
                        type='submit'
                        loading={loading}
                        loadingPosition={'end'}
                        variant="contained"
                        color="primary"
                        sx={{padding:'12px 32px', borderRadius:'12px', textTransform:'none', border:'1px solid #dcdcdc'}}
                        endIcon={<Box component='img' alt='' src={IconaRazzo} sx={{width:{xs:'22px', sm:'26px'}, height:'auto'}} />}
                        disableElevation={true}
                    >
                        <Typography sx={{fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal',
                            letterSpacing:'-0.2', textAlign:'left', marginRight:{xs:'6px', sm:'12px'}
                        }}>Crea Chatbot</Typography>
                    </LoadingButton>
                </Box>
            </form>
        </React.Fragment>
    );
}

export default ZonaCreaChatbot;