import './daterange.css'
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ModalDate from './ModalDate';
import AppAlert from '../generale/AppAlert';
import conversationApi from '../../api/conversation';
import useAuth from '../../controllers/auth/useAuth';
import ElencoConversazioni from './ElencoConversazioni';
import RiassuntoStatistiche from './RiassuntoStatistiche';
import useAgency from '../../controllers/agency/useAgency';

import EditIcon from '@mui/icons-material/Edit';

function ZonaStatistiche({visible, chatbot, correggiDomanda, premiCorreggiDomanda, pulisciDomanda}) {

    const {agency} = useAgency();
    const {isAdmin, isEditor} = useAuth();

    //states per le statistiche
    const [loading, setLoading] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);
    const [conversations, setConversations] = useState([]);

    //states per le date
    const [date, setDate] = useState(null)
    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        const aggDate = () => {
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - 7);
            setDate([{
                startDate: startDate,
                endDate: endDate,
                key:'selection'
            }])
        }
        aggDate()
    }, [])

    useEffect(() => {
        const aggStats = async() => {
            const endDate = new Date(date[0].endDate);
            endDate.setDate(endDate.getDate() + 1);
            const startDate = new Date(date[0].startDate);
            const result = await conversationApi.getStatistics(
                agency.id, chatbot.id, startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10)
            );
            if(!result.ok) return
            setConversations(result.data)
        }
        if(visible && date) aggStats()
    }, [visible, date])

    useEffect(() => {
        const aggAlert = () => {
            setOpenAlert(true)
            setConversations(conversations.map((conv) => {
                for(let msg of conv.messages) {
                    if(msg.id == correggiDomanda.id) msg.isRevised = true;
                }
                return conv;
            }))
            setTimeout(() => {
                pulisciDomanda()
            }, 200);
        }
        if(visible && correggiDomanda?.ok) aggAlert()
    }, [visible, correggiDomanda])

    useEffect(() => {
        const aggConversations = async() => {
            setLoading(true)
            const endDate = new Date(date[0].endDate);
            endDate.setDate(endDate.getDate() + 1);
            const startDate = new Date(date[0].startDate);
            const result = await conversationApi.getStatistics(
                agency.id, chatbot.id, startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10)
            );
            if(!result.ok) return
            setTimeout(() => {
                setConversations(result.data)
                setLoading(false)
            }, 500);
        }
        if(date && date[0].startDate && date[0].endDate) aggConversations()
    }, [date])

    const scriviDate = () => {
        if(!date) return ''
        let result = 'da ' + moment(date[0].startDate).format('DD/MM/YYYY');
        result += ' fino a ' + moment(date[0].endDate).format('DD/MM/YYYY');
        return result
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setOpenAlert(false)
    }

    return (
        <React.Fragment>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'title.main',
                fontSize:'18px', fontWeight:'600', lineHeight:'normal', margin:'20px 0 2px 4px',
                display:{xs:visible ? 'block' : 'none', sm:'none'}
            }}>Statistiche</Typography>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:0, sm:'40px 0 0', lg:'40px 40px 0'}, border:'1px solid #dcdcdc', 
                display: visible ? 'block' : 'none', position:'relative'
            }}>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'calc(100% - 60px)'}, padding:{xs:'24px 12px 2px', sm:'30px'}, 
                    display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', position:'relative'
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'20px', sm:'28px'}, fontWeight:'600', lineHeight:'normal',
                        marginBottom:{xs:'4px', sm:'10px'}
                    }}>Statistiche</Typography>

                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{padding:{xs:'8px 20px', sm:'12px 30px'}, borderRadius:'16px', textTransform: 'none'}}
                        onClick={() => setModalVisible(true)}
                        endIcon={<EditIcon sx={{color:'black', width:'20px', height:'auto'}} />}
                        disableElevation={true}
                    >
                        <Typography sx={{fontSize:{xs:'12px', sm:'15px'}, fontWeight:'500', color:'black', 
                            lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginRight:'12px'
                        }}>{scriviDate()}</Typography>
                    </Button>

                    <RiassuntoStatistiche
                        loading={loading}
                        conversations={conversations}
                    ></RiassuntoStatistiche>
                    <ElencoConversazioni
                        chatbot={chatbot}
                        loading={loading}
                        conversazioni={conversations}
                        isReader={!isEditor && !isAdmin}
                        premiCorreggiDomanda={premiCorreggiDomanda}
                    ></ElencoConversazioni>
                </Box>
            </Box>

            <AppAlert
                open={openAlert}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Domanda revisionata con successo!'}
            ></AppAlert>

            <ModalDate
                dateVere={date}
                cambiaDate={setDate}
                visible={modalVisible}
                chiudi={() => setModalVisible(false)}
            ></ModalDate> 
        </React.Fragment>
    );
}

export default ZonaStatistiche;