import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';

import utils from '../../libs/utils';

import FormIcon from './FormIcon';
import CloseIcon from './CloseIcon';
import * as animationData from '../../images/animations/animation.json'
import * as animationWhiteData from '../../images/animations/animation-white.json'

function FormContatti({chatbot, annulla, confermaInvio, contactFormData}) {

    const { t } = useTranslation()

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [telefono, setTelefono] = useState('')
    const [errore, setErrore] = useState(null)

    const configs = chatbot.contacts;
    const isDark = chatbot?.theme === 1;

    const premiInvia = async() => {
        //controllo errori
        if(!nome.length && configs?.fullName) return setErrore(1)
        if(!email.length && configs?.email) return setErrore(2)
        if(!telefono.length && configs?.phone) return setErrore(4)
        //valido email e telefono
        if(configs?.email && !utils.validateEmail(email)) return setErrore(2)
        if(configs?.phone && !utils.validateTelefono(telefono)) return setErrore(4)
        setErrore(0)
        //preparo il messaggio
        let msg = {}
        if(nome?.length) msg.fullName = nome;
        if(email?.length) msg.email = email;
        if(telefono?.length) msg.phone = telefono;
        await confermaInvio(msg)
    }

    if(contactFormData.send) return (
        <div className='contacts-form-container'>
            <div className='contacts-form-compiled'>
                <div className='contacts-icon-send'>
                    <FormIcon fill={chatbot.buttonColor} />
                </div>
                <span className='contacts-form-compiled-text' style={{
                    color:chatbot.buttonColor
                }}>{t('chat.formInviato')}</span>
            </div>
        </div>
    );

    return (
        <div className='contacts-form-container'>
            <div className='contacts-form-zona-chiudi'>
                <button 
                        className='button-close-form-contatto'
                        onClick={annulla}
                    >
                    <CloseIcon fill={isDark ? '#FFF' : '#000000'} />
                </button>
            </div>

            {configs?.fullName && <div className='riga-form-contatto' style={{marginTop:'-3px'}}>
                <span
                    style={{color:isDark ? 'white' : 'black'}}
                    className='label-form-contatto'
                >{t('account.nominativo')}: *</span>
                <input 
                    type="text" aria-label="chat-input-nome" required="" 
                    maxLength="650" className="contatto-text-input" 
                    value={nome} onChange={(e) => setNome(e.target.value)} 
                    style={{color:isDark ? 'white' : 'black', borderColor:errore == 1 ? 'red' : '#dcdcdc'}}
                    autoComplete={'off'} autoCorrect={'off'}
                />
            </div>}
            {configs.email && <div className='riga-form-contatto'>
                <span
                    style={{color:isDark ? 'white' : 'black'}}
                    className='label-form-contatto'
                >{t('login.email')}: *</span>
                <input 
                    type="text" aria-label="chat-input-email" required="" 
                    maxLength="650" className="contatto-text-input" 
                    value={email} onChange={(e) => setEmail(e.target.value)} 
                    style={{color:isDark ? 'white' : 'black', borderColor:errore == 2 ? 'red' : '#dcdcdc'}}
                    autoComplete={'off'} autoCorrect={'off'}
                />
            </div>}
            {configs.phone && <div className='riga-form-contatto'>
                <span
                    style={{color:isDark ? 'white' : 'black'}}
                    className='label-form-contatto'
                >{t('chat.telefono')}: *</span>
                <input 
                    type="text" aria-label="chat-input-telefono" required="" 
                    maxLength="650" className="contatto-text-input" 
                    value={telefono} onChange={(e) => setTelefono(e.target.value)} 
                    style={{color:isDark ? 'white' : 'black', borderColor:errore == 4 ? 'red' : '#dcdcdc'}}
                    autoComplete={'off'} autoCorrect={'off'}
                />
            </div>}

            {contactFormData.errore && <span className='errore-contact-form'>{contactFormData.errore}</span>}

            {!contactFormData.loading && <div className='zona-bottoni-form-contatto'>
                <button
                    onClick={premiInvia}
                    className='bottone-form-contatto'
                    style={{fontWeight:'bold', backgroundColor:chatbot.buttonColor, color:chatbot.secondaryColor}}
                >{t('chat.invia')}</button>
            </div>}
            {contactFormData.loading &&
                <div style={{marginBottom:'-20px', width:'50px'}}>
                    <Lottie 
                        height={'auto'}
                        loop={true}
                        autoPlay={true}
                        animationData={isDark ? animationWhiteData : animationData}
                        rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}}
                    />
                </div>
            }
        </div>
    );
}

export default FormContatti;