import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import RigaAllenamento from './RigaAllenamento';

import IconaRazzo from '../../images/razzo.png';

function ElencoAllenamenti({
    isAvvia, isModifica, trainings, loading, isEditor, completaChatbot, eliminaAllenamento, modificaAllenamento
}) {

    const [cancelLoading, setCancelLoading] = useState(-1)

    if(trainings.length > 0) return (
        <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, flexDirection:'column', alignItems:'center', 
            padding: isAvvia ? {xs:'0 0 24px', sm:'18px 0 20px', lg:'18px 40px 40px'} : {xs:'0 40px 20px', lg:'0 40px 40px'}
        }}>
            <LoadingButton
                loading={loading}
                loadingPosition={'end'}
                variant="contained"
                color="primary"
                sx={{
                    padding:{xs:'8px 46px', sm:'12px 80px'}, width:'100%', borderRadius:'12px',
                    display: isAvvia ? 'flex' : 'none', border:'1px solid #dcdcdc'
                }}
                endIcon={<Box component='img' alt='' src={IconaRazzo} sx={{width:{xs:'22px', sm:'26px'}, height:'auto'}} />}
                disableElevation={true}
                onClick={completaChatbot}
            >
                <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'600', 
                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginRight:{xs:'6px', sm:'12px'}
                }}>Avvia il tuo chatbot</Typography>
            </LoadingButton>

            {trainings.map((training, index) => (
                <RigaAllenamento
                    isEditor={isEditor}
                    isPrimo={!isAvvia && index === 0}
                    key={'all' + index}
                    training={training}
                    isModifica={isModifica}
                    isElimina={cancelLoading === index}
                    premiModifica={() => modificaAllenamento(training)}
                    premiElimina={async() => {
                        setCancelLoading(index)
                        await eliminaAllenamento(training)
                        setCancelLoading(-1)
                    }}
                ></RigaAllenamento>
            ))}
        </Box>
    );
}

export default ElencoAllenamenti;