import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';

function IntestazioneSettings({premiMenu}) {

    return (
        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', marginRight:'-5px'}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                fontSize:{xs:'24px', sm:'36px'}, fontWeight:'700', lineHeight:'normal'
            }}>Account</Typography>

            <IconButton sx={{width:'fit-content', height:'36px', borderRadius:'18px', 
                display:{xs:'flex', sm:'none'}, p:0, m:0
            }} onClick={premiMenu}>
                <MoreVertIcon
                    sx={{width:'auto', height:'36px', cursor:'pointer', zIndex:200, color:'black'}}
                />
            </IconButton>
        </Box>
    );
}

export default IntestazioneSettings;