import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import RigaChatbot from './RigaChatbot';
import BottoneChatbot from './BottoneChatbot';

function ListaChatbots({chatbots, limit, isAdmin, premiNuovo, premiChatbot, loading, isMargineSopra}) {

    const scriviLimite = () => {
        if(!limit) return ''
        if(limit == -1) return <Typography component='span' sx={{
            fontSize:{xs:'22px', sm:'38px'}, color:'primary.main'
        }}>∞</Typography>
        return limit
    }

    return (
        <Box sx={{
            width:'100%', backgroundColor:'white', borderRadius:'20px', 
            marginTop:{xs:'16px', sm:isMargineSopra ? '30px' : '12px'},
            overflow:'hidden', border:'1px solid #dcdcdc'
        }}>
            <Box sx={{width:{xs:'calc(100% - 24px)', sm:'calc(100% - 50px)', lg:'calc(100% - 50px)'}, padding:{xs:'12px', sm:'25px', lg:'25px'}, borderBottom:'1px solid #dcdcdc', 
                display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:{xs:'flex-start', sm:'center'}, justifyContent:'space-between'
            }}>
                <Box sx={{display:'flex', flexDirection:'column', maxWidth:'70%'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'22px', sm:'28px'}, fontWeight:'600', lineHeight:'normal',
                        display:'flex', alignItems:'center'
                    }}>
                        I tuoi chatbot&nbsp;
                        {isAdmin && '(' + chatbots.length + ' / '}
                        {isAdmin && scriviLimite()}
                        {isAdmin && ')'}
                    </Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'15px', sm:'20px'}, fontWeight:'500', lineHeight:'normal', marginTop:'4px',
                        paddingRight:{xs:0, sm:'50px'}
                    }}>
                        Di seguito sono riportati i tuoi bot. 
                        Clicca su uno per configurarlo, addestrarlo o provarlo.
                    </Typography>
                </Box>
                <Box sx={{display:isAdmin ? 'flex' : 'none', margin:{xs:'12px 0 6px', sm:0}}}>
                    <BottoneChatbot
                        text={'Crea Chatbot'}
                        onClick={premiNuovo}
                    ></BottoneChatbot>
                </Box>
            </Box>

            {loading ? (
                <Stack direction='column' spacing='8px' sx={{width:'calc(100% - 40px)', alignItems:'center', p:'20px'}}>
                    <Skeleton variant="rounded" animation="wave" sx={{borderBottom:'1px solid #dcdcdc', width:'100%', height:{xs:'60px', sm:'100px'}}} />
                    <Skeleton variant="rounded" animation="wave" sx={{borderBottom:'1px solid #dcdcdc', width:'100%', height:{xs:'60px', sm:'100px'}}} />
                    <Skeleton variant="rounded" animation="wave" sx={{borderBottom:'0px solid #dcdcdc', width:'100%', height:{xs:'60px', sm:'100px'}}} />
                </Stack>
            ) : (<>
                {chatbots.length === 0 ? (
                    <Box sx={{width:'100%', height:{xs:'200px', sm:'400px'}, display:'flex', 
                        flexDirection:'column', alignItems:'center', justifyContent:'center'
                    }}>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'18px', sm:'24px'}, fontWeight:'500', lineHeight:'normal'
                        }}>
                            {isAdmin ? "Crea il tuo primo Chatbot!" : "Non hai ancora nessun chatbot!"}
                        </Typography>
                        <Box sx={{display:isAdmin ? 'flex' : 'none', marginTop:{xs:'8px', sm:'24px'}}}>
                            <BottoneChatbot
                                text={'Crea Chatbot'}
                                onClick={premiNuovo}
                            ></BottoneChatbot>
                        </Box>
                    </Box>
                ) : (<>
                    {chatbots.map((bot, index) => (
                        <RigaChatbot
                            bot={bot}
                            key={index}
                            premiChatbot={premiChatbot}
                            isUltimo={index === chatbots.length - 1}
                        ></RigaChatbot>
                    ))}
                </>)}
            </>)}
        </Box>
    );
}

export default ListaChatbots;