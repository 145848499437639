import { API_URL, JWT_TOKEN } from "../config";
import storage from '../libs/storage';
import client from "./client";

const endpoint1 = API_URL + '/conversation/'

const getConversation = (conversationId) => {
    return client.get(endpoint1 + 'id/' + conversationId);
}

const createConversation = (data) => {
    return client.post(endpoint1, data);
}

const createMessage = (data) => {
    return client.post(endpoint1 + '/message', data);
}

const createContactMessage = (data) => {
    return client.post(endpoint1 + '/message/contact-form', data);
}

const getStatistics = (agencyId, chatbotId, startDate, endDate) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.get(endpoint1 + chatbotId + '/' + startDate + '/' + endDate, {}, {
        headers:{Authorization:'Bearer ' + token}
    });
}

export default {
    createMessage,
    getStatistics,
    getConversation,
    createConversation,
    createContactMessage
}