import React from 'react';
import moment from 'moment/moment';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';

import Cella from './Cella';

function TabellaUtenti({visitors, visitorsLoading, premiModifica, premiElimina, vediChatbots}) {

    const isVisible = !visitorsLoading && visitors.length > 0;

    const scriviRuolo = (role) => {
        return role[0].toUpperCase() + role.slice(1);
    }

    const scriviNome = (utente) => {
        let result = utente.fullName;
        if(!utente.enabled) {
            result += ' (NON ABILITATO)'
        }
        return result;
    }

    return (
        <TableContainer sx={{height:'fit-content', marginTop:'32px'}}>
            <Table stickyHeader aria-label="sticky table" sx={{
                overflow:'hidden', border:'1px solid #dcdcdc',
                borderTopLeftRadius:'12px', borderTopRightRadius:'12px',
                borderBottomRightRadius:isVisible ? '12px' : 0, borderBottomLeftRadius:isVisible ? '12px' : 0
            }}>

                <TableHead>
                    <TableRow>
                        <Cella
                            text={'Nome Completo'}
                            align={'left'}
                            borderLeft={false}
                            isHeader={true}
                        ></Cella>
                        <Cella
                            text={'Email'}
                            align={'left'}
                            borderLeft={false}
                            isHeader={true}
                        ></Cella>
                        <Cella
                            text={'Ruolo'}
                            align={'center'}
                            borderLeft={false}
                            isHeader={true}
                            width={'15%'}
                        ></Cella>
                        <Cella
                            text={'Data Creazione'}
                            align={'center'}
                            borderLeft={false}
                            isHeader={true}
                            width={'15%'}
                        ></Cella>
                        <Cella
                            text={'Chatbot'}
                            align={'center'}
                            borderLeft={false}
                            isHeader={true}
                            width={'15%'}
                        ></Cella>
                        <Cella
                            width={'10%'}
                            text={'Azioni'}
                            align={'center'}
                            borderRight={false}
                            isHeader={true}
                        ></Cella>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {visitors.map((utente, index) => (
                        <TableRow key={index}>
                            <Cella
                                text={scriviNome(utente)}
                                align={'left'}
                                borderLeft={false}
                                isUltimo={index === visitors.length - 1}
                            ></Cella>
                            <Cella
                                text={utente.email}
                                align={'left'}
                                borderLeft={false}
                                isUltimo={index === visitors.length - 1}
                            ></Cella>
                            <Cella
                                text={scriviRuolo(utente.role)}
                                align={'center'}
                                borderLeft={false}
                                width={'15%'}
                                isUltimo={index === visitors.length - 1}
                            ></Cella>
                            <Cella
                                text={moment(utente.createdAt).format('DD/MM/YYYY')}
                                align={'center'}
                                borderLeft={false}
                                width={'15%'}
                                isUltimo={index === visitors.length - 1}
                            ></Cella>
                            <Cella
                                isChatbots={true}
                                vediChatbots={(event, list) => vediChatbots(event, list)}
                                chatbots={utente.chatbots}
                                text={''}
                                align={'center'}
                                borderLeft={false}
                                width={'15%'}
                                isUltimo={index === visitors.length - 1}
                            ></Cella>
                            <Cella
                                isAzioni={true}
                                text={''}
                                align={'center'}
                                borderRight={false}
                                width={'10%'}
                                premiModifica={() => premiModifica(utente)}
                                premiElimina={() => premiElimina(utente)}
                                isUltimo={index === visitors.length - 1}
                            ></Cella>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {!visitorsLoading && visitors.length == 0 &&
                <Box sx={{height:'300px', width:'100%', backgroundColor:'white', boxSizing:'border-box',
                    display:'flex', alignItems:'center', justifyContent:'center', border:'1px solid #dcdcdc',
                    borderTop:'none', borderBottomLeftRadius:'12px', borderBottomRightRadius:'12px'
                }}>
                    <Typography sx={{
                        fontSize:'17px', fontWeight:'500', lineHeight:'normal', 
                        letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Non ci sono utenti registrati!</Typography>
                </Box>
            }
            {visitorsLoading &&
                <Box sx={{height:'300px', width:'100%', backgroundColor:'white', boxSizing:'border-box',
                    display:'flex', alignItems:'center', justifyContent:'center', border:'1px solid #dcdcdc',
                    borderTop:'none', borderBottomLeftRadius:'12px', borderBottomRightRadius:'12px'
                }}>
                    <CircularProgress sx={{width:'32px', height:'auto'}} color="primary" />
                </Box>
            }
        </TableContainer>
    );
}

export default TabellaUtenti;