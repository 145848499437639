import { API_URL, JWT_TOKEN } from "../config";
import storage from '../libs/storage';
import client from "./client";

const endpoint = API_URL + '/users/'

const getMe = async(agencyId) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.get(endpoint + 'me?withAccount=true', {}, {headers:{
        Authorization:'Bearer ' + token
    }})
}

const getAccount = async(agencyId) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.get(endpoint + 'me/account', {}, {headers:{
        Authorization:'Bearer ' + token
    }})
}

const updateMe = async(agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.put(endpoint + 'me', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}

const changePassword = async(agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.put(endpoint + 'me/change-password', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}

export default {
    getMe,
    updateMe,
    getAccount,
    changePassword
}