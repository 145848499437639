import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BottoneSito from './BottoneSito';
import SitoTextField from '../generale/SitoTextField';

function SceltaLinksSito({isSitemap, isScraping, siti, errore, addLink, removeLink, changeLinkValue}) {

    if(isSitemap) return (
        <Box sx={{margin:{xs:'30px 8px 30px', sm:'50px 25px 50px'}, width:{xs:'calc(100% - 16px)', sm:'calc(100% - 50px)'}}}>
            <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969', marginBottom:{xs:'2px', sm:0},
                fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                maxWidth:{xs:'90%', sm:'100%'}, marginLeft:{xs:'5%', sm:0}
            }}>
                Inserisci la <b>SITEMAP</b> del tuo sito web. <br className='newline-no-mobile'/>
                <b>Attenzione</b>: la durata del procedimento aumenta in base al <b>numero di pagine del sito.</b>
            </Typography>
            <Box sx={{width:{xs:'90%', sm:'60%', lg:'40%'}, 
                marginLeft:{xs:'5%', sm:'20%', lg:'30%'}, marginTop:{xs:'24px', sm:'10px'}
            }}>
                <SitoTextField
                    name={'SitoSitemap'}
                    placeholder={'Sitemap del sito web'}
                    value={siti[0]}
                    error={errore === 0}
                    onChange={(e) => changeLinkValue(0, e.target.value)}
                ></SitoTextField>
            </Box> 
        </Box>
    );

    if(isScraping) return (
        <Box sx={{margin:{xs:'30px 8px 30px', sm:'50px 25px 50px'}, width:{xs:'calc(100% - 16px)', sm:'calc(100% - 50px)'}}}>
            <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969', marginBottom:{xs:'2px', sm:0},
                fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                maxWidth:{xs:'90%', sm:'100%'}, marginLeft:{xs:'5%', sm:0}
            }}>
                Inserisci la Home Page del tuo sito web. <br className='newline-no-mobile'/>
                <b>Attenzione</b>: il procedimento può durare fino a <b>2 minuti</b>.
            </Typography>
            <Box sx={{width:{xs:'90%', sm:'60%', lg:'40%'}, 
                marginLeft:{xs:'5%', sm:'20%', lg:'30%'}, marginTop:{xs:'24px', sm:'10px'}
            }}>
                <SitoTextField
                    name={'SitoDominio'}
                    placeholder={'Home page del sito web'}
                    value={siti[0]}
                    error={errore === 0}
                    onChange={(e) => changeLinkValue(0, e.target.value)}
                ></SitoTextField>
            </Box> 
        </Box>
    );
    
    return (
        <Box sx={{margin:{xs:'30px 8px 30px', sm:'30px 25px 30px'}, width:{xs:'calc(100% - 16px)', sm:'calc(100% - 50px)'}}}>
            <Box sx={{width:'100%', display:'flex', flexDirection:{xs:'column', sm:'row'},
                alignItems:{xs:'flex-start', sm:'flex-end'}, justifyContent:'space-between'
            }}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969', marginBottom:{xs:'2px', sm:0},
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                }}>Hai inserito {siti.length} links.</Typography>
                <BottoneSito
                    tipologia={2}
                    text={'Aggiungi Link'}
                    onClick={() => addLink()}
                ></BottoneSito>
            </Box>
            <Stack direction='column' spacing={{xs:'20px', sm:'20px'}} sx={{width:'100%', marginTop:{xs:'24px', sm:'10px'}}}>
                {siti.map((sito, index) => (
                    <Stack key={'site' + index} direction={{xs:'column', sm:'row'}} 
                        spacing={{xs:'6px', sm:'12px'}} sx={{width:'100%'}}
                    >
                        <SitoTextField
                            name={'Sito' + index}
                            placeholder={'Link pagina web'}
                            value={sito}
                            error={errore === index}
                            onChange={(e) => changeLinkValue(index, e.target.value)}
                        ></SitoTextField>
                        <BottoneSito
                            tipologia={1}
                            text={'Rimuovi Link'}
                            onClick={() => removeLink(index)}
                        ></BottoneSito>
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
}

export default SceltaLinksSito;