import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function IntestazioneAllenamento({situazione, goBack, fasi, faseAttuale}) {

    const scriviIcona = () => {
        if(situazione === 1) return <ArticleIcon sx={{width:'25px', height:'auto'}} color='black' />
        if(situazione === 2) return <ModeEditIcon sx={{width:'25px', height:'auto'}} color='black' />
        if(situazione === 3) return <LanguageIcon sx={{width:'25px', height:'auto'}} color='black' />
        if(situazione === 4) return <QuestionAnswerIcon sx={{width:'25px', height:'auto'}} color='black' />
    }

    const scriviTitolo = () => {
        if(situazione === 1) return 'DOCUMENTI'
        if(situazione === 2) return 'TESTO'
        if(situazione === 3) return 'SITO WEB'
        if(situazione === 4) return 'Q / A'
    }

    return (
        <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', 
            position:'relative', padding:{xs:'42px 0 0', sm:0}
        }}>
            {scriviIcona()}
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                fontSize:{xs:'15px', sm:'20px'}, fontWeight:'700', lineHeight:'normal', marginLeft:{xs:'8px', sm:'16px'}
            }}>{scriviTitolo()}</Typography>

            <Box sx={{position:'absolute', top:{xs:'-16px', sm:'-20px'}, left:{xs:'8px', sm:'16px'}, padding:'5px 14px', backgroundColor:'#dcdcdc', 
                display:'flex', flexDirection:'row', alignItems:'center', borderRadius:'20px', cursor:'pointer'
            }} onClick={() => goBack()}>
                <KeyboardBackspaceIcon sx={{width:'20px', height:'auto'}} color='black' />
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', marginLeft:'6px'
                }}>Indietro</Typography>
            </Box>

            <Box sx={{position:'absolute', top:{xs:'-16px', sm:'-20px'}, right:{xs:'8px', sm:'16px'}, 
                display:'flex', flexDirection:'column', justifyContent:'center'
            }}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                    fontSize:{xs:'14px', sm:'20px'}, fontWeight:'500', lineHeight:'normal'
                }}>
                    Fase&nbsp;
                    <Typography component='span' sx={{
                        fontSize:{xs:'14px', sm:'20px'}, fontWeight:'bold', color:'primary.main'
                    }}>{faseAttuale}</Typography>
                    &nbsp;di {fasi}
                </Typography>
            </Box>
        </Box>
    );
}

export default IntestazioneAllenamento;