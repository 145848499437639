import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function ZonaCodice({codice, copia}) {

    return (
        <Box sx={{marginTop:{xs:'10px', sm:'20px'}, padding:{xs:'12px', sm:'18px 30px'}, backgroundColor:'#F0F2FA', 
            borderRadius:'20px', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'
        }}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                fontSize:{xs:'15px', sm:'21px'}, fontWeight:'600', lineHeight:'normal',
                maxWidth:{xs:'90%', sm:'auto'}, overflow:'hidden'
            }}>
                {codice}
            </Typography>
            <IconButton sx={{marginLeft:{xs:0, sm:'50px'}, marginRight:{xs:'-5px', sm:'20px'}}} onClick={copia}>
                <ContentCopyIcon sx={{width:{xs:'20px', sm:'30px'}, height:'auto'}} color='#7C7C7C' />
            </IconButton>
        </Box>
    );
}

export default ZonaCodice;