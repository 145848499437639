import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import VisibilityIcon from '@mui/icons-material/Visibility';

export const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        margin: 0,
        borderRadius: 0,
        border:'none',
        color:'black',
        fontSize:'inherit',
        '& fieldset': {
            borderWidth:0,
        },
        '&:hover fieldset': {
            borderWidth:0,
        },
        '&.Mui-focused fieldset': {
            borderWidth:0,
            borderColor: 'primary.main'
        }
    },
    "& .MuiOutlinedInput-input": {
        padding: 0,
        color:'black'
    }
})

function AppTextField({
    name, placeholder, register, error, isLabel, width, multiline, isPassword, isBianco
}) {

    const [visible, setVisible] = useState(isPassword ? false : true)

    return (
        <Box sx={{display:'flex', flexDirection:'column', width:{xs:'100%', sm:width}}}>
            {isLabel && <Typography sx={{fontSize:'14px', fontWeight:'500', marginBottom:{xs:'4px', sm:'8px'},
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'black'
            }}>{name}</Typography>}
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', 
                width: width ? {xs:'100%', sm:width} : {xs:'100%', sm:'350px'}, maxWidth:'100%',
                borderRadius: '12px', border:'1px solid #DCDCDC', justifyContent:'space-between',
                backgroundColor: isBianco ? 'white' : 'transparent', fontSize:{xs:'13px', sm:'16px'}
            }}>
                <StyledTextField
                    id={name + new Date().toISOString()}
                    variant="outlined"
                    placeholder={placeholder}
                    {...register}
                    InputProps={{style:{color:"#737373"}, autoComplete:"off", autoCorrect: "off"}}
                    sx={{
                        height: Boolean(multiline) ? 'auto' : '40px', 
                        display:'flex', justifyContent:'center',
                        padding: Boolean(multiline) ? {xs:'0 2px', sm:'0 12px'} : {xs:'0 12px', sm:'0 24px'}
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                    autoComplete={undefined}
                    multiline={Boolean(multiline)}
                    minRows={Boolean(multiline) ? multiline : 1}
                    maxRows={Boolean(multiline) ? 5 : 1}
                    fullWidth
                    type={(isPassword && !visible) ? 'password' : 'text'}
                />
                {isPassword &&
                    <IconButton onClick={() => setVisible(!visible)}>
                        <VisibilityIcon sx={{width:'20px', height:'auto', color:visible ? 'black' : '#9b9999'}} />
                    </IconButton>
                }
            </Box>
            {Boolean(error) &&
                <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, marginBottom:{xs:'4px', sm:'8px'}, fontWeight:'600',
                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'#FC2A1B'
                }}>{error}</Typography>
            }
        </Box>
    );
}

export default AppTextField;