import React from 'react';

import ReloadIcon from './ReloadIcon';

function HeaderChatFrame({chatbot, reloadChat}) {

    const isDark = chatbot?.theme === 1;
    const isFoto = Boolean(chatbot?.buttonPhoto)

    if(!chatbot) return(
        <div className='chat-header' style={{backgroundColor: isDark ? 'black' : 'white'}} />
    );
    
    if(chatbot) return (
        <div 
            className='chat-header'
            style={{backgroundColor:chatbot.buttonColor}}
        >
            <div className='zona-titolo-header'>
                {isFoto &&
                    <img src={chatbot.buttonPhoto} alt='' className='logo-header' />
                }
                <div style={{marginLeft: isFoto ? '12px' : 0}}>
                    <span 
                        className='titolo-header'
                        style={{color:chatbot.secondaryColor}}
                    >{chatbot.displayName}</span>
                    <div className='riga-pallino'>
                        <div className='pallino-online' />
                        <span 
                            className='online-header'
                            style={{color:chatbot.secondaryColor}}
                        >Online</span>
                    </div>
                </div>
            </div>
            
            <button 
                className='header-button'
                onClick={() => reloadChat()}
            >
                <ReloadIcon fill={chatbot.secondaryColor} />
            </button>
        </div>
    );
}

export default HeaderChatFrame;