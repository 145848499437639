import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import authApi from '../api/auth';
import useAuth from '../controllers/auth/useAuth';
import useAgency from '../controllers/agency/useAgency';
import ZonaAccount from '../components/account/ZonaAccount';
import MenuSettings from '../components/account/MenuSettings';
import useAbbonamento from '../controllers/abbonamento/useAbbonamento';
import ZonaGestioneUtenti from '../components/utenti/ZonaGestioneUtenti';
import LoadingPaginaSettings from '../components/generale/LoadingPaginaSettings';
import IntestazioneSettings from '../components/intestazione/IntestazioneSettings';

function Settings(props) {

    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const {agency} = useAgency()
    const {subscription} = useAbbonamento()
    const {user, isAdmin, aggUser, logout} = useAuth()

    const [situazione, setSituazione] = useState(1)
    const [menuVisible, setMenuVisible] = useState({open:false, anchorVariabili:null})

    useEffect(() => {
        const aggSituazione = () => {
            if(state?.situazione) setSituazione(state.situazione);
        }
        aggSituazione()
    }, [state])

    if(!subscription || !user) return(
        <Box sx={{
            position:'relative', 
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'16px 12px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <IntestazioneSettings 
                premiMenu={() => null}
            />

            <LoadingPaginaSettings
                situazione={situazione}
            ></LoadingPaginaSettings>
        </Box>
    );
    
    return (
        <Box sx={{
            position:'relative', 
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'16px 12px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <IntestazioneSettings
                premiMenu={(event)  => setMenuVisible({open:true, anchorVariabili:event.currentTarget})}
            />

            <MenuSettings
                situazione={situazione}
                cambiaSituazione={(v) => {
                    setSituazione(v)
                    navigate(pathname, {replace:true, state:{situazione:v}})
                }}
                isAdmin={isAdmin}
                open={menuVisible.open}
                anchorEl={menuVisible.anchorVariabili}
                onClose={() => setMenuVisible({open:false, anchorVariabili:null})}
            ></MenuSettings>

            <ZonaAccount
                user={user}
                agency={agency}
                logout={() => {
                    logout()
                    authApi.logout(agency.id)
                    navigate('/login')
                }}
                aggUser={aggUser}
                visible={situazione === 1}
            ></ZonaAccount>

            {isAdmin && <ZonaGestioneUtenti
                agency={agency}
                visible={situazione === 2}
            ></ZonaGestioneUtenti>}
        </Box>
    );
}

export default Settings;