import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { addDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { DateRange } from 'react-date-range'

import BottoneLogin from '../login/BottoneLogin';

function ModalDate({visible, chiudi, dateVere, cambiaDate}) {

    const [date, setDate] = useState([{
        startDate: null, endDate: new Date(""), key:'selection'
    }])
    const dateValide = Boolean(date[0].startDate) && Boolean(date[0].endDate)

    useEffect(() => {
        const aggDate = () => {
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - 7);
            setDate([{
                startDate: startDate,
                endDate: endDate,
                key:'selection'
            }])
        }
        aggDate()
    }, [])

    useEffect(() => {
        const syncDate = () => {
            setDate(dateVere)
        }
        if(visible) syncDate()
    }, [visible, dateVere])

    const conferma = () => {
        cambiaDate(date)
        chiudi()
    }
    
    return (
        <Modal
            open={visible}
            closeAfterTransition
            keepMounted
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', 
                width:'100%', height:'100%', position:'relative', backgroundColor:'rgba(0, 0, 0, 0.2)'
            }} onClick={chiudi}>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'600px'}, backgroundColor:'white', 
                    borderRadius:'16px', border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    padding:{xs:'16px 12px', sm:'30px 4px', md:'30px 30px'}, display:{xs:'none', sm:'flex'}, flexDirection:'column',
                    margin:{xs:'16px 12px', sm:0}, height:{xs:'calc(100% - 56px)', sm:'fit-content'}, 
                    overflowY:{xs:'scroll', sm:'auto'}
                }} onClick={(e) => e.stopPropagation()}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'19px', sm:'24px'}, fontWeight:'600', lineHeight:'normal',
                        marginBottom: {xs:'4px', sm:'10px'}
                    }}>Seleziona le date</Typography>

                    <DateRange 
                        onChange={item => {
                            const appo = {...item.selection}
                            setDate([appo])
                        }}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        maxDate={addDays(new Date(), 0)}
                        months={2}
                        direction='horizontal'
                        locale={it}
                        showMonthAndYearPickers={false}
                        rangeColors={['black']}
                        showDateDisplay={false}
                        weekdayDisplayFormat="EEEEEE"
                        dayDisplayFormat="d"
                        monthDisplayFormat="LLLL Y"
                    />

                    <Stack direction='row' spacing='32px' sx={{
                        paddingTop:{xs:'20px', sm:'30px'}, paddingLeft:'15%'
                    }}>
                        <LoadingButton
                            variant={"contained"}
                            color={"primary"}
                            loading={false}
                            sx={{
                                padding:{xs:'5px 24px', sm:'9px 42px'}, borderRadius:'12px', textTransform: 'none',
                                minWidth:'180px', border:'1px solid #dcdcdc'
                            }}
                            onClick={() => conferma()}
                            disableElevation={true}
                            disabled={!dateValide}
                        >
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'600', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>Conferma</Typography>
                        </LoadingButton>
                        <LoadingButton
                            variant={"contained"}
                            color={"nero"}
                            loading={false}
                            sx={{
                                padding:{xs:'5px 24px', sm:'9px 42px'}, borderRadius:'12px', textTransform: 'none',
                                marginLeft:'24px', minWidth:'180px', border:'1px solid #dcdcdc'
                            }}
                            onClick={() => chiudi()}
                            disableElevation={true}
                        >
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'600', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>Annulla</Typography>
                        </LoadingButton>
                    </Stack>
                </Box>

                <Box sx={{width:{xs:'100%', sm:'600px'}, backgroundColor:'white', 
                    borderRadius:'16px', border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    padding:{xs:'16px 0px', sm:'25px 30px'}, display:{xs:'flex', sm:'none'}, flexDirection:'column',
                    margin:{xs:'16px 12px', sm:0}, height:{xs:'fit-content', sm:'fit-content'}, 
                    overflowY:{xs:'scroll', sm:'auto'}
                }} onClick={(e) => e.stopPropagation()}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'21px', sm:'24px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Seleziona le date</Typography>

                    <DateRange 
                        onChange={item => {
                            const appo = {...item.selection}
                            setDate([appo])
                        }}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        maxDate={addDays(new Date(), 0)}
                        months={1}
                        direction='vertical'
                        locale={it}
                        showMonthAndYearPickers={false}
                        rangeColors={['black']}
                        showDateDisplay={false}
                        weekdayDisplayFormat="EEEEEE"
                        dayDisplayFormat="d"
                        monthDisplayFormat="LLLL Y"
                    />

                    <Stack direction='row' spacing='32px' sx={{
                        padding:{xs:'20px 0 10px', sm:'30px 0 0'}, width:'60%', marginLeft:'15%'
                    }}>
                        <BottoneLogin
                            isBlue={true}
                            text={'Conferma'}
                            loading={false}
                            onClick={() => conferma()}
                            disabled={!dateValide}
                        ></BottoneLogin>
                        <BottoneLogin
                            text={'Annulla'}
                            loading={false}
                            onClick={() => chiudi()}
                            disabled={false}
                        ></BottoneLogin>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalDate;