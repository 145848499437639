import { useContext } from "react";

import VisitorContext from "./context";

const useVisitor = () => {

    const { visitors, setVisitors } = useContext(VisitorContext);

    const addVisitor = (value) => {
        const appo = [...visitors.list]
        appo.push(value)
        setVisitors({loading:false, list:appo})
    }

    const updateVisitor = (value) => {
        const appo = [...visitors.list]
        for(let i = 0; i < appo.length; i++) {
            if(appo[i].id == value.id) appo[i] = {...value}
        }
        setVisitors({loading:false, list:appo})
    }

    const removeVisitor = (id) => {
        const appo = [...visitors.list].filter((el) => el.id != id)
        setVisitors({loading:false, list:appo})
    }

    return { 
        visitors: visitors.list,
        visitorsLoading: visitors.loading,

        addVisitor,
        updateVisitor,
        removeVisitor
    }
}

export default useVisitor;