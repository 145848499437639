import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import utils from '../../libs/utils';
import BottoneSito from './BottoneSito';
import chatbotApi from '../../api/chatbot';
import BottoneAllena from './BottoneAllena';
import BottoneUpload from './BottoneUpload';
import useAgency from '../../controllers/agency/useAgency';
import IntestazioneAllenamento from '../intestazione/IntestazioneAllenamento';

function AllenamentoDocumenti({
    situazione, loading, loadingAllena, goBack, cambiaAltro, 
    conferma, charsLimitXBot, caratteriAttuali, premiUpgrade
}) {

    const {agency} = useAgency();

    const [caricati, setCaricati] = useState([])
    const isCaricati = Boolean(caricati.length > 0)

    useEffect(() => {
        const clear = () => {
            setCaricati([])
        }
        if(situazione == 0) clear()
    }, [situazione])

    const caricaFiles = async(event) => {
        if(loading) return;
        cambiaAltro({loading:true, errore:null})
        //preparo i files
        const files = event.target.files;
        const fileArray = Array.from(files);
        let formData = new FormData();
        for(let index in fileArray) {
            formData.append('file' + index, fileArray[index], fileArray[index].name);
        }
        //invio al server
        const result = await chatbotApi.readFiles(agency.id, formData);
        if(!result.ok) {
            return cambiaAltro({loading:false, errore:result.data})
        }
        //aggiorno i dati
        setCaricati([...caricati, ...result.data.files]);
        cambiaAltro({errore:null, loading:0, visible:false});
    }

    const removeFile = (pos) => {
        const appo = caricati.filter((f, index) => index != pos)
        setCaricati(appo)
    }

    const contaTotaleCaratteri = () => {
        let cont = 0
        for(let i = 0; i < caricati.length; i++) cont += caricati[i].cont
        return cont
    }

    const isFuoriCaratteri = () => {
        if(charsLimitXBot === -1) return false;
        let cont = contaTotaleCaratteri()
        if(cont + caratteriAttuali >= charsLimitXBot) return true
        return false
    }

    const clickConferma = async() => {
        if(isFuoriCaratteri()) return cambiaAltro({errore:'Limite di caratteri superato.'})
        let references = []
        for(let i = 0; i < caricati.length; i++) {
            references.push(caricati[i].fileName)
        }
        await conferma(caricati, caricati[0].fileName, 'documents', references)
    }

    const scriviSottotitolo = () => {
        if(!isCaricati) return "Aggiungi i documenti che vuoi scansionare"
        return "Conferma i contenuti prestando attenzione al limite di caratteri"
    }

    return (
        <Box sx={{width:'100%', padding:{xs:'24px 0', sm:'40px 0'}, display:'flex', 
            flexDirection:'column', alignItems:'center', justifyContent:'center'
        }}>
            <IntestazioneAllenamento
                situazione={1}
                goBack={goBack}
                fasi={2}
                faseAttuale={isCaricati ? 2 : 1}
            ></IntestazioneAllenamento>
            <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969', padding:{xs:'0 24px', sm:0},
                fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, marginTop:{xs:'8px', sm:'12px'}
            }}>{scriviSottotitolo()}</Typography>

            {!isCaricati ? (
                <Box sx={{margin:{xs:'30px 25px 30px', sm:'90px 25px 50px'}, width:'calc(100% - 50px)', 
                    display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                        marginTop:{xs:'8px', sm:'12px'}
                    }}>
                        Allega i tuoi file e guarda da quanti caratteri sono composti.<br className='newline-no-mobile'/>
                        <Typography component='span' sx={{display:{xs:'inline-block', sm:'none'}}}>&nbsp;</Typography>
                        Dimensione massima consentita: 52MB
                    </Typography>
                    <Box sx={{marginTop:'24px'}}>
                        <input 
                            id="raised-button-file-1"
                            label="Allegato"
                            value=""
                            onChange={(event) => caricaFiles(event)}
                            type="file"
                            accept='application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain'
                            multiple
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="raised-button-file-1">
                            <BottoneUpload
                                text={'Upload'}
                                loading={loading}
                            ></BottoneUpload>
                        </label>
                    </Box>
                </Box>
            ) : (<>
                <Box sx={{margin:{xs:'25px 8px 30px', sm:'60px 25px 40px'}, width:{xs:'calc(100% - 16px)', sm:'calc(100% - 50px)'}, 
                    display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'
                }}>
                    <Box sx={{width:'100%', display:'flex', flexDirection:{xs:'column', sm:'row'}, 
                        alignItems:{xs:'flex-start', sm:'flex-end'}, justifyContent:'space-between'
                    }}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', marginBottom:{xs:'2px', sm:0},
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Hai caricato {caricati.length} files con {utils.formatNumber(contaTotaleCaratteri())} caratteri totali.
                        </Typography>
                        <input 
                            id={"raised-button-file-2"}
                            label="Allegato"
                            value=""
                            onChange={(event) => caricaFiles(event)}
                            type="file"
                            accept='application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain'
                            multiple
                            style={{ display: 'none' }}
                        />
                        <label htmlFor={"raised-button-file-2"}>
                            <BottoneUpload
                                isPiccolo={true}
                                loading={loading}
                                text={'Aggiungi File'}
                            ></BottoneUpload>
                        </label>
                    </Box>
                    <Stack direction='column' spacing={{xs:'20px', sm:'20px'}} sx={{width:'100%', marginTop:{xs:'24px', sm:'16px'}}}>
                        {caricati.map((file, index) => (
                            <Stack key={'file' + index} direction={{xs:'column', sm:'row'}} 
                                spacing={{xs:'6px', sm:'12px'}} sx={{width:'100%'}}
                            >
                                <Box sx={{display:'flex', flex:1, padding:{xs:'4px 8px', sm:'8px 24px'}, 
                                    borderRadius:'12px', border:'1px solid #DCDCDC', overflow:'hidden'
                                }}>
                                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', maxWidth:'100%'
                                    }}>{file.fileName}</Typography>
                                </Box>
                                <Box sx={{display:'flex', width:{xs:'fit-content', sm:'160px'}, 
                                    padding:{xs:'4px 8px', sm:'8px 24px'}, 
                                    borderRadius:'12px', border:'1px solid #DCDCDC'
                                }}>
                                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal'
                                    }}>{utils.formatNumber(file.cont)} caratteri</Typography>
                                </Box>
                                <BottoneSito
                                    tipologia={1}
                                    text={'Rimuovi File'}
                                    onClick={() => removeFile(index)}
                                ></BottoneSito>
                            </Stack>
                        ))}
                    </Stack>
                </Box>
                {isFuoriCaratteri() &&
                    <Box sx={{marginBottom:{xs:'24px', sm:'40px'}, marginTop:{xs:0, sm:'10px'}, display:'flex', 
                        flexDirection:'column', alignItems:'center', width:{xs:'70%', sm:'100%'}
                    }}>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Il numero massimo di caratteri consentiti per Chatbot è di {utils.formatNumber(charsLimitXBot)}.
                        </Typography>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Confermando l'allenamento il totale di caratteri arriverà a&nbsp;
                            <span style={{color:'#FC2A1B', fontWeight:'bold'}}>{utils.formatNumber(contaTotaleCaratteri() + caratteriAttuali)}</span>.
                        </Typography>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Esegui&nbsp;
                            <Typography component='span' sx={{display:'inline-block', fontSize:{xs:'13px', sm:'16px'}, cursor:'pointer',
                                color:'primary.main', fontWeight:'700', textDecoration:'underline'
                            }} onClick={premiUpgrade}>l'upgrade</Typography>
                            &nbsp;per aumentare il limite di caratteri.
                        </Typography>
                    </Box>
                }
                <BottoneAllena
                    isConferma={true}
                    loading={loadingAllena}
                    onClick={async() => clickConferma()}
                    text={loadingAllena ? 'Magia AI in corso' : 'Allena Chatbot'}
                ></BottoneAllena>
            </>)}
        </Box>
    );
}

export default AllenamentoDocumenti;