import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ChatIcon from './ChatIcon';
import ReloadIcon from './ReloadIcon';
import CancelIcon from './CancelIcon';
import SendIcon from '@mui/icons-material/Send';

function AnteprimaChat({impostazioni, startMessages, suggestedQuestions, previewFoto}) {

    const isFoto = impostazioni?.buttonPhoto?.length > 0 || previewFoto;
    const isNomeChat = Boolean(impostazioni?.displayName?.length);
    const isRight = impostazioni?.buttonPosition === 1;
    const isDark = impostazioni?.theme === 1;

    const [question, setQuestion] = useState(-1)

    const scriviQuestion = (quest) => {
        let risultato = quest.match(/\[(.*?)\]/g);
        if(!risultato) return quest;
        return risultato[0].replace('[', '').replace(']', '');
    }

    return (
        <Box sx={{display:'block', height:'100%'}}>
            <Box sx={{width:{xs:1, sm:1}, borderRadius:'12px', padding:0, overflow:'hidden',
                display:'flex', flexDirection:'column', justifyContent:'space-between', height:{xs:'460px', md:'min(750px, 60vh)'},
                backgroundColor:isDark ? 'black' : 'white', border:isDark ? '1px solid white' : '1px solid #dcdcdc'
            }}>
                <Box><Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', boxSizing:'border-box',
                    width:1, backgroundColor:impostazioni.buttonColor, padding:{xs:'12px 12px', sm:'12px 12px'}
                }}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        {isFoto &&
                            <Box component={'img'} alt='' 
                                src={previewFoto ? previewFoto : impostazioni.buttonPhoto}
                                sx={{width:{xs:'34px', sm:'40px'}, height:{xs:'34px', sm:'40px'}}}
                            />
                        }
                        <Box sx={{marginLeft:isFoto ? '14px' : '8px'}}>
                            <Typography sx={{
                                textAlign:'left', color:impostazioni.secondaryColor,
                                letterSpacing:'-0.2', fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal'
                            }}>{impostazioni.displayName}</Typography>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <Box sx={{width:'10px', height:'10px', borderRadius:'10px',
                                    marginRight:'6px', backgroundColor:'#13dd21'
                                }} />
                                <Typography sx={{
                                    textAlign:'left', color:impostazioni.secondaryColor,
                                    letterSpacing:'-0.2', fontSize:{xs:'10px', sm:'11px'}, fontWeight:'500'
                                }}>Online</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Stack direction='row' spacing={{xs:'16px', sm:'24px'}} sx={{mr:'4px'}}>
                        <ReloadIcon fill={impostazioni.secondaryColor} />
                        <CancelIcon fill={impostazioni.secondaryColor} />
                    </Stack>
                </Box>
                <Box sx={{display:'flex', flexDirection:'column',
                    paddingTop:{xs:'10px', sm:'12px'}, px:{xs:'8px', sm:'12px'}
                }}>
                    {startMessages.map((msg, index) => (
                        <React.Fragment key={'start' + index}>
                            {isNomeChat &&
                                <Typography sx={{margin:'0 0 3px 3px',
                                    textAlign:'left', color:isDark ? 'white' : 'black',
                                    letterSpacing:'-0.2', fontSize:'12px', fontWeight:'500', lineHeight:'normal'
                                }}>{impostazioni.displayName}</Typography>
                            }
                            <Box sx={{backgroundColor:impostazioni.botMessageColor, borderRadius:'8px', 
                                padding:{xs:'7px 10px', sm:'9px 14px'}, marginBottom:'14px', width:'fit-content'
                            }}>
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:impostazioni.botTextColor,
                                    fontSize:{xs:'13px', sm:'14px'}, fontWeight:'300', lineHeight:'normal'
                                }}>{msg}</Typography>
                            </Box>
                        </React.Fragment>
                    ))}
                    <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
                        <Box sx={{backgroundColor:impostazioni.userMessageColor, borderRadius:'8px', 
                            padding:{xs:'7px 10px', sm:'9px 14px'}, marginBottom:'12px', width:'fit-content'
                        }}>
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:impostazioni.userTextColor,
                                fontSize:{xs:'13px', sm:'14px'}, fontWeight:'300', lineHeight:'normal'
                            }}>Ciao, di che cosa vi occupate?</Typography>
                        </Box>
                    </Box>
                </Box></Box>
                <Box sx={{display:'block', padding:{xs:'0 8px 12px', sm:'0 12px 15px'}}}>
                    {suggestedQuestions.length > 0 &&
                        <Box component='ul' sx={{textAlign:'left', width:'90%',
                            padding:0, margin:0, paddingInlineStart:0, marginBottom:0,
                        }}>
                            {suggestedQuestions.map((quest, index) => (
                                <Box 
                                    component='li' key={'quest' + index} 
                                    sx={{fontSize:{xs:'11px', sm:'12px'},
                                        padding:{xs:'3px 6px', sm:'4px 8px'}, backgroundColor:question === index ? (impostazioni.buttonColor) : (isDark ? 'black' : 'white'),
                                        marginRight:'12px', fontWeight:500, color:question === index ? impostazioni.secondaryColor : (isDark ? 'white' : 'black'),
                                        position:'relative', display:'inline-block', textAlign:'center', cursor:'pointer', whiteSpace:'nowrap',
                                        marginBottom:'10px', border:'1px solid ' + impostazioni.buttonColor, borderRadius:'8px'
                                    }}
                                    onMouseOver={() => setQuestion(index)}
                                    onMouseLeave={() => setQuestion(-1)}
                                >{scriviQuestion(quest)}</Box>
                            ))}
                        </Box>
                    }
                    <Box sx={{width:1, border:isDark ? '1px solid white' : '1px solid #dcdcdc', alignItems:'center', boxSizing:'border-box',
                        display:'flex', justifyContent:'space-between', padding:{xs:'6px 8px', sm:'8px 10px'}, borderRadius:'8px'
                    }}>
                        <Typography sx={{
                            textAlign:'left', color:'#737373',
                            letterSpacing:'-0.2', fontSize:{xs:'13px', sm:'15px'}, fontWeight:'400', lineHeight:'normal'
                        }}>Scrivi qui la tua domanda...</Typography>
                        <SendIcon sx={{width:{xs:'20px', sm:'24px'}, height:'auto', color:impostazioni.buttonColor}} />
                    </Box>
                </Box>
            </Box>
            
            <Box sx={{width:'100%', display:'flex', justifyContent:isRight ? 'flex-end' : 'flex-start', 
                marginTop:{xs:'12px', sm:'16px'}, marginBottom:{xs:'12px', sm:0}
            }}>
                <Box sx={{width:{xs:'44px', sm:'50px'}, height:{xs:'44px', sm:'50px'}, borderRadius:'25px', backgroundColor:impostazioni.buttonColor,
                    display:'flex', alignItems:'center', justifyContent:'center', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                }}>
                    {isFoto ? (
                        <Box component='img' alt='' src={previewFoto ? previewFoto : impostazioni.buttonPhoto} sx={{
                            width:{xs:'33px', sm:'38px'}, height:{xs:'33px', sm:'38px'}, objectFit:'contain'
                        }}/>
                    ) : (
                        <ChatIcon fill={impostazioni.secondaryColor} />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default AnteprimaChat;