import React from 'react';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy({buttonColor, secondaryColor, link, accettaPolicy}) {

    const { t } = useTranslation()
    
    return (
        <div className='zona-privacy-policy'>
            <img src='https://cdn.supportfast.ai/privacy-policy.png' alt='' className='img-privacy-policy' />
            <span 
                className='titolo-privacy-policy'
                style={{color:buttonColor}}
            >{t('chat.privacy').toUpperCase()}</span>
            <span className='testo-privacy-policy'>
                {t('chat.testoPrivacy1')}&nbsp;
                {t('chat.testoPrivacy2')}&nbsp;
                <a
                    className='link-privacy-policy' href={link} target='_blank'
                    style={{color:buttonColor}}
                >{t('chat.privacy')}</a>.
            </span>
            <button
                onClick={accettaPolicy}
                className='bottone-privacy-policy'
                style={{border:'1px solid ' + buttonColor, fontWeight:'bold', 
                    backgroundColor:buttonColor, color:secondaryColor
                }}
            >{t('chat.accetto')}</button>
        </div>
    );
}

export default PrivacyPolicy;