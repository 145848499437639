import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import userApi from '../../api/user';
import ModalLogout from './ModalLogout';
import ZonaPassword from './ZonaPassword';
import BottoniAccount from './BottoniAccount';
import AppAlert from '../generale/AppAlert';
import AppSelect from '../generale/AppSelect';
import AppTextField from '../generale/AppTextField';

import LogoIT from '../../images/it.png';
import LogoEN from '../../images/en.png';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

const IconGoogle = 'https://cdn.supportfast.ai/g-logo.png';
const languages = [
    {id:'it', name:'Italiano'},
    {id:'en', name:'Inglese'}
]

function ZonaAccount({visible, user, agency, aggUser, logout}) {

    const [isPwd, setIsPwd] = useState(false)
    const [altro, setAltro] = useState({
        loading:false, errore:null, modifiche:false, openAlert:0, logout:false
    });

    const isGoogle = Boolean(user?.isGoogle);

    const schema = yup.object().shape({
        fullName: yup.string().required('Il nome completo è obbligatorio.'),
        language: yup.string().required('La lingua è obbligatoria.'),
    });
    const {register, handleSubmit, getValues, setValue, clearErrors, formState: { errors }} = useForm({
        resolver: yupResolver(schema), defaultValues: {
            language: user ? user.language : 'en'
        }
    });

    useEffect(() => {
        const aggDati = () => {
            setValue('fullName', user.fullName)
            setValue('language', user.language)
            setAltro({...altro, modifiche:false})
            if(!visible) setIsPwd(false)
        }
        if(user && (!visible || isPwd)) aggDati()
    }, [visible, user, isPwd])

    useEffect(() => {
        if(!visible) setTimeout(() => {
            chiudiAlert()
        }, 1000);
    }, [visible])

    const conferma = async(values) => {
        if(altro.loading) return
        setAltro({...altro, loading:true})
        const result = await userApi.updateMe(agency.id, values)
        if(!result.ok) return setTimeout(() => {
            return setAltro({...altro, loading:false, errore:result.data.message})
        }, 400);
        setTimeout(() => {
            aggUser(result.data)
            setAltro({...altro, loading:false, errore:null, modifiche:false, openAlert:1})
        }, 500);
    }

    const confermaCambioPassword = async(values) => {
        if(altro.loading) return
        if(values.nuova != values.ripeti) return setAltro({...altro, errore:'La nuova password deve coincidere.'})
        setAltro({...altro, loading:true})
        const result = await userApi.changePassword(agency.id, {
            nuova: values.nuova, vecchia: values.vecchia
        })
        if(!result.ok) return setTimeout(() => {
            return setAltro({...altro, loading:false, errore:result.data.message})
        }, 400);
        setTimeout(() => {
            setIsPwd(false)
            setAltro({...altro, loading:false, errore:null, modifiche:false, openAlert:2})
        }, 500);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }

    if(isPwd) return(
        <ZonaPassword
            visible={visible}
            loading={altro.loading}
            errore={altro.errore}
            chiudiAlert={chiudiAlert}
            annulla={() => setIsPwd(false)}
            conferma={confermaCambioPassword}
        ></ZonaPassword>
    );

    if(user) return (
        <form
            id="account-form"
            name="account-form"
            data-name="Account Form"
            onSubmit={handleSubmit(conferma)}
        >
            <Box sx={{width:'100%', backgroundColor:'transparent', borderRadius:'20px', 
                margin:{xs:'20px 0 0', sm:'40px 0 0'}, display: visible ? 'block' : 'none'
            }}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:{xs:'title.main', sm:'black'}, p:{xs:0, sm:'30px 0 0'},
                    fontSize:{xs:'20px', sm:'30px'}, fontWeight:'500', lineHeight:'normal', marginBottom:{xs:'4px', sm:'10px'}
                }}>Il mio Profilo</Typography>
                {isGoogle && <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'row', sm:'row'}, alignItems:'center'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black', marginRight:'12px',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'normal', lineHeight:'normal'
                    }}>Account creato tramite Google</Typography>
                    <Box 
                        component='img' alt=''
                        sx={{width:'24px', height:'24px'}}
                        src={IconGoogle} 
                    />
                </Box>}
                <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:{xs:'auto', sm:'150px', md:'200px', lg:'300px'}
                    }}>Nominativo</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'fullName'}
                        width={'400px'}
                        isLabel={false}
                        error={errors.fullName?.message}
                        placeholder={'Nominativo'}
                        register={register("fullName", {required: false, value:user.fullName, onChange:()=>setAltro({...altro, modifiche:true})})}
                    ></AppTextField>
                </Box>
                <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:{xs:'auto', sm:'150px', md:'200px', lg:'300px'}
                    }}>Indirizzo Email</Typography>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', width:{xs:'calc(100% - 24px)', sm:'352px'},
                        borderRadius:'12px', border:'1px solid #DCDCDC', padding:{xs:'12px 12px', sm:'8px 24px'}, backgroundColor:'white', cursor:'not-allowed'
                    }}>
                        <EmailIcon sx={{color:'#667085', width:'17px', height:'auto'}} />
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'normal', lineHeight:'normal',
                            marginLeft:{xs:'6px', sm:'12px'}
                        }}>{user.email}</Typography>
                    </Box>
                </Box>
                {/* <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:{xs:'auto', sm:'150px', md:'200px', lg:'300px'}
                    }}>Numero di Telefono</Typography>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', width:{xs:'calc(100% - 24px)', sm:'352px'},
                        borderRadius:'12px', border:'1px solid #DCDCDC', padding:{xs:'12px 12px', sm:'8px 24px'}, backgroundColor:'white', cursor:'not-allowed'
                    }}>
                        <PhoneAndroidIcon sx={{color:'#667085', width:'17px', height:'auto'}} />
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'normal', lineHeight:'normal',
                            marginLeft:{xs:'6px', sm:'12px'}
                        }}>{user.phone}</Typography>
                    </Box>
                </Box> */}
                {!isGoogle && <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:{xs:'150px', md:'200px', lg:'300px'}
                    }}>Password</Typography>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', width:{xs:'calc(100% - 24px)', sm:'250px', md:'352px'},
                        borderRadius:'12px', border:'1px solid #DCDCDC', padding:{xs:'12px 12px', sm:'8px 24px'}, backgroundColor:'white', cursor:'not-allowed'
                    }}>
                        <LockIcon sx={{color:'#667085', width:'17px', height:'auto'}} />
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'normal', lineHeight:'normal',
                            marginLeft:{xs:'6px', sm:'12px'}
                        }}>********</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            border:'1px solid #DCDCDC', padding:{xs:'8px 16px', sm:'8px 24px'}, 
                            borderRadius:'12px', textTransform: 'none',
                            marginLeft:{xs:0, sm:'24px'}, marginTop:{xs:'6px', sm:0}
                        }}
                        onClick={() => setIsPwd(true)}
                        disableElevation={true}
                    >
                        <Typography noWrap sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', 
                            lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                        }}>Cambia Password</Typography>
                    </Button>
                </Box>}
                {/* <Box sx={{padding:{xs:'12px 0 12px', sm:'25px 0'}, display:'flex', width:'100%', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Box sx={{display:'flex', flexDirection:'row', 
                        width:{xs:'150px', md:'200px', lg:'300px'}, marginBottom:{xs:'4px', sm:0}
                    }}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                            fontSize:'16px', fontWeight:'500', lineHeight:'normal', marginRight:'12px'
                        }}>Lingua</Typography>
                        <Box 
                            component='img' alt=''
                            sx={{width:'20px', height:'20px'}}
                            src={getValues('language') == 'it' ? LogoIT : LogoEN} 
                        />
                    </Box>
                    <AppSelect
                        text={null}
                        width={'400px'}
                        options={languages}
                        value={getValues('language')}
                        onChange={(e) => {
                            setValue('language', e.target.value)
                            setAltro({...altro, modifiche:true})
                        }}
                    ></AppSelect>
                </Box> */}
                {altro.modifiche && <Box sx={{paddingBottom:{xs:'12px', sm:'25px'}}}>
                    <BottoniAccount
                        annulla={() => {
                            setValue('fullName', user.fullName)
                            setValue('language', user.language)
                            clearErrors()
                            setAltro({...altro, modifiche:false})
                        }}
                        loading={altro.loading}
                    ></BottoniAccount>
                </Box>}
                <Box sx={{width:'100%', border:'1px solid #D0D5DD', backgroundColor:'#D0D5DD', margin:{xs:'18px 0 12px', sm:'25px 0'}}} />
                <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'column', md:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:'300px'
                    }}>Logout</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            border:'1px solid #DCDCDC', padding:{xs:'8px 24px', sm:'12px 36px', md:'8px 24px'}, 
                            borderRadius:'12px', textTransform: 'none',
                            marginLeft:0, marginTop:{xs:'6px', sm:'12px', md:0}
                        }}
                        onClick={() => setAltro({...altro, logout:true})}
                        disableElevation={true}
                    >
                        <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', 
                            lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                        }}>Logout</Typography>
                    </Button>
                </Box>
            </Box>

            <ModalLogout
                conferma={logout}
                visible={altro.logout}
                chiudi={() => setAltro({...altro, logout:false})}
            ></ModalLogout>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={'error'}
                message={altro.errore}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Account salvato con successo!'}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 2}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Password cambiata con successo!'}
            ></AppAlert>
        </form>
    );
}

export default ZonaAccount;