import React from 'react';

function ReloadIcon({fill}) {
    return (
        <svg 
            width='100%' height='100%' fill={fill} id="Layer_3" 
            data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
        >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
                <path 
                    d="M4061 5102 c-19 -9 -44 -30 -55 -45 -25 -33 -26 -65 -13 -426 l9
                    -234 -49 37 c-349 265 -756 425 -1188 467 -243 24 -571 0 -790 -56 -559 -144
                    -1034 -478 -1358 -957 -272 -401 -414 -899 -393 -1383 8 -193 43 -428 72 -487
                    50 -100 199 -104 250 -7 19 38 17 77 -16 254 -29 159 -33 455 -7 621 66 427
                    256 813 551 1123 28 29 94 89 146 134 723 613 1768 649 2530 87 l75 -55 -50
                    -2 c-27 -2 -171 -7 -319 -13 -293 -11 -306 -14 -350 -73 -31 -42 -28 -115 7
                    -160 46 -60 49 -60 545 -43 246 9 468 18 495 21 63 6 103 31 127 80 19 40 19
                    45 1 530 -11 270 -24 501 -29 515 -11 27 -45 61 -79 79 -31 16 -73 14 -112 -7z"
                />
                <path 
                    d="M4623 3161 c-39 -24 -63 -68 -63 -113 0 -18 9 -78 20 -133 66 -336
                    44 -692 -66 -1024 -173 -528 -547 -953 -1049 -1196 -568 -275 -1232 -271
                    -1798 11 -160 80 -413 254 -371 254 10 1 155 13 320 29 332 31 350 35 386 101
                    38 70 5 163 -70 193 -35 14 -74 12 -527 -29 -541 -49 -543 -49 -579 -119 l-20
                    -39 43 -490 c30 -336 48 -501 58 -524 20 -41 63 -73 112 -79 70 -10 140 46
                    148 119 4 39 -29 484 -43 567 l-5 34 48 -37 c674 -512 1572 -621 2340 -285
                    606 266 1067 772 1274 1399 128 391 151 783 69 1210 -15 76 -29 106 -64 139
                    -36 34 -118 40 -163 12z"
                />
            </g>
        </svg>
    );
}

export default ReloadIcon;