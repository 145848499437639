import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AppTextField from '../generale/AppTextField';
import BottoniAccount from '../account/BottoniAccount';

function IntegrazioneWA({WA, loading, isEditor, conferma}) {

    const [modifiche, setModifiche] = useState(0);

    const schema = yup.object().shape({
        active: yup.boolean(),
        number: yup.string(),
        numberId: yup.string(),
        businessId: yup.string(),
        appSecret: yup.string(),
        accessToken: yup.string()
    });
    const {register, handleSubmit, getValues, setValue, clearErrors, formState: { errors }} = useForm({
        resolver: yupResolver(schema), defaultValues:{
            active: WA.active, number: WA.number, numberId: WA.numberId,
            businessId: WA.businessId, appSecret: WA.appSecret, accessToken: WA.accessToken
        }
    });

    const clickConferma = async(values) => {
        await conferma(values);
        setTimeout(() => {
            setModifiche(0);
        }, 800);
    }

    const premiAnnulla = () => {
        setValue('active', WA.active)
        setValue('number', WA.number)
        setValue('numberId', WA.numberId)
        setValue('businessId', WA.businessId)
        setValue('appSecret', WA.appSecret)
        setValue('accessToken', WA.accessToken)
        clearErrors()
        setModifiche(0)
    }

    return (
        <Box
            component='form'
            id="wa-integrazione-form"
            name="wa-integrazione-form"
            data-name="wa-integrazione Form"
            onSubmit={handleSubmit(clickConferma)}
            sx={{margin:{xs:'8px 0 -4px', sm:'16px 0 12px 6px'}, boxSizing:'border-box'}}
        >
            <Grid container spacing={{xs:'12px', sm:'20px'}} sx={{
                backgroundColor:'transparent', margin:0
            }}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'2px', sm:'2px'}
                    }}>Phone Number</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'number'}
                        width={'95%'}
                        isLabel={false}
                        error={errors.number}
                        placeholder={'Number'}
                        register={register("number", {required: false, onChange:() => setModifiche(modifiche+1)})}
                    ></AppTextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'2px', sm:'2px'}
                    }}>Phone Number ID</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'numberId'}
                        width={'95%'}
                        isLabel={false}
                        error={errors.numberId}
                        placeholder={'Number ID'}
                        register={register("numberId", {required: false, onChange:() => setModifiche(modifiche+1)})}
                    ></AppTextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'2px', sm:'2px'}
                    }}>WhatsApp Business Account ID</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'businessId'}
                        width={'95%'}
                        isLabel={false}
                        error={errors.businessId}
                        placeholder={'Business ID'}
                        register={register("businessId", {required: false, onChange:() => setModifiche(modifiche+1)})}
                    ></AppTextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'2px', sm:'2px'}
                    }}>App Secret</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'appSecret'}
                        width={'95%'}
                        isLabel={false}
                        error={errors.appSecret}
                        placeholder={'App Secret'}
                        register={register("appSecret", {required: false, onChange:() => setModifiche(modifiche+1)})}
                    ></AppTextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'2px', sm:'2px'}
                    }}>Access Token</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'accessToken'}
                        width={'95%'}
                        isLabel={false}
                        error={errors.accessToken}
                        placeholder={'Access Token'}
                        register={register("accessToken", {required: false, onChange:() => setModifiche(modifiche+1)})}
                    ></AppTextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:{xs:'16px', sm:'20px'}
                        }}>Attivo</Typography>
                        <Switch
                            {...register("active", {
                                required: false,
                                onChange:() => setModifiche(modifiche+1)
                            })}
                            checked={getValues('active')}
                        />
                    </Box>
                </Grid>

                <Box sx={{display:(modifiche && isEditor) ? 'block' : 'none',
                    marginTop:'-8px', marginLeft:{xs:0, sm:'20px'}
                }}>
                    <BottoniAccount
                        loading={loading}
                        annulla={premiAnnulla}
                    ></BottoniAccount>
                </Box>
            </Grid>
        </Box>
    );
}

export default IntegrazioneWA;