import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function DrawerMobile({agency, openDrawer, toggleDrawer, isRouteAttuale}) {

    const navigate = useNavigate()

    const isSettings = isRouteAttuale('/settings')
    const isChatbot = isRouteAttuale('/') || isRouteAttuale('/create-chatbot') || isRouteAttuale('/chatbot')

    useEffect(() => {
        // if(openDrawer) document.body.style.overflowY = 'hidden';
        // else document.body.style.overflowY = 'scroll';
        // return () => document.body.style.overflowY = 'scroll';
    }, [openDrawer])

    return (
        <Drawer
            anchor='right'
            variant="persistent"
            open={openDrawer}
            onClose={toggleDrawer}
            transitionDuration={{ enter: 1000, exit: 800 }}
            ModalProps={{keepMounted: true}} // Better open performance on mobile.
            sx={{
                display: { xs: 'flex', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "100%", border: "none", backgroundColor:"#f9f9f9" },
            }}
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'16px 16px 32px'}}>
                <Box
                    onClick={() => {
                        navigate('/')
                        toggleDrawer()
                    }}
                    sx={{display:'flex', alignItems:'center', cursor:'pointer'}}
                >
                    <Box component='img' alt='' src={agency.logo} sx={{
                        width:'auto', height:'45px', objectFit:'contain'
                    }} />
                    {agency.showTitle && <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:agency.titleColor,
                        fontSize:'28px', fontWeight:'700', lineHeight:'normal', marginLeft:'8px'
                    }}>{agency.name}</Typography>}
                </Box>
                <IconButton
                    onClick={toggleDrawer}
                    sx={{display:{xs:'flex', sm:'none'}, width:'38px', height:'38px'}}
                >
                    <CloseOutlinedIcon sx={{color:'black', width:'38px', height:'auto'}} />
                </IconButton>
            </Box>

            <Stack direction='column' spacing='46px' sx={{padding:'0 24px'}}>
                <Box sx={{display:'flex', alignItems:'center', width:'fit-content',
                    borderBottom:isChatbot ? '3px solid' : 'none', borderColor:'primary.main'
                }}>
                    <Button
                        type="submit"
                        color="nero"
                        variant="text"
                        sx={{
                            border:'none', padding:'0', textTransform: 'none',
                            '&:hover': {backgroundColor:'transparent'}
                        }}
                        disableElevation={true}
                        onClick={() => {
                            navigate('/')
                            toggleDrawer()
                        }}
                    >
                        <Typography component='span' sx={{fontSize:'22px', fontWeight:'500', lineHeight:'normal', 
                            letterSpacing:'-0.2', textAlign:'center', color:'black'
                        }}>I miei Chatbot</Typography>
                    </Button>
                </Box>
                <Box sx={{display:'flex', alignItems:'center', width:'fit-content',
                    borderBottom:isSettings ? '3px solid' : 'none', borderColor:'primary.main'
                }}>
                    <Button
                        type="submit"
                        color="nero"
                        variant="text"
                        sx={{
                            border:'none', padding:'0', textTransform: 'none',
                            '&:hover': {backgroundColor:'transparent'}
                        }}
                        disableElevation={true}
                        onClick={() => {
                            navigate('/settings')
                            toggleDrawer()
                        }}
                    >
                        <Typography component='span' sx={{fontSize:'22px', fontWeight:'500', lineHeight:'normal', 
                            letterSpacing:'-0.2', textAlign:'center', color:'black'
                        }}>Account</Typography>
                    </Button>
                </Box>
            </Stack>
        </Drawer>
    );
}

export default DrawerMobile;