import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import chatbotApi from '../api/chatbot';
import AppAlert from '../components/generale/AppAlert';
import useAgency from '../controllers/agency/useAgency';
import useChatbot from '../controllers/chatbots/useChatbot';
import ZonaCreaChatbot from '../components/chatbots/ZonaCreaChatbot';
import useAbbonamento from '../controllers/abbonamento/useAbbonamento';
import LoadingCreaChatbot from '../components/chatbots/LoadingCreaChatbot';
import AllenamentoChatbot from '../components/allenamento/AllenamentoChatbot';
import ModalCosaAggiungere from '../components/allenamento/ModalCosaAggiungere';
import ModalEliminaAllenamento from '../components/allenamento/ModalEliminaAllenamento';

function CreaChatbot(props) {
    
    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const {agency} = useAgency()
    const {subscription} = useAbbonamento()
    const {chatbots, chatbotsLoading, addChatBot, updateChatbot} = useChatbot()

    const [chatbot, setChatbot] = useState(null)
    const [situazione, setSituazione] = useState(0)
    const [elimina, setElimina] = useState({visible:false, training:null})
    const [altro, setAltro] = useState({
        errore:null, loading:false, loadingAllena:false, openAlert:0, visible:0
    })

    useEffect(() => {
        const aggState = () => {
            if(state?.chatbot) {
                const appo = chatbots.filter((c) => c.id == state.chatbot.id)[0];
                if(!appo && !chatbotsLoading) return navigate('/')
                if(appo) return setChatbot({...appo})
            } else if(!state?.isCreaChatbot) return navigate('/')
        }
        aggState()
    }, [state, chatbots, chatbotsLoading])

    useEffect(() => {
        if(!subscription) return
        if(!chatbot && subscription.metrics.botsLimit != -1 &&
            chatbots.length >= subscription.metrics.botsLimit) return navigate('/')
    }, [chatbots, chatbot, subscription])

    const creaChatbot = async(values) => {
        setAltro({...altro, errore:null, loading:true})
        const primaryColor = agency?.primaryColor?.length > 0 ? agency.primaryColor : '#2D5BFF';
        const secondaryColor = agency?.secondaryColor?.length > 0 ? agency.secondaryColor : 'white';
        let result = await chatbotApi.createChatbot(agency.id, {
            name: values.chatbotName, scope: values.scope,
            openaiKey: values.openaiKey?.length ? values.openaiKey : null,
            primaryColor:primaryColor, secondaryColor:secondaryColor,
            botsLimit: subscription.metrics.botsLimit
        });
        if(!result.ok) return setAltro({...altro, errore:result.data.message, loading:false})
        setTimeout(async() => {
            addChatBot(result.data)
            setChatbot(result.data)
            setAltro({...altro, loading:false, errore:null, openAlert:1})
            navigate(pathname, {replace:true, state:{chatbot:result.data}})
        }, 500);
    }

    const allenaChatbot = async(contents, name, source, references) => {
        if(altro.loadingAllena) return
        if(!contents.length) return setAltro({...altro, errore:'Le informazioni dell\'allenamento non possono essere vuote'})
        if(!name.length) return setAltro({...altro, errore:'Inserire il nome dell\'allenamento'})
        setAltro({...altro, errore:null, loadingAllena:true})
        const result = source != 'domande' ? await chatbotApi.trainChatbot(agency.id, {
            chatbotId:chatbot.id, contents:contents, name:name,
            trainingType:source, references:references
        }) : await chatbotApi.trainChatbotQA(agency.id, {
            chatbotId:chatbot.id, contents:contents, name:name,
            trainingType:source, references:references, messageId:null
        })
        if(!result.ok) return setAltro({...altro, errore:result.data.message, loadingAllena:false})
        setTimeout(() => {
            const appo = {...chatbot}
            appo.trainings.push(result.data)
            setChatbot(appo)
            updateChatbot(appo)
            setSituazione(0)
            setAltro({...altro, loadingAllena:false, errore:null, openAlert:2})
            navigate(pathname, {replace:true, state:{chatbot:appo}})
        }, 500);
    }

    const completaChatbot = () => {
        setAltro({...altro, errore:null, loading:true})
        updateChatbot(chatbot)
        setTimeout(() => {
            navigate('/chatbot', {replace:true, state:{
                chatbot:chatbot, situazione:4
            }})
        }, 500);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }

    if(chatbotsLoading) return (
        <Box sx={{
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'16px 12px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <LoadingCreaChatbot
                isAllena={Boolean(chatbot)}
            ></LoadingCreaChatbot>
        </Box>
    );

    if(!chatbot) return (
        <Box sx={{
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'16px 12px', sm:'30px 40px 30px', md:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}, position:'relative'
        }}>
            <ZonaCreaChatbot
                isPrimo={chatbots.length === 0}
                loading={altro.loading}
                agency={agency}
                conferma={creaChatbot}
            ></ZonaCreaChatbot>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={"error"}
                message={altro.errore}
            ></AppAlert>
        </Box>
    );

    if(chatbot) return (
        <Box sx={{
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'0 12px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <Box sx={{display: 'flex', width:'100%', flexDirection:'column', alignItems:'center', padding:'25px 0 0'}}>
                <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'26px', sm:'36px'}, fontWeight:'700', lineHeight:{xs:'0.9', sm:'normal'}
                }}>
                    Aggiungi contenuti a&nbsp;
                    <Typography component='span' sx={{
                        display:'initial', color:agency.titleColor, fontSize:{xs:'28px', sm:'36px'}, fontWeight:'700'
                    }}>{chatbot.name}</Typography>
                </Typography>
                <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'15px', sm:'20px'}, fontWeight:'400', lineHeight:'normal', marginTop:{xs:'8px', sm:'16px'},
                    marginLeft:{xs:'4px', sm:'60px', md:'190px', lg:0}, marginRight:{xs:'4px', sm:'60px', md:'190px', lg:0}
                }}>
                    Aggiungi i contenuti su cui vuoi che il tuo chatbot risponda alle domande. 
                    Puoi sempre aggiungerne altri in seguito. <br className='newline-no-mobile'/>
                    {chatbot.trainings.length == 0 &&
                        'Per poter avviare il chatbot devi prima caricare almeno una sorgente di dati a tua scelta.'
                    }
                </Typography>
            </Box>

            <AllenamentoChatbot
                isAvvia={true}
                isEditor={true}
                altro={altro}
                isModifica={false}
                cambiaAltro={setAltro}
                situazione={situazione}
                cambiaSituazione={setSituazione}
                chatbotId={chatbot.id}
                trainings={chatbot.trainings}
                allenaChatbot={allenaChatbot}
                completaChatbot={completaChatbot}
                modificaAllenamento={() => null}
                eliminaAllenamento={(training) => setElimina({visible:true, training})}
                charsLimitXBot={subscription?.metrics.charsLimitXBot}
                apriCosaAggiungere={() => setAltro({...altro, visible:1})}
                premiUpgrade={() => navigate('/settings', {state:{situazione:3}})}
            ></AllenamentoChatbot>

            <ModalCosaAggiungere
                visible={altro.visible === 1}
                chiudi={() => setAltro({...altro, visible:0})}
            ></ModalCosaAggiungere>

            <ModalEliminaAllenamento
                chatbot={chatbot}
                visible={elimina.visible}
                training={elimina.training}
                chiudi={() => setElimina({visible:false, training:null})}
                removeTraining={(idTraining) => {
                    let appo = {...chatbot};
                    appo.trainings = chatbot.trainings.filter((t) => t.id != idTraining);
                    setChatbot({...appo})
                    updateChatbot({...appo})
                    setElimina({visible:false, training:null})
                    navigate(pathname, {replace:true, state:{chatbot:appo}})
                    setAltro({...altro, errore:null, openAlert:3})
                }}
            ></ModalEliminaAllenamento>

            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={"success"}
                message={"Chatbot creato con successo!"}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 2}
                chiudiAlert={chiudiAlert}
                severity={"success"}
                message={"Chatbot allenato con successo!"}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 3}
                chiudiAlert={chiudiAlert}
                severity={"success"}
                message={"Allenamento eliminato con successo!"}
            ></AppAlert>
            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={"error"}
                message={altro.errore}
            ></AppAlert>
        </Box>
    );
}

export default CreaChatbot;