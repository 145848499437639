import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function LoadingPaginaChatbot(props) {
    return (
        <Box sx={{
            position:'relative', 
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'16px 12px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <Stack direction='row' spacing={{xs:'10px', sm:'30px'}} sx={{alignItems:'center', zIndex:200}}>
                <KeyboardBackspaceIcon
                    color='black'
                    sx={{width:'50px', height:'auto', cursor:'pointer', zIndex:200}}
                />
                <Skeleton variant="circular" width={'50px'} height={'50px'} animation="wave" />
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'150px', sm:'450px'}, height:{xs:'30px', sm:'50px'}
                }} />
            </Stack>

            <Box sx={{display:{xs:'block', sm:'none'}, marginTop:'15px'}}>
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'150px', sm:'450px'}, height:{xs:'30px', sm:'50px'}
                }} />
            </Box>
            <Stack direction='row' spacing={{xs:'30px', lg:'85px'}} sx={{
                width:{xs:'96%', lg:'75%'}, alignItems:'center', justifyContent:'space-between', 
                backgroundColor:'transparent', marginTop:'40px', marginLeft:{xs:'2%', lg:'60px'},
                display:{xs:'none', sm:'flex'}
            }}>
                <Skeleton variant="rounded" width={'130px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'130px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'130px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'130px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'130px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'130px'} height={'40px'} animation="wave" />
            </Stack>

            <Stack direction='column' spacing='0' sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:'12px 0 0', sm:'20px 0 0', lg:'40px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc'
            }}>
                <Box sx={{padding:'20px 20px 0'}}>
                    <Skeleton variant="rounded" animation="wave" sx={{
                        width:'100%', height:{xs:'30px', sm:'50px'}
                    }}/>
                </Box>
                <Box sx={{padding:'20px 20px 0'}}>
                    <Skeleton variant="rounded" animation="wave" sx={{
                        width:'100%', height:{xs:'30px', sm:'50px'}
                    }}/>
                </Box>
                <Box sx={{padding:'20px'}}>
                    <Skeleton variant="rounded" animation="wave" sx={{
                        width:'100%', height:{xs:'30px', sm:'50px'}
                    }}/>
                </Box>
            </Stack>
        </Box>
    );
}

export default LoadingPaginaChatbot;