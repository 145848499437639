import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { SketchPicker } from 'react-color';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AppMenu = styled(Menu)({
    '& .MuiPaper-root': {
        backgroundColor:'transparent',
        boxShadow:'none',
        padding:0
    }
})

function AppColorPicker({text, value, onChange}) {

    const [open, setOpen] = useState(false)
    const [anchorVariabili, setAnchorVariabili] = useState()

    return (
        <React.Fragment>
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                {text && <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', marginBottom:'2px'
                }}>{text}</Typography>}
                <Box sx={{width:{xs:'100px', sm:'120px'}, height:{xs:'28px', sm:'36px'}, p:'0 5px', display:'flex', 
                    border:'1px solid #DCDCDC', borderRadius:'12px', alignItems:'center'
                }}>
                    <Box sx={{display:'flex', flex:1, height:{xs:'22px', sm:'28px'}, borderRadius:'10px', backgroundColor:value}} />
                    <IconButton disableRipple sx={{p:0, m:0}} onClick={(event) => {
                        setAnchorVariabili(event.currentTarget); setOpen(true);
                    }}>
                        <ExpandMoreIcon sx={{width:'20px', height:'auto'}} color='black' />
                    </IconButton>
                </Box>
            </Box>

            <AppMenu
                open={open}
                disableScrollLock={true}
                anchorEl={anchorVariabili}
                onClose={() => setOpen(false)}
                sx={{marginTop:'10px', cursor:'pointer'}}
                anchorOrigin={{vertical:"bottom", horizontal:"center"}}
            >
                <SketchPicker
                    color={value}
                    onChangeComplete={(color) => onChange(color.hex)}
                />
            </AppMenu>
        </React.Fragment>
    );
}

export default AppColorPicker;