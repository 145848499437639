import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

function LoadingCreaChatbot({isAllena}) {
    
    if(!isAllena) return (
        <Box sx={{width:'100%', display:'flex', flexDirection:'column', 
            alignItems:'center', padding:{xs:'45px 0 25px', sm:'25px 0'}
        }}>
            <Skeleton variant="rounded" animation="wave" sx={{
                width:{xs:'100%', sm:'450px'}, height:{xs:'40px', sm:'60px'}
            }} />
            <Skeleton variant="rounded" animation="wave" sx={{
                width:{xs:'80%', sm:'300px'}, height:{xs:'35px', sm:'50px'}, marginTop:{xs:'20px', sm:'40px'}
            }} />
            <Skeleton variant="rounded" animation="wave" sx={{
                width:{xs:'100%', sm:'420px'}, height:{xs:'30px', sm:'40px'}, marginTop:{xs:'20px', sm:'40px'}
            }} />
            <Skeleton variant="rounded" animation="wave" sx={{
                width:{xs:'150px', sm:'200px'}, height:{xs:'50px', sm:'60px'}, marginTop:{xs:'30px', sm:'50px'}
            }} />
        </Box>
    );

    return (
        <Box sx={{display: 'flex', width:'100%', flexDirection:'column', alignItems:'center', padding:'25px 0 0'}}>
            <Skeleton variant="rounded" animation="wave" sx={{
                width:{xs:'80%', sm:'450px'}, height:{xs:'40px', sm:'60px'}
            }} />

            <Stack direction='column' spacing='6px' sx={{paddingTop:{xs:'16px', sm:'25px', sm:'30px'}, width:'100%', alignItems:'center'}}>
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'80%', md:'820px'}, height:{xs:'10px', sm:'15px'}
                }} />
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'80%', md:'820px'}, height:{xs:'10px', sm:'15px'}
                }} />
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'80%', md:'820px'}, height:{xs:'10px', sm:'15px'}
                }} />
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'80%', md:'820px'}, height:{xs:'10px', sm:'15px'}, display:{xs:'block', md:'none'}
                }} />
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'80%', md:'820px'}, height:{xs:'10px', sm:'15px'}, display:{xs:'block', md:'none'}
                }} />
            </Stack>

            <Stack direction='column' spacing='16px' sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:'16px 0 0', sm:'25px 0 0', lg:'40px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', alignItems:'center', p:'20px 0'
            }}>
                <Box sx={{padding:'20px 20px 0'}}>
                    <Skeleton variant="rounded" animation="wave" sx={{
                        width:{xs:'250px', sm:'500px'}, height:{xs:'40px', sm:'50px'}
                    }}/>
                </Box>
                <Box sx={{padding:'20px 20px 0'}}>
                    <Skeleton variant="rounded" animation="wave" sx={{
                        width:{xs:'250px', sm:'500px'}, height:{xs:'40px', sm:'50px'}
                    }}/>
                </Box>
                <Box sx={{padding:'20px'}}>
                    <Skeleton variant="rounded" animation="wave" sx={{
                        width:{xs:'250px', sm:'500px'}, height:{xs:'40px', sm:'50px'}
                    }}/>
                </Box>
            </Stack>
        </Box>
    );
}

export default LoadingCreaChatbot;