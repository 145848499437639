import React from 'react';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import UploadIcon from '@mui/icons-material/Upload';

function BottoneUpload({text, isPiccolo, loading}) {

    return (
        <LoadingButton
            component="span"
            type="submit"
            color="primary"
            variant="contained"
            loading={loading}
            loadingPosition={'start'}
            sx={{border:'1px solid #dcdcdc',
                width:isPiccolo ? 'fit-content' : {xs:'160px', sm:'200px'}, 
                padding:isPiccolo ? 'none' : {xs:'8px 0', sm:'10px 0'}, 
                borderRadius:isPiccolo ? '12px' : '12px', textTransform:'none',
                minWidth:isPiccolo ? {xs:'140px', sm:'160px'} : 'auto',
                height:isPiccolo ? {xs:'30px', sm:'40px'} : 'fit-content'
            }}
            startIcon={<UploadIcon sx={{width:{xs:isPiccolo ? '16px' : '20px', sm:isPiccolo ? '20px' : '25px'}, height:'auto'}} color='white' />}
            disableElevation={true}
        >
            <Typography component='span' sx={{fontSize:{xs:isPiccolo ? '13px' : '14px', sm:isPiccolo ? '16px' : '18px'}, fontWeight:'700', lineHeight:'normal', 
                letterSpacing:'-0.2', textAlign:'left', marginLeft:isPiccolo ? '5px' : {xs:'6px', sm:'10px'}
            }}>{text}</Typography>
        </LoadingButton>
    );
}

export default BottoneUpload;