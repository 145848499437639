import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

function BottoniAccount({annulla, loading}) {

    return (
        <Stack direction='row' spacing={{xs:'16px', sm:'24px'}} sx={{marginTop:{xs:'12px', sm:'30px'}}}>
            <LoadingButton
                type='submit'
                loading={loading}
                variant="contained"
                color="primary"
                sx={{padding:'8px 30px', borderRadius:'12px', textTransform:'none', border:'1px solid #dcdcdc'}}
                disableElevation={true}
            >
                <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', 
                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                }}>Salva</Typography>
            </LoadingButton>
            <Button
                variant="contained"
                color="bianco"
                sx={{padding:'8px 24px', borderRadius:'12px', textTransform:'none', border:'1px solid #dcdcdc'}}
                disableElevation={true}
                onClick={annulla}
            >
                <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', color:'black', 
                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                }}>Annulla</Typography>
            </Button>
        </Stack>
    );
}

export default BottoniAccount;