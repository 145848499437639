import React from 'react';

function CloseIcon({fill}) {
    return (
        <svg 
            width='100%' height='100%' fill={fill} id="Layer_5" 
            data-name="Layer 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
        >
            <path d="M.7.7c-1.9 1.8.4 4.9 13 17.5L27.5 32 13.6 45.9C3.9 55.6-.1 60.3.2 61.6c.9 4.6 4 2.6 17.9-11.2L32 36.5l13.9 13.9c10 9.9 14.4 13.7 15.5 13.3 4.3-1.7 2.9-3.9-11-17.8L36.5 32l13.9-13.9C61.9 6.6 64.1 3.9 63.5 2.1 63.2 1 62.1 0 61.2 0c-.9 0-7.9 6.2-15.4 13.7L32 27.5 18.2 13.7C5.6 1.1 2.5-1.2.7.7z" />
        </svg>
    );
}

export default CloseIcon;