import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function ModalConfermaRetrain({visible, conferma, chiudi}) {
    
    return (
        <Modal
            open={visible}
            closeAfterTransition
            keepMounted
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', 
                width:'100%', height:'100%', position:'relative', backgroundColor:'rgba(0, 0, 0, 0.2)'
            }} onClick={chiudi}>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'fit-content'}, backgroundColor:'white', 
                    borderRadius:'16px', border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    padding:{xs:'20px 12px', sm:'25px 30px'}, display:'flex', flexDirection:'column',
                    margin:{xs:'16px 12px', sm:'0 36px', md:0}, height:{xs:'fit-content', sm:'fit-content'}, 
                    overflowY:{xs:'scroll', sm:'auto'}, maxWidth:{xs:'auto', md:'40%'}
                }} onClick={(e) => e.stopPropagation()}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'primary.main',
                        fontSize:{xs:'26px', sm:'34px'}, fontWeight:'700', lineHeight:'1.2'
                    }}>Hai completato tutte le modifiche?</Typography>

                    <Box sx={{marginTop:{xs:'18px', sm:'24px'}}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'18px'}, fontWeight:'500', lineHeight:'normal'
                        }}>
                            Ti informiamo che un <b>numero eccessivo di salvataggi consecutivi</b> potrebbe portare 
                            alla temporanea disabilitazione della possibilità di eseguire nuovi allenamenti. 
                            Questo meccanismo di sicurezza è stato implementato per evitare un uso eccessivo del sistema e 
                            garantire una gestione ottimale delle risorse.
                        </Typography>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'18px'}, fontWeight:'500', lineHeight:'normal',
                            marginTop:{xs:'12px', sm:'24px'}
                        }}>
                            Per questo motivo ti consigliamo di <b>effettuare tutte le modifiche </b> 
                            desiderate in una sola volta, prima di premere il pulsante "Allena Chatbot". 
                        </Typography>
                    </Box>

                    <Box sx={{marginTop:{xs:'24px', sm:'36px', md:'28px'}, width:'100%', alignItems:'center',
                        display:'flex', flexDirection:{xs:'column', sm:'row'}, justifyContent:'center'
                    }}>
                        <Button
                            type="submit"
                            color={"nero"}
                            variant="contained"
                            sx={{
                                border:'1px solid #dcdcdc', padding:{xs:'8px 24px', sm:'10px 40px'},
                                borderRadius:'12px', textTransform:'none'}}
                            disableElevation={true}
                            onClick={chiudi}
                        >
                            <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'left', color:'white'
                            }}>Prosegui le modifiche</Typography>
                        </Button>
                        <Box sx={{width:'32px', height:'12px'}} />
                        <Button
                            type="submit"
                            color={"primary"}
                            variant="contained"
                            sx={{
                                border:'1px solid #dcdcdc', padding:{xs:'8px 24px', sm:'10px 40px'},
                                borderRadius:'12px', textTransform:'none'
                            }}
                            disableElevation={true}
                            onClick={conferma}
                        >
                            <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'600',
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>Conferma Allenamento</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalConfermaRetrain;