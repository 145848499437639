import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import Messaggio from '../chat/Messaggio';

import StarIcon from '@mui/icons-material/Star';
import CancelIcon from '@mui/icons-material/Cancel';

function ElencoConversazioni({conversazioni, chatbot, loading, isReader, premiCorreggiDomanda}) {

    const [selected, setSelected] = useState(null)

    useEffect(() => {
        const clear = () => {
            setSelected(null)
        }
        const aggSelected = () => {
            let conv = conversazioni.find((c) => c.id == selected.id);
            if(conv) setSelected(conv)
            else setSelected(null)
        }
        if(loading) clear()
        if(!loading && selected) aggSelected()
    }, [conversazioni, loading])

    const isContacts = (list) => {
        for(let i = 0; i < list.length; i++) {
            if(list[i].metadata?.contacts) return true
        }
        return false
    }

    if(loading) return (
        <Box sx={{width:'100%', margin:{xs:'30px 0 12px', sm:'50px 0 0'}}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:{xs:'title.main', sm:'black'},
                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'500', lineHeight:'normal'
            }}>Elenco Conversazioni:</Typography>

            <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, marginTop:{xs:'4px', sm:'8px'}}}>
                <Stack direction='column' spacing='12px' sx={{width:{xs:'100%', sm:'40%'}}}>
                    <Box sx={{
                        padding:'12px', display:'flex', borderRadius:'12px', 
                        flexDirection:'column', border:'1px solid #dcdcdc',
                    }}>
                        <Skeleton variant="rounded" width={'230px'} height={'18px'} animation="wave" />
                        <Skeleton variant="rounded" width={'130px'} height={'14px'} animation="wave" sx={{marginTop:'5px'}} />
                        <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', marginTop:'4px'}}>
                            <Skeleton variant="rounded" width={'130px'} height={'14px'} animation="wave" />
                        </Box>
                    </Box>
                    <Box sx={{
                        padding:'12px', display:'flex', borderRadius:'12px', 
                        flexDirection:'column', border:'1px solid #dcdcdc',
                    }}>
                        <Skeleton variant="rounded" width={'230px'} height={'18px'} animation="wave" />
                        <Skeleton variant="rounded" width={'130px'} height={'14px'} animation="wave" sx={{marginTop:'5px'}} />
                        <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', marginTop:'4px'}}>
                            <Skeleton variant="rounded" width={'130px'} height={'14px'} animation="wave" />
                        </Box>
                    </Box>
                    <Box sx={{
                        padding:'12px', display:'flex', borderRadius:'12px', 
                        flexDirection:'column', border:'1px solid #dcdcdc',
                    }}>
                        <Skeleton variant="rounded" width={'230px'} height={'18px'} animation="wave" />
                        <Skeleton variant="rounded" width={'130px'} height={'14px'} animation="wave" sx={{marginTop:'5px'}} />
                        <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', marginTop:'4px'}}>
                            <Skeleton variant="rounded" width={'130px'} height={'14px'} animation="wave" />
                        </Box>
                    </Box>
                </Stack>
                <Box sx={{width:'calc(60% - 24px)', marginLeft:'24px', borderRadius:'10px', overflowY:'auto', 
                    border:'1px solid #dcdcdc', height:selected ? '500px' : '300px', display:{xs:'none', sm:'block'}
                }} className='stats-messages'>
                    <Box sx={{width:'100%', height:'300px', display:'flex',
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <Typography sx={{fontSize:{xs:'18px', sm:'25px'}, fontWeight:'500', color:'black', 
                            lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'center'
                        }}>
                            Seleziona una conversazione <br className='newline-no-mobile'/>
                            per visualizzare i messaggi
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    if(!loading && conversazioni.length == 0) return (
        <Box sx={{width:'100%', margin:{xs:'30px 0 12px', sm:'50px 0 0'}}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:{xs:'title.main', sm:'black'},
                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'500', lineHeight:'normal'
            }}>Elenco Conversazioni:</Typography>
            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'rosso.main', 
                lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'left'
            }}>
                Nessuna conversazione disponibile in queste date.
            </Typography>
        </Box>
    );
    
    return (
        <Box sx={{width:'100%', margin:{xs:'30px 0 12px', sm:'50px 0 0'}}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:{xs:'title.main', sm:'black'},
                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'500', lineHeight:'normal',
                display:{xs:!selected ? 'block' : 'none', sm:'block'},
            }}>Elenco Conversazioni:</Typography>

            <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, marginTop:{xs:'4px', sm:'8px'}, position:'relative'}}>
                <Stack direction='column' spacing='12px' sx={{
                    width:{xs:'100%', sm:'40%'}, display:{xs:!selected ? 'flex' : 'none', sm:'flex'}
                }}>
                    {conversazioni.map((conv, index) => (
                        <Box key={'conv' + index} sx={{
                            padding:'12px', display:'flex', borderRadius:'12px',  flexDirection:'column',
                            '&:hover': {backgroundColor:'#F4F4F5'}, cursor:'pointer',
                            border:selected?.id === conv.id ? '1px solid' : '1px solid',
                            borderColor:selected?.id === conv.id ? 'primary.main' : '#dcdcdc'
                        }} onClick={() => setSelected(conv)}>
                            <Typography sx={{fontSize:{xs:'14px', sm:'15px'}, fontWeight:'500', color:'black', 
                                lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'left'
                            }}>
                                <span style={{color:'#696969'}}>Cliente:</span> {conv.messages[0]?.content?.slice(0, 200)}
                            </Typography>
                            <Stack direction='row' spacing={{xs:'18px', sm:'24px'}} sx={{
                                marginTop:{xs:'4px', sm:0}, alignItems:'center'
                            }}>
                                <Typography sx={{fontSize:{xs:'11px', sm:'13px'}, fontWeight:'500', color:'black', 
                                    lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'left'
                                }}><span style={{color:'#696969'}}>Messaggi:</span> {conv.messages.length}</Typography>
                                <Typography sx={{fontSize:{xs:'11px', sm:'13px'}, fontWeight:'500', color:'black', 
                                    lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'left'
                                }}><span style={{color:'#696969'}}>Fonte:</span> {conv.source}</Typography>
                                {isContacts(conv.messages) &&
                                    <StarIcon sx={{width:'20px', height:'20px', color:'gold'}} />
                                }
                            </Stack>
                            <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', marginTop:'4px'}}>
                                <Typography sx={{fontSize:{xs:'10px', sm:'12px'}, fontWeight:'500', color:'#696969', 
                                    lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'right'
                                }}>{moment(conv.createdAt).format('DD/MM/YYYY, HH:mm')}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Stack>

                <Box sx={{display:{xs:selected ? 'flex' : 'none', sm:'none'}, alignItems:'flex-end',
                    justifyContent:'space-between', marginBottom:{xs:'4px', sm:0}
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'title.main',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'500', lineHeight:'normal'
                    }}>Conversazione selezionata:</Typography>
                    <Box sx={{width:'26px', height:'26px', cursor:'pointer',
                        borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center'
                    }} onClick={() => setSelected(null)}>
                        <CancelIcon sx={{width:'26px', height:'26px', color:'#FC2A1B'}} />
                    </Box>
                </Box>

                <Box sx={{width:{xs:'100%', sm:'calc(60% - 24px)'}, marginLeft:{xs:0, sm:'24px'}, borderRadius:'10px', overflowY:'auto', 
                    border:'1px solid #dcdcdc', height:selected ? {xs:'60vh', sm:'680px', md:'500px'} : '300px',
                    position:'sticky', top:'20px', display:{xs:selected ? 'block' : 'none', sm:'block'}
                }} className='stats-messages'>
                    {!selected && <Box sx={{width:'100%', height:'300px', display:'flex',
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <Typography sx={{fontSize:{xs:'18px', sm:'25px'}, fontWeight:'500', color:'black', 
                            lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'center'
                        }}>
                            Seleziona una conversazione <br className='newline-no-mobile'/>
                            per visualizzare i messaggi
                        </Typography>
                    </Box>}
                    {selected && <Box sx={{padding:{xs:'8px', sm:'18px'}}}>
                        {selected.messages.map((msg, index) => (
                            <Messaggio
                                key={index}
                                isDark={false}
                                isReader={isReader}
                                contenuto={msg.content}
                                isOperator={msg.operator}
                                operatorStatus={msg.status}
                                isUser={msg.role === 'user'}
                                isRevised={msg.metadata?.revised}
                                displayName={chatbot.displayName}
                                buttonColor={chatbot.buttonColor}
                                isCorreggiDomanda={msg.role === 'assistant'}
                                coloreSfondo={msg.role === 'user' ? chatbot.userMessageColor : chatbot.botMessageColor}
                                coloreTesto={msg.role === 'user' ? chatbot.userTextColor : chatbot.botTextColor}
                                premiCorreggiDomanda={() => premiCorreggiDomanda({
                                    messageId:selected.id + '-' + index, question:selected.messages[index - 1].content, content:msg.content
                                })}
                            ></Messaggio>
                        ))}
                    </Box>}
                </Box>
            </Box>
        </Box>
    );
}

export default ElencoConversazioni;