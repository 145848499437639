import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import utils from '../../libs/utils';
import ElencoLingue from './ElencoLingue';
import chatbotApi from '../../api/chatbot';
import AnteprimaChat from './AnteprimaChat';
import AppAlert from '../generale/AppAlert';
import AppSelect from '../generale/AppSelect';
import BottoneSalva from '../generale/BottoneSalva';
import AppTextField from '../generale/AppTextField';
import AppFilePicker from '../generale/AppFilePicker';
import AppColorPicker from '../generale/AppColorPicker';
import useAgency from '../../controllers/agency/useAgency';

import CancelIcon from '@mui/icons-material/Cancel';

const themes = [
    {id:0, name:'Light'},
    {id:1, name:'Dark'}
]

const positions = [
    {id:0, name:'Left'},
    {id:1, name:'Right'}
]

const secondaryColors = [
    {id:'white', name:'Bianco'},
    {id:'black', name:'Nero'}
]

function ZonaGrafica({visible, chatbot, aggChatbot, isEditor}) {

    const {agency} = useAgency()

    const [foto, setFoto] = useState({foto:null, preview:null})
    const [activeLang, setActiveLang] = useState({
        initial:chatbot.languages[0], suggested:chatbot.languages[0]
    })
    const [altro, setAltro] = useState({
        loading:false, errore:null, modifiche:false, openAlert:0
    })

    useEffect(() => {
        if(!visible) setTimeout(() => {
            chiudiAlert();
        }, 1000);
    }, [visible])

    const schema = yup.object().shape({
        startMessages: yup.object().shape({
            it:yup.string().nullable(), en:yup.string().nullable(),
            fr:yup.string().nullable(), de:yup.string().nullable(), es:yup.string().nullable()
        }),
        suggestedQuestions: yup.object().shape({
            it:yup.string().nullable(), en:yup.string().nullable(),
            fr:yup.string().nullable(), de:yup.string().nullable(), es:yup.string().nullable()
        }),
        theme: yup.number('Il tema deve avere un valore valido.').required('Il tema è obbligatorio.'),
        displayName: yup.string().nullable(),
        userMessageColor: yup.string().required('Il colore è obbligatorio.'),
        userTextColor: yup.string().required('Il colore è obbligatorio.'),
        botMessageColor: yup.string().required('Il colore è obbligatorio.'),
        botTextColor: yup.string().required('Il colore è obbligatorio.'),
        buttonColor: yup.string().required('Il colore è obbligatorio.'),
        buttonPhoto: yup.string().nullable(),
        buttonPosition: yup.number('La posizione deve avere un valore valido.').required('La posizione è obbligatoria.'),
        secondaryColor: yup.string().required('Il colore secondario è obbligatorio.'),
        isBubble: yup.boolean(),
        bubbleTimer: yup.number().min(1, 'Il timer deve essere di almeno 1 secondo.')
    });
    const {register, getValues, setValue, handleSubmit, reset, formState: { errors }} = useForm({
        resolver: yupResolver(schema), defaultValues:{
            startMessages: utils.smontaOggetto(chatbot.startMessages),
            suggestedQuestions: utils.smontaOggetto(chatbot.suggestedQuestions),
            theme: chatbot ? chatbot.theme : 0,
            buttonPosition: chatbot ? chatbot.buttonPosition : 0,
            userMessageColor: chatbot.userMessageColor,
            userTextColor: chatbot.userTextColor,
            botMessageColor: chatbot.botMessageColor,
            botTextColor: chatbot.botTextColor,
            buttonColor: chatbot.buttonColor,
            buttonPhoto: chatbot.buttonPhoto,
            secondaryColor: chatbot.secondaryColor,
            isBubble: Boolean(chatbot.isBubble),
            bubbleTimer: parseInt(chatbot.bubbleTimer) ? parseInt(chatbot.bubbleTimer) : 5
        }
    });

    const conferma = async(values) => {
        if(!isEditor) return;
        values = JSON.parse(JSON.stringify(values))
        values = utils.trimStringValues(values);
        values.startMessages = utils.assemblaOggetto(values.startMessages);
        values.suggestedQuestions = utils.assemblaOggetto(values.suggestedQuestions);
        //inizio il caricamento
        setAltro({...altro, loading:true})
        let result;
        //creo la foto in cdn
        if(foto.foto) {
            let formData = new FormData();
            formData.append('image', foto.foto, foto.foto.name);
            result = await chatbotApi.createButtonImage(agency.id, formData);
            if(!result.ok) return setAltro({...altro, loading:false, errore:result.data.message});
            values.buttonPhoto = result.data.url;
        }
        //creo il chatbot
        result = await chatbotApi.updateChatbot(agency.id, {
            chatbotId: chatbot.id, ...values,
            isGraphics:true, onboardingStatus:false
        })
        if(!result.ok) return setAltro({...altro, loading:false, errore:result.data.message})
        //correct
        setTimeout(async() => {
            setFoto({foto:null, preview:null})
            let appo = result.data
            appo.trainings = chatbot.trainings;
            aggChatbot(appo)
            setAltro({loading:false, errore:null, modifiche:false, openAlert:1})
        }, 500);
    }

    const cambiaFoto = (file) => {
        const objectUrl = URL.createObjectURL(file)
        setFoto({foto:file, preview:objectUrl})
    }
    
    const premiCancella = () => {
        if(foto.preview) return setFoto({foto:null, preview:null})
        setValue('buttonPhoto', null)
        setAltro({...altro, modifiche:true})
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }

    return (
        <form
            id="graphics-form"
            name="graphics-form"
            data-name="Graphics Form"
            onSubmit={handleSubmit(conferma)}
        >
            <Box sx={{width:'100%', display: {xs:(visible && altro.modifiche) ? 'flex' : 'none', sm:'none'},
                justifyContent:'flex-end', marginTop:'12px', marginRight:'12px', marginBottom:'-12px'
            }}>
                <BottoneSalva
                    loading={altro.loading}
                    text={'Salva le modifiche'}
                    onClick={() => null}
                ></BottoneSalva>
            </Box>

            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'title.main',
                fontSize:'18px', fontWeight:'600', lineHeight:'normal', margin:'20px 0 2px 4px',
                display:{xs:visible ? 'block' : 'none', sm:'none'}
            }}>Interfaccia</Typography>
            <Box sx={{display:visible ? 'flex' : 'none', position:'relative', flexDirection:{xs:'column', md:'row'},
                width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:0, sm:'40px 0 0', lg:'40px 40px 0'}, border:'1px solid #dcdcdc'
            }}>
                <Box sx={{width:{xs:'100%', md:'50%'}, height:'100%', position:'relative'}}>
                    <Box sx={{padding:{xs:'16px 12px 0', sm:'30px 30px 0'}}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', 
                            marginBottom:{xs:'4px', sm:'10px'}
                        }}>Nome Chat</Typography>
                        <AppTextField
                            width={'100%'}
                            name={'chatName'}
                            isLabel={false}
                            placeholder={'Nome Chat'}
                            register={register("displayName", {required: false, value:chatbot.displayName, onChange:()=>setAltro({...altro, modifiche:true})})}
                            multiline={false}
                        ></AppTextField>
                    </Box>
                    <Box sx={{padding:{xs:'24px 12px 0', sm:'40px 30px 0'}}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                        }}>Messaggi iniziali</Typography>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'1.1', 
                            margin:{xs:'2px 0 6px', sm:'2px 0 14px'}
                        }}>
                            Inserisci i messaggi che compariranno inizialmente nella chat. <br className='newline-no-mobile'/>
                            Per inserire più messaggi è necessario andare a capo riga tra uno e l'altro.
                        </Typography>
                        <ElencoLingue
                            isGrande={false}
                            disponibili={chatbot.languages}
                            lingue={[activeLang.initial]}
                            premiLingua={(l) => setActiveLang({...activeLang, initial:l})}
                        ></ElencoLingue>
                        <AppTextField
                            width={'100%'}
                            name={'initial'}
                            isLabel={false}
                            placeholder={'Messaggi iniziali'}
                            register={{
                                value:getValues('startMessages')[activeLang.initial] || '',
                                onChange:(e) => {
                                    let appo = getValues('startMessages');
                                    appo[activeLang.initial] = e.target.value;
                                    setValue('startMessages', {...appo})
                                    setAltro({...altro, modifiche:true})
                                }
                            }}
                            multiline={1}
                        ></AppTextField>
                    </Box>
                    <Box sx={{padding:{xs:'24px 12px 0', sm:'40px 30px 0'}}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                        }}>Domande suggerite</Typography>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'1.1', 
                            margin:{xs:'2px 0 6px', sm:'2px 0 14px'}
                        }}>
                            Imposta delle domande predefinite da far cliccare agli utenti per 
                            comprendere il funzionamento del tuo chatbot.
                            Per inserire più domande è necessario andare a capo riga tra una e l'altra.
                        </Typography>
                        <ElencoLingue
                            isGrande={false}
                            disponibili={chatbot.languages}
                            lingue={[activeLang.suggested]}
                            premiLingua={(l) => setActiveLang({...activeLang, suggested:l})}
                        ></ElencoLingue>
                        <AppTextField
                            width={'100%'}
                            name={'questions'}
                            isLabel={false}
                            placeholder={'Domande suggerite'}
                            register={{
                                value:getValues('suggestedQuestions')[activeLang.suggested] || '',
                                onChange:(e) => {
                                    let appo = getValues('suggestedQuestions');
                                    appo[activeLang.suggested] = e.target.value;
                                    setValue('suggestedQuestions', {...appo})
                                    setAltro({...altro, modifiche:true})
                                }
                            }}
                            multiline={1}
                        ></AppTextField>
                    </Box>
                    <Box sx={{padding:{xs:'24px 12px 0', sm:'40px 30px 0'}}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                        }}>Nuvoletta sul Bottone</Typography>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'1.1', 
                            margin:{xs:'2px 0 6px', sm:'2px 0 14px'}
                        }}>
                            Scegli se vuoi mostrare le Domande Suggerite in sovraimpressione al bottone sul tuo sito web.
                            Questo servirà per far notare agli utenti la presenza dell'assistente Chat.
                            Puoi anche impostare il timer per personalizzare la comparsa della "Nuvoletta".
                        </Typography>
                        <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:{xs:'flex-start', sm:'center'}, justifyContent:{xs:'flex-start', sm:'space-between'}}}>
                            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:'12px'
                                }}>Abilitato</Typography>
                                <Switch
                                    {...register("isBubble", {
                                        required: false,
                                        value: Boolean(chatbot.isBubble),
                                        onChange:() => setAltro({...altro, modifiche:true})
                                    })}
                                    defaultChecked={Boolean(chatbot.isBubble)}
                                />
                            </Box>
                            {getValues('isBubble') &&
                                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:'12px'
                                    }}>Timer (secondi)</Typography>
                                    <AppTextField
                                        width={'auto'}
                                        name={'bubbleTimer'}
                                        isLabel={false}
                                        placeholder={'Timer di comparsa'}
                                        register={register("bubbleTimer", {
                                            required: false,
                                            value:parseInt(chatbot.bubbleTimer) ? parseInt(chatbot.bubbleTimer) : 5,
                                            onChange:()=>setAltro({...altro, modifiche:true})
                                        })}
                                    ></AppTextField>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box sx={{padding:{xs:'24px 12px 0', sm:'40px 30px 0'}}}>
                        <AppSelect
                            text={'Tema'}
                            options={themes}
                            value={getValues('theme')}
                            onChange={(e) => {
                                setValue('theme', e.target.value)
                                setAltro({...altro, modifiche:true})
                            }}
                        ></AppSelect>
                    </Box>
                    <Box sx={{padding:{xs:'24px 12px 0', sm:'40px 30px 0'}}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', 
                            marginBottom:{xs:'2px', sm:'8px'}
                        }}>Colore dei Messaggi</Typography>
                        <Stack direction='row' spacing={{xs:'36px', sm:'80px'}} sx={{
                            padding:'0 30px 0 0'
                        }}>
                            <AppColorPicker
                                value={getValues('userMessageColor')}
                                text={'messaggio utente'}
                                onChange={(hex) => {
                                    setValue('userMessageColor', hex)
                                    setAltro({...altro, modifiche:true})
                                }}
                            />
                            <AppColorPicker
                                value={getValues('userTextColor')}
                                text={'testo utente'}
                                onChange={(hex) => {
                                    setValue('userTextColor', hex)
                                    setAltro({...altro, modifiche:true})
                                }}
                            />
                        </Stack>
                        <Stack direction='row' spacing={{xs:'36px', sm:'80px'}} sx={{
                            padding:'15px 30px 0 0'
                        }}>
                            <AppColorPicker
                                value={getValues('botMessageColor')}
                                text={'messaggio bot'}
                                onChange={(hex) => {
                                    setValue('botMessageColor', hex)
                                    setAltro({...altro, modifiche:true})
                                }}
                            />
                            <AppColorPicker
                                value={getValues('botTextColor')}
                                text={'testo bot'}
                                onChange={(hex) => {
                                    setValue('botTextColor', hex)
                                    setAltro({...altro, modifiche:true})
                                }}
                            />
                        </Stack>
                    </Box>
                    <Box sx={{padding:{xs:'24px 12px 0', sm:'40px 30px 0'}}}>
                        <AppSelect
                            text={'Posizione del bottone'}
                            options={positions}
                            value={getValues('buttonPosition')}
                            onChange={(e) => {
                                setValue('buttonPosition', e.target.value)
                                setAltro({...altro, modifiche:true})
                            }}
                        ></AppSelect>
                    </Box>
                    <Box sx={{padding:{xs:'24px 12px 0', sm:'40px 30px 0'}, display:'flex', 
                        flexDirection:{xs:'column', lg:'row'}, alignItems:{xs:'flex-start', lg:'center'}
                    }}>
                        <Box sx={{width:{xs:'100%', lg:'70%'}}}>
                            <AppFilePicker
                                text={'Icona del Bottone'}
                                file={foto.foto}
                                caricaFile={(e) => {
                                    cambiaFoto(e.target.files[0])
                                    setAltro({...altro, modifiche:true})
                                }}
                            ></AppFilePicker>
                        </Box>
                        {(getValues('buttonPhoto')?.length > 0 || foto.preview) &&
                            <Box sx={{marginLeft:{xs:0, lg:'90px'}, marginTop:{xs:'12px', lg:'30px'}, display:'flex', 
                                alignItems:'center', justifyContent:'center', position:'relative'
                            }}>
                                <Box component='img' alt='' src={foto.preview ? foto.preview : chatbot.buttonPhoto} sx={{
                                    width:'60px', height:'60px', objectFit:'contain', position:'relative'
                                }}/>
                                <Box sx={{position:'absolute', top:0, right:'-20px', width:'20px', height:'20px', cursor:'pointer',
                                    borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center'
                                }} onClick={() => premiCancella()}>
                                    <CancelIcon sx={{width:'20px', height:'20px', color:'#FC2A1B'}} />
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box sx={{padding:{xs:'28px 12px 24px', sm:'40px 30px 30px'}, display:'flex', 
                        flexDirection:{xs:'column', lg:'row'}, alignItems:{xs:'flex-start', lg:'center'}, justifyContent:'space-between'
                    }}>
                        <Box sx={{display:'block'}}>
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', 
                                marginBottom:{xs:'4px', sm:'10px'}
                            }}>Colore Primario</Typography>
                            <Box sx={{width:'140px'}}>
                                <AppColorPicker
                                    text={null}
                                    value={getValues('buttonColor')}
                                    onChange={(hex) => {
                                        setValue('buttonColor', hex)
                                        setAltro({...altro, modifiche:true})
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{display:'block', width:{xs:'100%', lg:'65%'}, marginTop:{xs:'24px', md:'40px', lg:0}}}>
                            <AppSelect
                                text={'Colore secondario'}
                                options={secondaryColors}
                                value={getValues('secondaryColor')}
                                onChange={(e) => {
                                    setValue('secondaryColor', e.target.value)
                                    setAltro({...altro, modifiche:true})
                                }}
                            ></AppSelect>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', md:'calc(50% - 80px)', lg:'calc(50% - 110px)'}, 
                    padding:{xs:'12px', sm:'40px'}, marginLeft:{xs:0, lg:'30px'}
                }}>
                    <Box sx={{position:'sticky', top:'30px', width:'100%', 
                        height:'fit-content', display:'flex', flexDirection:'column'
                    }}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', 
                            marginBottom:{xs:'4px', sm:'10px'}, display:{xs:'block', md:'none'}
                        }}>Anteprima Chat</Typography>
                        <AnteprimaChat
                            impostazioni={getValues()}
                            previewFoto={foto.preview}
                            startMessages={getValues('startMessages')[activeLang.initial] ? utils.smontaStringa(getValues('startMessages')[activeLang.initial]) : []}
                            suggestedQuestions={getValues('suggestedQuestions')[activeLang.suggested] ? utils.smontaStringa(getValues('suggestedQuestions')[activeLang.suggested]) : []}
                        ></AnteprimaChat>
                    </Box>
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 30px)'}, display: (visible && altro.modifiche && isEditor) ? 'flex' : 'none',
                justifyContent:'flex-end', marginTop:{xs:'12px', sm:'20px'}, marginRight:{xs:'12px', lg:'30px'}
            }}>
                <BottoneSalva
                    isElimina={true}
                    loading={false}
                    text={'Annulla modifiche'}
                    onClick={() => {reset(); setAltro({...altro, modifiche:false})}}
                ></BottoneSalva>
                <Box sx={{width:{xs:'8px', sm:'24px'}}} />
                <BottoneSalva
                    loading={altro.loading}
                    text={'Salva le modifiche'}
                    onClick={() => null}
                ></BottoneSalva>
            </Box>

            <Box sx={{ 
                width:{xs:'calc(100% - 80px)', md:'calc(100% - 195px)', xl:'calc(100% - 330px)'}, height:'55px',
                display:{xs:'none', sm:(visible && altro.modifiche && isEditor) ? 'flex' : 'none'}, alignItems:'flex-end', 
                justifyContent:'flex-end', zIndex:0, position:'absolute', top:{xs:'24px', xl:'40px'}
            }}>
                <BottoneSalva
                    loading={altro.loading}
                    text={'Salva le modifiche'}
                    onClick={() => null}
                ></BottoneSalva>
            </Box>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={'error'}
                message={altro.errore}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Chatbot salvato con successo!'}
            ></AppAlert>
        </form>
    );
}

export default ZonaGrafica;