import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function IntestazioneChatbot({name, colore, icona, goBack, premiMenu, disabled}) {

    return (<>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', marginRight:'-5px'}}>
            <Stack direction='row' spacing={{xs:'14px', sm:'30px'}} sx={{alignItems:'center', zIndex:200}}>
                <KeyboardBackspaceIcon
                    onClick={goBack}
                    sx={{width:{xs:'38px', sm:'50px'}, height:'auto', cursor:'pointer', zIndex:200, color:'black'}}
                />
                <Box sx={{width:{xs:'38px', sm:'50px'}, height:{xs:'38px', sm:'50px'}, borderRadius:'25px',
                    backgroundColor:colore, display:'flex', alignItems:'center', justifyContent:'center', zIndex:200
                }}>
                    {icona &&
                        <Box component='img' alt='' src={icona} sx={{
                            width:{xs:'30px', sm:'40px'}, height:{xs:'30px', sm:'40px'}, objectFit:'contain', zIndex:200
                        }} />
                    }
                </Box>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'24px', sm:'32px'}, fontWeight:'700', lineHeight:'normal', zIndex:200
                }}>{name}</Typography>

                {disabled && <Box sx={{
                    padding:{xs:'8px 12px', sm:'10px 16px'}, borderRadius:'12px', 
                    textTransform: 'none', display:{xs:'none', sm:'flex'}, backgroundColor:'rosso.main'
                }}>
                    <Typography sx={{fontSize:{xs:'13px', sm:'15px', lg:'16px'}, fontWeight:'700',
                        color:'white', lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                    }}>Disabilitato</Typography>
                </Box>}
            </Stack>

            <IconButton sx={{width:'fit-content', height:'36px', borderRadius:'18px', 
                display:{xs:'flex', sm:'none'}, p:0, m:0
            }} onClick={premiMenu}>
                <MoreVertIcon
                    sx={{width:'auto', height:'36px', cursor:'pointer', zIndex:200, color:'black'}}
                />
            </IconButton>
        </Box>
    </>);
}

export default IntestazioneChatbot;