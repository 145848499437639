import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';

import ItemMenuChatbot from './ItemMenuChatbot';

const AppMenu = styled(Menu)({
    '& .MuiPaper-root': {
        backgroundColor:'white',
        borderRadius:'12px',
        border:'1px solid #dcdcdc',
        boxShadow:'none'
    }
})

function MenuChatbot({situazione, cambiaSituazione, anchorEl, open, onClose}) {

    useEffect(() => {
        setTimeout(() => {
            onClose()
        }, 50);
    }, [situazione])

    return (
        <React.Fragment>
            <Stack direction='row' spacing={{xs:'30px', md:0, lg:0}} sx={{display:{xs:'none', sm:'flex'},
                width:{xs:'96%', lg:'90%'}, alignItems:'center', justifyContent:'space-between', 
                backgroundColor:'transparent', marginTop:'24px', marginLeft:{xs:'2%', lg:'60px'},
                marginBottom:'-8px'
            }}>
                <ItemMenuChatbot
                    text={'Impostazioni'}
                    active={situazione === 1}
                    onClick={() => cambiaSituazione(1)}
                ></ItemMenuChatbot>
                <ItemMenuChatbot
                    text={'Interfaccia'}
                    active={situazione === 2}
                    onClick={() => cambiaSituazione(2)}
                ></ItemMenuChatbot>
                <ItemMenuChatbot
                    text={'Allenamento'}
                    active={situazione === 3}
                    onClick={() => cambiaSituazione(3)}
                ></ItemMenuChatbot>
                <ItemMenuChatbot
                    text={'Chat'}
                    active={situazione === 4}
                    onClick={() => cambiaSituazione(4)}
                ></ItemMenuChatbot>
                <ItemMenuChatbot
                    text={'Statistiche'}
                    active={situazione === 5}
                    onClick={() => cambiaSituazione(5)}
                ></ItemMenuChatbot>
                <ItemMenuChatbot
                    text={'Condividi'}
                    active={situazione === 6}
                    onClick={() => cambiaSituazione(6)}
                ></ItemMenuChatbot>
                <ItemMenuChatbot
                    text={'Integrazioni'}
                    active={situazione === 7}
                    onClick={() => cambiaSituazione(7)}
                ></ItemMenuChatbot>
            </Stack>

            <AppMenu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                disableScrollLock={true}
                sx={{p:0, m:0}}
            >
                <Stack direction='column' spacing='0' sx={{minWidth:'160px', p:0, margin:'-8px 0'}}>
                    <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Impostazioni'}
                            active={situazione === 1}
                            onClick={() => cambiaSituazione(1)}
                        ></ItemMenuChatbot>
                    </Box>
                    <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Interfaccia'}
                            active={situazione === 2}
                            onClick={() => cambiaSituazione(2)}
                        ></ItemMenuChatbot>
                    </Box>
                    <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Allenamento'}
                            active={situazione === 3}
                            onClick={() => cambiaSituazione(3)}
                        ></ItemMenuChatbot>
                    </Box>
                    <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Chat'}
                            active={situazione === 4}
                            onClick={() => cambiaSituazione(4)}
                        ></ItemMenuChatbot>
                    </Box>
                    <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Statistiche'}
                            active={situazione === 5}
                            onClick={() => cambiaSituazione(5)}
                        ></ItemMenuChatbot>
                    </Box>
                    <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Condividi'}
                            active={situazione === 6}
                            onClick={() => cambiaSituazione(6)}
                        ></ItemMenuChatbot>
                    </Box>
                    <Box sx={{p:'6px 12px 5px', borderBottom:'0px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Integrazioni'}
                            active={situazione === 7}
                            onClick={() => cambiaSituazione(7)}
                        ></ItemMenuChatbot>
                    </Box>
                </Stack>
            </AppMenu>
        </React.Fragment>
    );
}

export default MenuChatbot;