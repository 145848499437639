import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    borderBottom:'1px solid #dcdcdc',
    '&:last-child': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    }
}));
  
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary {...props}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    />
))(({ theme }) => ({
    padding:'6px 16px',
    backgroundColor:'white',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor:'rgba(0, 0, 0, .03)',
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

function ContenutiChatFrame({contents}) {

    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(-1);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }
    
    return (
        <Box sx={{marginTop:'30px', width:'100%'}}>
            <Typography component='span' sx={{fontSize:{xs:'16px', md:'20px'}, fontWeight:'600', 
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black', marginLeft:'6px'
            }}>{t('chat.fonteDati')}</Typography>

            <Box sx={{width:'100%', borderRadius:'20px', marginTop:'2px',
                overflow:'hidden', border:'1px solid #dcdcdc', boxSizing:'border-box'
            }}>
                {contents.map((content, index) => (
                    <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{
                                textAlign:'left', letterSpacing:'normal', color:'black',
                                fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal'
                            }}>{t('allenamento.contenuto')} {index + 1}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{
                                textAlign:'left', letterSpacing:'-0.2', color:'black', whiteSpace:'pre-line',
                                fontSize:{xs:'13px', sm:'15px'}, fontWeight:'400', lineHeight:'normal'
                            }}>
                                {content.pageContent.replaceAll('\n', ' ').replaceAll('\\t', ' ')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
}

export default ContenutiChatFrame;