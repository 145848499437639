import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import authApi from '../../api/auth'
import userApi from '../../api/user'
import {JWT_TOKEN} from '../../config'
import storage from '../../libs/storage'
import useAuth from '../../controllers/auth/useAuth'
import FormLogin from '../../components/login/FormLogin'
import useAgency from '../../controllers/agency/useAgency'

function Login(props) {

    const navigate = useNavigate()
    const { t } = useTranslation()

    const {login} = useAuth()
    const {agency} = useAgency()

    const [altro, setAltro] = useState({errore:null, loading:false})

    const conferma = async(valori) => {
        if(altro.loading) return
        setAltro({...altro, loading:true, errore:null})
        //effettuo il login
        let result = await authApi.login(agency.id, {
            email: valori.email, password: valori.password, accountId:agency.accountId
        })
        if(!result.ok) return setTimeout(() => {
            setAltro({...altro, loading:false, errore:t(result.data.message)})
        }, 500);
        const TOKEN_KEY = JWT_TOKEN + '-' + agency.id;
        storage.setItem(TOKEN_KEY, result.data.token)
        //scarico i dati utente
        result = await userApi.getMe(agency.id)
        if(!result.ok) return setAltro({...altro, loading:false, errore:result.data.message})
        //correct
        setTimeout(() => {
            setAltro({...altro, errore:null, loading:false})
            login(result.data)
            navigate('/')
        }, 500);
    }

    return (
        <Box sx={{width:'100%', minHeight:'99.9vh', backgroundColor:'#f9f9f9', 
            overflow:'auto', display:'flex', justifyContent:'center', alignItems:'center'
        }}>
            <Stack direction='column' spacing='24px' sx={{alignItems:'center', padding:{xs:'0 16px', sm:0}}}>
                <Box sx={{display:'flex', alignItems:'center', margin:'50px 0 0'}}>
                    <Box component='img' alt='' src={agency.logo} sx={{
                        width:'auto', height:'60px', objectFit:'contain'
                    }} />
                    {agency.showTitle && <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:agency.titleColor,
                        fontSize:'34px', fontWeight:'700', lineHeight:'normal', marginLeft:'12px'
                    }}>{agency.name}</Typography>}
                </Box>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Typography sx={{fontSize:'36px', fontWeight:'500', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black'
                    }}>Bentornato!</Typography>
                    <Typography sx={{paddingTop:'0px', paddingBottom:'4px', fontSize:'14px', fontWeight:'400', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#96A0AD'
                    }} dangerouslySetInnerHTML={{__html: "Effettua il login al tuo account"}}></Typography>
                </Box>
                <FormLogin
                    errore={altro.errore}
                    loading={altro.loading}
                    conferma={conferma}
                ></FormLogin>
            </Stack>
        </Box>
    );
}

export default Login;