import React from 'react';

function ChatIcon({fill}) {
    return (
        <svg 
            width='24px' height='24px' fill={fill} id="Layer_1" 
            data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
        >
            <title>speech-bubble-2-outline</title>
            <path className="cls-1" d="M256,51.2c127,0,230.4,91.87,230.4,204.8,0,38.44-12.13,76-35.08,108.5l-9.13,13,7.52,14,35.76,66.31.28.52.3.5a5.73,5.73,0,0,1,.35.7h0a2.5,2.5,0,0,1-1.3,1.37,2.72,2.72,0,0,1-.77-.35l-.5-.3-.52-.28-69.07-37.25-13.12-7.07-12.63,7.92c-39,24.42-84.79,37.34-132.5,37.34C129,460.8,25.6,368.93,25.6,256S129,51.2,256,51.2m0-25.6C114.61,25.6,0,128.75,0,256S114.61,486.4,256,486.4c54.32,0,104.66-15.27,146.09-41.24l69.07,37.24a26.86,26.86,0,0,0,13.91,4c19,0,34.55-21.49,22.94-40.84l-35.76-66.31C497.38,343.6,512,301.35,512,256,512,128.75,397.38,25.6,256,25.6Z"/>
            <path className="cls-1" d="M332.8,217.6H179.2a12.8,12.8,0,0,1,0-25.6H332.8a12.8,12.8,0,1,1,0,25.6Z"/>
            <path className="cls-1" d="M332.8,268.8H179.2a12.8,12.8,0,0,1,0-25.6H332.8a12.8,12.8,0,1,1,0,25.6Z"/>
            <path className="cls-1" d="M332.8,320H179.2a12.8,12.8,0,0,1,0-25.6H332.8a12.8,12.8,0,1,1,0,25.6Z"/>
        </svg>
    );
}

export default ChatIcon;