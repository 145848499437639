import React from 'react';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

function BottoneElimina({text, loading, onClick, isLungo, disabled}) {

    if(isLungo) return(
        <LoadingButton
            color="rosso"
            variant="contained"
            sx={{
                border:'1px solid #dcdcdc', width:'100%',
                padding:{xs:'8px 24px', sm:'12px 40px'}, 
                borderRadius:'12px', textTransform: 'none'
            }}
            disableElevation={true}
            onClick={onClick}
            loading={loading}
            disabled={disabled}
            loadingPosition={'center'}
        >
            <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', 
                letterSpacing:'-0.2', textAlign:'left', color:'white'
            }}>{text}</Typography>
        </LoadingButton>
    );
    
    return (
        <LoadingButton
            color="rosso"
            variant="contained"
            sx={{
                border:'1px solid #dcdcdc', padding:{xs:'6px 20px', sm:'8px 35px'}, 
                borderRadius:'12px', textTransform: 'none'
            }}
            disableElevation={true}
            loading={loading}
            disabled={disabled}
            loadingPosition={'center'}
            onClick={onClick}
        >
            <Typography component='span' sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'700', lineHeight:'normal', 
                letterSpacing:'-0.2', textAlign:'center', color:'white'
            }}>{text}</Typography>
        </LoadingButton>
    );
}

export default BottoneElimina;