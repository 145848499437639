import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import IconaRobot from '../../images/robot.png'

function BottoneChatbot({text, onClick}) {
    
    return (
        <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{border:'1px solid #dcdcdc', padding:{xs:'12px 16px', sm:'12px 20px'}, borderRadius:'16px', textTransform: 'none'}}
            endIcon={<Box component='img' src={IconaRobot} alt='' sx={{width:{xs:'24px', sm:'28px'}, height:'auto'}} />}
            disableElevation={true}
            onClick={onClick}
        >
            <Typography noWrap component='span' sx={{fontSize:{xs:'15px', sm:'20px'}, fontWeight:'500',
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginRight:{xs:'6px', sm:'12px'}
            }}>{text}</Typography>
        </Button>
    );
}

export default BottoneChatbot;