import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import utils from '../../libs/utils';
import BottoneSito from './BottoneSito';
import chatbotApi from '../../api/chatbot';
import AppAlert from '../generale/AppAlert';
import BottoneAllena from './BottoneAllena';
import SceltaLinksSito from './SceltaLinksSito';
import ModalAggiungiSiti from './ModalAggiungiSiti';
import useAgency from '../../controllers/agency/useAgency';
import IntestazioneAllenamento from '../intestazione/IntestazioneAllenamento';

import LanguageIcon from '@mui/icons-material/Language';

function AllenamentoSito({
    conferma, loading, situazione, loadingAllena, goBack, cambiaAltro, 
    charsLimitXBot, caratteriAttuali, premiUpgrade, isSitemap, isScraping, chatbotId
}) {

    const {agency} = useAgency()

    const [siti, setSiti] = useState([''])
    const [errore, setErrore] = useState(null)
    const [caricati, setCaricati] = useState([])
    const isCaricati = Boolean(caricati.length > 0)
    const [visible, setVisible] = useState(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const clear = () => {
            setSiti([''])
            setCaricati([])
        }
        if(situazione == 0) clear()
    }, [situazione])

    useEffect(() => {
        if(!loading) return;
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress == 100 ? 100 : prevProgress + 1));
        }, 1210);
        return () => {
            clearInterval(timer);
        }
    }, [loading]);

    const addLink = () => {
        const appo = Array.from(siti)
        appo.push('')
        setSiti(appo)
    }

    const removeLink = (pos) => {
        if(siti.length == 1) return cambiaAltro({errore:'Almeno 1 link deve essere inserito.'})
        const appo = siti.filter((el, index) => index != pos)
        setSiti(appo)
    }

    const changeLinkValue = (index, value) => {
        const appo = Array.from(siti)
        appo[index] = value
        setSiti(appo)
    }

    const confermaSiti = async() => {
        //controllo urls
        if(!siti.length) return cambiaAltro({errore:'Inserire almeno 1 Link'})
        let regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        for(let i = 0; i < siti.length; i++) {
            if(!regexp.test(siti[i])) {
                setErrore(i)
                return cambiaAltro({errore:'Link non valido.'})
            }
        }
        setErrore(null)
        //leggo contenuto dai links
        cambiaAltro({errore:null, loading:true})
        let result = await chatbotApi.readWebsites(agency.id, {links:siti, chatbotId})
        if(!result.ok) return cambiaAltro({errore:result.data.message, loading:false})
        //confermo upload
        setTimeout(() => {
            setCaricati([...caricati, ...result.data.websites])
            cambiaAltro({errore:null, loading:false})
            if(visible) setVisible(2)
            setSiti([''])
        }, 300);
    }

    const confermaSitoConScraping = async(sitoweb) => {
        //controllo link
        if(!sitoweb?.length) {
            setErrore(0)
            return cambiaAltro({errore:'Inserire il sito web da analizzare.'})
        }
        let regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        if(!regexp.test(sitoweb)) {
            setErrore(0)
            return cambiaAltro({errore:'Sito web non valido.'})
        }
        setErrore(null)
        //leggo contenuto dai links
        cambiaAltro({errore:null, loading:true})
        let result = await chatbotApi.readWebsiteByScraping(agency.id, {link:sitoweb, chatbotId})
        if(!result.ok) return cambiaAltro({errore:result.data.message, loading:false})
        //confermo upload
        setTimeout(() => {
            setCaricati([...caricati, ...result.data.websites])
            if(result.data.isInterrotto) setVisible(3)
            cambiaAltro({errore:null, loading:false})
            setSiti([''])
        }, 300);
    }

    const confermaSitoConSitemap = async(sitemapUrl) => {
        //controllo link
        if(!sitemapUrl?.length) {
            setErrore(0)
            return cambiaAltro({errore:'Inserire la Sitemap del sito web da analizzare.'})
        }
        let regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        if(!regexp.test(sitemapUrl)) {
            setErrore(0)
            return cambiaAltro({errore:'Sitemap non valida.'})
        }
        setErrore(null)
        //leggo contenuto dai links
        cambiaAltro({errore:null, loading:true})
        let result = await chatbotApi.readWebsiteBySitemap(agency.id, {link:sitemapUrl, chatbotId})
        if(!result.ok) return cambiaAltro({errore:result.data.message, loading:false})
        //confermo upload
        setTimeout(() => {
            setCaricati([...caricati, ...result.data.websites])
            cambiaAltro({errore:null, loading:false})
            setSiti([''])
        }, 300);
    }

    const removeSito = (pos) => {
        if(caricati.length == 1) return cambiaAltro({errore:'Almeno 1 link deve essere inserito.'})
        const appo = caricati.filter((f, index) => index != pos)
        setCaricati(appo)
    }

    const contaTotaleCaratteri = () => {
        let cont = 0
        for(let i = 0; i < caricati.length; i++) cont += caricati[i].cont
        return cont
    }

    const isFuoriCaratteri = () => {
        if(charsLimitXBot === -1) return false;
        let cont = contaTotaleCaratteri()
        if(cont + caratteriAttuali >= charsLimitXBot) return true
        return false
    }

    const clickConferma = async() => {
        if(isFuoriCaratteri()) return cambiaAltro({errore:'Limite di caratteri superato.'})
        let references = []
        for(let i = 0; i < caricati.length; i++) {
            references.push(caricati[i].link)
        }
        await conferma(caricati, caricati[0].link, 'sitoweb', references)
    }

    const scriviSottotitolo = () => {
        if(!isCaricati) return "Aggiungi i link delle pagine web che vuoi scansionare"
        return "Conferma i contenuti prestando attenzione al limte di caratteri"
    }

    const iniziaLettura = async() => {
        if(isSitemap) return await confermaSitoConSitemap(siti[0])
        if(isScraping) return await confermaSitoConScraping(siti[0])
        return await confermaSiti()
    }

    const scriviTestoBottone = () => {
        if(isSitemap) return 'Conferma Sitemap'
        if(isScraping) return 'Conferma Sito'
        return 'Conferma Links'
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setVisible(false)
    }

    return (
        <Box sx={{width:'100%', padding:{xs:'24px 0', sm:'40px 0'}, display:'flex', 
            flexDirection:'column', alignItems:'center', justifyContent:'center'
        }}>
            <IntestazioneAllenamento
                situazione={3}
                goBack={goBack}
                fasi={2}
                faseAttuale={isCaricati ? 2 : 1}
            ></IntestazioneAllenamento>
            <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969', padding:{xs:'0 24px', sm:0},
                fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.1', sm:'normal'}, marginTop:{xs:'8px', sm:'12px'}
            }}>
                {scriviSottotitolo()}. <br className='newline-no-mobile'/>
                Esempio: https://esempio.com
            </Typography>
            
            {!isCaricati ? (<>
                <SceltaLinksSito
                    isSitemap={isSitemap}
                    isScraping={isScraping}
                    siti={siti}
                    errore={errore}
                    addLink={addLink}
                    removeLink={removeLink}
                    changeLinkValue={changeLinkValue}
                ></SceltaLinksSito>
                {isScraping && loading &&
                    <Box sx={{paddingBottom:{xs:'12px', sm:'20px', md:'40px'}, display:'flex', 
                        width:{xs:'85%', sm:'60%'}, alignItems:'center'
                    }}>
                        <LinearProgress variant="determinate" value={progress} sx={{
                            width:'100%', height:'12px', borderRadius:'4px'
                        }} />
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black', marginLeft:{xs:'8px', sm:'16px'},
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'600', lineHeight:{xs:'1.1', sm:'normal'}
                        }}>
                            {progress}%
                        </Typography>
                    </Box>
                }
                <BottoneAllena
                    isConferma={false}
                    isSitoWeb={true}
                    loading={loading}
                    Icon={LanguageIcon}
                    text={scriviTestoBottone()}
                    onClick={async() => await iniziaLettura()}
                ></BottoneAllena>
            </>) : (<>
                <Box sx={{margin:{xs:'30px 8px 30px', sm:'50px 25px 40px'}, width:{xs:'calc(100% - 16px)', sm:'calc(100% - 50px)'}, 
                    display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'
                }}>
                    <Box sx={{width:'100%', display:'flex', flexDirection:{xs:'column', sm:'row'}, 
                        alignItems:{xs:'flex-start', sm:'flex-end'}, justifyContent:'space-between'
                    }}>
                        <Typography sx={{textAlign:{xs:'center', sm:'left'}, letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal',
                            color:'#696969', marginBottom:{xs:'3px', sm:0}
                        }}>
                            Hai caricato {caricati.length} links con {utils.formatNumber(contaTotaleCaratteri())} caratteri totali.
                        </Typography>
                        <BottoneSito
                            tipologia={2}
                            text={'Aggiungi Link'}
                            onClick={() => setVisible(1)}
                        ></BottoneSito>
                    </Box>
                    <Stack direction='column' spacing={{xs:'20px', sm:'20px'}} sx={{width:'100%', marginTop:{xs:'24px', sm:'16px'}}}>
                        {caricati.map((sito, index) => (
                            <Stack key={'sito' + index} direction={{xs:'column', sm:'row'}} 
                                spacing={{xs:'6px', sm:'12px'}} sx={{width:'100%'}}
                            >
                                <Box sx={{display:'flex', flex:1, padding:{xs:'4px 8px', sm:'8px 16px', md:'8px 24px'},
                                    borderRadius:'12px', border:'1px solid #DCDCDC'
                                }}>
                                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal'
                                    }}>{sito.link}</Typography>
                                </Box>
                                <Box sx={{display:'flex', width:{xs:'fit-content', sm:'120px', md:'160px'},
                                    padding:{xs:'4px 8px', sm:'8px 16px', md:'8px 24px'},
                                    borderRadius:'12px', border:'1px solid #DCDCDC'
                                }}>
                                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal'
                                    }}>{utils.formatNumber(sito.cont)} caratteri</Typography>
                                </Box>
                                <BottoneSito
                                    tipologia={1}
                                    text={'Rimuovi Link'}
                                    onClick={() => removeSito(index)}
                                ></BottoneSito>
                            </Stack>
                        ))}
                    </Stack>
                </Box>
                {isFuoriCaratteri() &&
                    <Box sx={{marginBottom:{xs:'24px', sm:'40px'}, marginTop:{xs:0, sm:'10px'}, display:'flex', 
                        flexDirection:'column', alignItems:'center', width:{xs:'70%', sm:'100%'}
                    }}>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Il numero massimo di caratteri consentiti per Chatbot è di {utils.formatNumber(charsLimitXBot)}.
                        </Typography>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Confermando l'allenamento il totale di caratteri arriverà a&nbsp;
                            <span style={{color:'#FC2A1B', fontWeight:'bold'}}>{utils.formatNumber(contaTotaleCaratteri() + caratteriAttuali)}</span>.
                        </Typography>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Esegui&nbsp;
                            <Typography component='span' sx={{display:'inline-block', fontSize:{xs:'13px', sm:'16px'}, cursor:'pointer',
                                color:'primary.main', fontWeight:'700', textDecoration:'underline'
                            }} onClick={premiUpgrade}>l'upgrade</Typography>
                            &nbsp;per aumentare il limite di caratteri.
                        </Typography>
                    </Box>
                }
                <BottoneAllena
                    isConferma={true}
                    loading={loadingAllena}
                    onClick={async() => clickConferma()}
                    text={loadingAllena ? 'Magia AI in corso' : 'Allena Chatbot'}
                ></BottoneAllena>
            </>)}

            <ModalAggiungiSiti
                visible={visible === 1}
                chiudi={() => {
                    if(loading) return
                    setVisible(false)
                    setSiti([''])
                }}
                siti={siti}
                errore={errore}
                loading={visible === 1 && loading}
                addLink={addLink}
                removeLink={removeLink}
                changeLinkValue={changeLinkValue}
                confermaSiti={confermaSiti}
            ></ModalAggiungiSiti>

            <AppAlert
                open={visible === 2}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Links aggiunti con successo'}
            ></AppAlert>
            <AppAlert
                open={visible === 3}
                chiudiAlert={chiudiAlert}
                severity={'warning'}
                message={'Lettura del sito web interrotta per limite di tempo.'}
            ></AppAlert>
        </Box>
    );
}

export default AllenamentoSito;