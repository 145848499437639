import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import utils from '../../libs/utils';
import ElencoLingue from './ElencoLingue';
import chatbotApi from '../../api/chatbot';
import AppAlert from '../generale/AppAlert';
import ContattiChatbot from './ContattiChatbot';
import NotificheChatbot from './NotificheChatbot';
import BottoneSalva from '../generale/BottoneSalva';
import AppTextField from '../generale/AppTextField';
import BottoneElimina from '../generale/BottoneElimina';
import useAgency from '../../controllers/agency/useAgency';

function ZonaImpostazioni({
    visible, chatbot, aggChatbot, isDomain,
    togliDomain, premiElimina, isAdmin, isEditor
}) {

    const domainRef = useRef();
    const privacyRef = useRef();
    const messageRef = useRef();

    const {agency} = useAgency();
    const isReader = !isAdmin && !isEditor;

    const [lang, setLang] = useState(chatbot.languages);
    const [activeLang, setActiveLang] = useState({
        privacy:chatbot.languages[0], message:chatbot.languages[0]
    });
    const [altro, setAltro] = useState({
        loading:false, errore:null, modifiche:false, openAlert:0
    });
    const [isLimit, setIsLimit] = useState(chatbot.monthlyLimit >= 0);

    useEffect(() => {
        if(!visible) setTimeout(() => {
            chiudiAlert();
        }, 1000);
    }, [visible])

    useEffect(() => {
        if(isDomain) domainRef?.current?.scrollIntoView({
            behavior:"smooth", block:"end", offset:200
        })
    }, [isDomain])

    useEffect(() => {
        const aggSingoleLingue = () => {
            const appo = {...activeLang};
            if(!lang.includes(appo.privacy)) appo.privacy = lang[0];
            if(!lang.includes(appo.message)) appo.message = lang[0];
            setActiveLang({...appo});
        }
        aggSingoleLingue()
    }, [lang])

    const schema = yup.object().shape({
        name: yup.string().required('Il nome è obbligatorio.'),
        instructions: yup.string().max(300, "Massimo 300 caratteri.").nullable(),
        scope: yup.string().max(200, "Massimo 200 caratteri.").required('L\'ambito è obbligatorio.'),
        privacyPolicy: yup.object().shape({
            it:lang.includes('it') ? yup.string().url('Link alle Privacy Policy non valido.').nullable() : yup.string().nullable(),
            en:lang.includes('en') ? yup.string().url('Link alle Privacy Policy non valido.').nullable() : yup.string().nullable(),
            fr:lang.includes('fr') ? yup.string().url('Link alle Privacy Policy non valido.').nullable() : yup.string().nullable(),
            de:lang.includes('de') ? yup.string().url('Link alle Privacy Policy non valido.').nullable() : yup.string().nullable(),
            es:lang.includes('es') ? yup.string().url('Link alle Privacy Policy non valido.').nullable() : yup.string().nullable()
        }),
        domain: yup.string(),
        monthlyLimit: yup.number('Il limite deve essere numerico.').required(),
        limitNumber: yup.number('Il limite deve essere numerico.').min(1, 'Il limite deve essere > 0.').required(),
        limitSeconds: yup.number('Il limite deve essere numerico.').min(1, 'Il limite deve essere > 0.').required(),
        limitMessage: yup.object().shape({
            it:lang.includes('it') ? yup.string().required('Il messaggio è obbligatorio.') : yup.string().nullable(),
            en:lang.includes('en') ? yup.string().required('Il messaggio è obbligatorio.') : yup.string().nullable(),
            fr:lang.includes('fr') ? yup.string().required('Il messaggio è obbligatorio.') : yup.string().nullable(),
            de:lang.includes('de') ? yup.string().required('Il messaggio è obbligatorio.') : yup.string().nullable(),
            es:lang.includes('es') ? yup.string().required('Il messaggio è obbligatorio.') : yup.string().nullable()
        }),
        contacts: yup.object().shape({
            enabled:yup.boolean(), email:yup.boolean(), fullName:yup.boolean(), phone:yup.boolean()
        }),
        liveChat: yup.object().shape({enabled:yup.boolean(), form:yup.boolean(),
            email:yup.boolean(), fullName:yup.boolean(), phone:yup.boolean()
        }),
        notifications: yup.object().shape({
            chat:yup.boolean(), operator:yup.boolean(), contacts:yup.boolean(),
            mailTo: yup.string().email('L\'indirizzo email deve essere valido.')
        }),
        openaiKey: yup.string().nullable()
    });
    const {register, getValues, setValue, handleSubmit, reset, formState: { errors }} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            openaiKey: chatbot.openaiKey,
            privacyPolicy: chatbot.privacyPolicy,
            limitMessage: chatbot.limitMessage,
            monthlyLimit: chatbot.monthlyLimit,
            contacts: {
                phone: chatbot?.contacts?.phone || false,
                email: chatbot?.contacts?.email || false,
                fullName: chatbot?.contacts?.fullName || false,
                enabled: chatbot?.contacts?.enabled || false,
            },
            liveChat: {
                form: chatbot?.liveChat?.form || false,
                email: chatbot?.liveChat?.email || false,
                phone: chatbot?.liveChat?.phone || false,
                enabled: chatbot?.liveChat?.enabled || false,
                fullName: chatbot?.liveChat?.fullName || false,
            },
            notifications: {
                mailTo: chatbot?.notifications?.mailTo || '',
                chat: chatbot?.notifications?.chat || false,
                contacts: chatbot?.notifications?.contacts || false,
                operator: chatbot?.notifications?.operator || false
            }
        }
    });

    useEffect(() => {
        if(errors.limitMessage) {
            setActiveLang({...activeLang, message:Object.keys(errors.limitMessage)[0]})
            return messageRef?.current?.focus()
        }
        if(errors.privacyPolicy) {
            setActiveLang({...activeLang, privacy:Object.keys(errors.privacyPolicy)[0]})
            return privacyRef?.current?.focus()
        }
    }, [errors])

    const conferma = async(values) => {
        if(!isEditor) return;
        if(values.contacts?.enabled) {
            if(!values.contacts?.email && !values.contacts?.phone
                && !values.contacts?.fullName
            ) return setAltro({...altro, errore:'Inserire almeno un campo nel Form di Contatto.'});
        }
        if(!lang.length) return setAltro({...altro, errore:'Inserire almeno una lingua per il chatbot.'});
        setAltro({...altro, loading:true})
        values = utils.trimStringValues(values);
        const result = await chatbotApi.updateChatbot(agency.id, {
            chatbotId:chatbot.id, ...values,
            isGraphics:false, languages:lang
        })
        if(!result.ok) return setAltro({...altro, loading:false, errore:result.data.message})
        setTimeout(() => {
            togliDomain()
            let appo = result.data
            appo.trainings = chatbot.trainings;
            aggChatbot(appo)
            setAltro({loading:false, errore:null, modifiche:false, openAlert:1})
        }, 500);
    }

    const premiLang = (l) => {
        if(lang.includes(l)) setLang(lang.filter((el) => el != l))
        else {lang.push(l); setLang(lang)}
        setAltro({...altro, modifiche:true})
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }

    return (
        <form
            id="settings-form"
            name="settings-form"
            data-name="Settings Form"
            onSubmit={handleSubmit(conferma)}
        >
            <Box sx={{width:'100%', display: {xs:(visible && altro.modifiche) ? 'flex' : 'none', sm:'none'},
                justifyContent:'flex-end', marginTop:'12px', marginRight:'12px', marginBottom:'-12px'
            }}>
                <BottoneSalva
                    loading={altro.loading}
                    text={'Salva le modifiche'}
                    onClick={() => null}
                ></BottoneSalva>
            </Box>

            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'title.main',
                fontSize:'18px', fontWeight:'600', lineHeight:'normal', margin:'20px 0 2px 4px',
                display:{xs:visible ? 'block' : 'none', sm:'none'}
            }}>Impostazioni</Typography>
            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', position:'relative', 
                margin:{xs:0, sm:'40px 0 0', lg:'40px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', display: visible ? 'block' : 'none'
            }}>
                <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'1px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Nome Interno</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Inserisci il nome che avrà il bot all'interno di questa applicazione. <br className='newline-no-mobile'/>
                        Questo nome non verrà mai mostrato agli utenti.
                    </Typography>
                    <AppTextField
                        name={'name'}
                        isLabel={false}
                        width={'700px'}
                        error={errors.name?.message}
                        placeholder={'Nome Interno'}
                        register={register("name", {required: false, value:chatbot.name, onChange:()=>setAltro({...altro, modifiche:true})})}
                    ></AppTextField>
                </Box>

                <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'1px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Linguaggio</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Seleziona le lingue in cui vuoi personalizzare i messaggi di avviso e suggerimento del tuo chatbot. <br className='newline-no-mobile' />
                        Se l'utente non appartiene a nessuna delle lingue attivate, la lingua di default viene impostata su Inglese se attivo, oppure nella prima lingua disponibile.
                    </Typography>
                    <ElencoLingue
                        lingue={lang}
                        isGrande={true}
                        premiLingua={premiLang}
                        disponibili={['it', 'en', 'de', 'fr', 'es']}
                    ></ElencoLingue>
                </Box>

                {!isReader && <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'1px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Chiave AI</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Inserisci la tua chiave segreta di OpenAI sulla quale verranno addebitati i costi di AI di questo chatbot.
                    </Typography>
                    <AppTextField
                        name={'openaiKey'}
                        isLabel={false}
                        width={'700px'}
                        error={errors.openaiKey?.message}
                        placeholder={'Chiave AI'}
                        isPassword={true}
                        register={register("openaiKey", {required: false, value:chatbot.openaiKey, onChange:()=>setAltro({...altro, modifiche:true})})}
                    ></AppTextField>
                </Box>}

                <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'1px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Ambito del chatbot</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Inserisci l'ambito di cui il chatbot è abilitato a discutere con gli utenti. <br className='newline-no-mobile'/>
                        Se l'utente farà richieste che non riguardano l'ambito inserito, il bot eviterà di rispondergli.<br className='newline-no-mobile'/><br/><br className='newline-only-mobile'/>
                        <b>ES)</b> Luca Rossi, mental coach per giovani imprenditori.<br/>
                        <b>ES)</b> Stepcode.io, una software house e web agency.<br/>
                        <b>ES)</b> Farmaco SRL, un azienda di prodotti per la cura della pelle.<br/>
                    </Typography>
                    <AppTextField
                        width={'100%'}
                        name={'scope'}
                        isLabel={false}
                        error={errors.scope?.message}
                        placeholder={'Ambito del chatbot'}
                        register={register("scope", {required: false, value:chatbot.scope, onChange:()=>setAltro({...altro, modifiche:true})})}
                    ></AppTextField>

                    <Box sx={{display:'flex', flexDirection:{xs:'column', lg:'row'}, marginTop:{xs:'20px', sm:'30px'}, 
                        alignItems:{xs:'flex-start', lg:'flex-end'}, justifyContent:'space-between'
                    }}>
                        <div>
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                            }}>Istruzioni aggiuntive</Typography>
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                                fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                            }}>
                                Inserisci ulteriori istruzioni da dare all'Intelligenza Artificiale per gestire al meglio le 
                                conversazioni che dovrà intrattenere con gli utenti. <br className='newline-no-mobile'/>
                                <b>Attenzione: </b>si consiglia di fornire le istruzioni in lingua Inglese per una migliore comprensione da parte del Chatbot.
                            </Typography>
                        </div>
                    </Box>
                    <AppTextField
                        name={'instructions'}
                        isLabel={false}
                        error={errors.instructions?.message}
                        placeholder={'Istruzioni aggiuntive'}
                        register={register("instructions", {required: false, value:chatbot.instructions, onChange:()=>setAltro({...altro, modifiche:true})})}
                        width={'100%'}
                        multiline={3}
                    ></AppTextField>
                </Box>

                <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'1px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:isDomain ? '#FC2A1B' : 'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Dominio</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Inserisci il dominio del sito web su cui vuoi abilitare questo bot.
                        Lasciare vuoto in caso non sia necessario applicare un sito web. <br className='newline-no-mobile'/>
                        Inserisci il dominio che vuoi utilizzare (senza HTTP o HTTPS).
                    </Typography>
                    <AppTextField
                        name={'domain'}
                        isLabel={false}
                        width={'700px'}
                        error={isDomain ? 'Inserisci il dominio del tuo sito web!' : errors.domain?.message}
                        placeholder={'esempio.it'}
                        register={register("domain", {required: false, value:chatbot.domain, onChange:()=>setAltro({...altro, modifiche:true})})}
                    ></AppTextField>
                    <div ref={domainRef} />
                </Box>

                <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'1px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:isDomain ? '#FC2A1B' : 'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Privacy Policy</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Inserisci il link alle privacy policy per i vostri utenti.
                    </Typography>
                    <ElencoLingue
                        isGrande={false}
                        disponibili={lang}
                        lingue={[activeLang.privacy]}
                        premiLingua={(l) => setActiveLang({...activeLang, privacy:l})}
                    ></ElencoLingue>
                    <AppTextField
                        name={'privacyPolicy'}
                        isLabel={false}
                        width={'700px'}
                        error={errors.privacyPolicy ? (
                            errors.privacyPolicy[Object.keys(errors.privacyPolicy)[0]]?.message
                        ) : null}
                        placeholder={'https://esempio.it/privacy-policy'}
                        register={{
                            inputRef:privacyRef,
                            value:getValues('privacyPolicy')[activeLang.privacy] || '',
                            onChange:(e) => {
                                let appo = getValues('privacyPolicy');
                                appo[activeLang.privacy] = e.target.value;
                                setValue('privacyPolicy', {...appo})
                                setAltro({...altro, modifiche:true})
                            }
                        }}
                    ></AppTextField>
                </Box>

                <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'1px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:isDomain ? '#FC2A1B' : 'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Limite Messaggi Mensili</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', marginTop:'5px'
                    }}>
                        Assegna un limite massimo di messaggi mensili per questo chatbot.&nbsp;
                        <br className='newline-no-mobile'/>
                        Se il limite non viene assegnato, questo chatbot potrà inviare un numero di messaggi infinito.
                    </Typography>
                    <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'},
                        alignItems:{xs:'flex-start', sm:'center'}, marginTop:{xs:'12px', sm:'18px'}
                    }}>
                        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:{xs:'12px', sm:'12px'}
                            }}>Abilita limite</Typography>
                            <Switch
                                value={isLimit}
                                defaultChecked={isLimit}
                                onChange={() => {
                                    setValue('monthlyLimit', isLimit ? -1 : 1000);
                                    setIsLimit(!isLimit);
                                    setAltro({...altro, modifiche:true});
                                }}
                            />
                        </Box>
                        <Stack direction={{xs:'column', sm:'row'}} spacing={{xs:'4px', sm:'16px'}} sx={{
                            alignItems:{xs:'flex-start', sm:'center'}, display:isLimit ? 'flex' : 'none', marginLeft:{xs:0, sm:'80px'}
                        }}>
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal'
                            }}>Imposta il limite mensile:</Typography>
                            <AppTextField
                                name={'monthlyLimit'}
                                isLabel={false}
                                placeholder={'Num'}
                                error={errors.monthlyLimit?.message}
                                register={register("monthlyLimit", {required: false, value:chatbot.monthlyLimit, onChange:()=>setAltro({...altro, modifiche:true})})}
                                width={'150px'}
                            ></AppTextField>
                        </Stack>
                    </Box>
                </Box>

                <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'none'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Limitazione della velocità</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', marginTop:'5px'
                    }}>
                        Limita il numero di messaggi inviati da un dispositivo sull'iframe e sulla bolla della chat 
                        (questo limite verrà applicato sul tuo sito web per i tuoi utenti per evitare abusi).
                    </Typography>

                    <Stack direction={{xs:'column', sm:'row'}} spacing={{xs:'4px', sm:'16px'}} sx={{
                        alignItems:{xs:'flex-start', sm:'center'}, marginTop:{xs:'12px', sm:'18px'}
                    }}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'14px', sm:'18px'}, fontWeight:'400', lineHeight:'normal'
                        }}>Limita a solo</Typography>
                        <AppTextField
                            name={'limitNum'}
                            isLabel={false}
                            placeholder={'Num'}
                            error={errors.limitNumber?.message}
                            register={register("limitNumber", {required: false, value:chatbot.limitNumber, onChange:()=>setAltro({...altro, modifiche:true})})}
                            width={'100px'}
                        ></AppTextField>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'14px', sm:'18px'}, fontWeight:'400', lineHeight:'normal'
                        }}>messaggi ogni</Typography>
                        <AppTextField
                            name={'limitSec'}
                            isLabel={false}
                            placeholder={'Num'}
                            error={errors.limitSeconds?.message}
                            register={register("limitSeconds", {required: false, value:chatbot.limitSeconds, onChange:()=>setAltro({...altro, modifiche:true})})}
                            width={'100px'}
                        ></AppTextField>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'14px', sm:'18px'}, fontWeight:'400', lineHeight:'normal'
                        }}>secondi.</Typography>
                    </Stack>

                    <Box sx={{marginTop:{xs:'12px', sm:'18px'}}}>
                        <Stack direction={{xs:'column', sm:'row'}} spacing={{xs:'8px', sm:'24px'}} sx={{margin:'2px 0 8px'}}>
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                fontSize:{xs:'14px', sm:'18px'}, fontWeight:'400', lineHeight:{xs:'1.1', sm:'normal'}
                            }}>Mostra questo messaggio quando viene raggiunto il limite:</Typography>
                            <ElencoLingue
                                isGrande={false}
                                disponibili={lang}
                                lingue={[activeLang.message]}
                                premiLingua={(l) => setActiveLang({...activeLang, message:l})}
                            ></ElencoLingue>
                        </Stack>
                        <AppTextField
                            name={'limitMessage'}
                            isLabel={false}
                            width={'700px'}
                            placeholder={'Messaggio per limite superato'}
                            error={errors.limitMessage ? (
                                errors.limitMessage[Object.keys(errors.limitMessage)[0]]?.message
                            ) : null}
                            register={{
                                inputRef: messageRef,
                                value:getValues('limitMessage')[activeLang.message] || '',
                                onChange:(e) => {
                                    let appo = getValues('limitMessage');
                                    appo[activeLang.message] = e.target.value;
                                    setValue('limitMessage', {...appo})
                                    setAltro({...altro, modifiche:true})
                                }
                            }}
                        ></AppTextField>
                    </Box>
                </Box>
            </Box>

            <ContattiChatbot
                chatbot={chatbot}
                visible={visible}
                register={register}
                getValues={getValues}
                cambiaAltro={(v) => setAltro({...altro, ...v})}
            ></ContattiChatbot>

            <NotificheChatbot
                errors={errors}
                chatbot={chatbot}
                visible={visible}
                register={register}
                cambiaAltro={(v) => setAltro({...altro, ...v})}
            ></NotificheChatbot>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:'12px 0 0', sm:'25px 0 0', lg:'25px 40px 0'}, overflow:'hidden', border:'1px solid #FC2A1B',
                display: visible && isAdmin ? 'block' : 'none'
            }}>
                <Box sx={{padding:{xs:'12px', sm:'30px'}}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Elimina Chatbot</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'5px 0 12px'
                    }}>
                        Questo chatbot non ti serve più? Premi qui per eliminarlo. <br className='newline-no-mobile'/>
                        Ricorda: una volta eliminato, il tuo chatbot e le sue impostazioni non potranno più essere recuperate.
                    </Typography>
                    <BottoneElimina
                        text={'Elimina'}
                        loading={false}
                        onClick={premiElimina}
                    ></BottoneElimina>
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 30px)'}, display: (visible && altro.modifiche && isEditor) ? 'flex' : 'none',
                justifyContent:'flex-end', marginTop:{xs:'12px', sm:'20px'}, marginRight:{xs:'12px', lg:'30px'}
            }}>
                <BottoneSalva
                    isElimina={true}
                    loading={false}
                    text={'Annulla modifiche'}
                    onClick={() => {reset(); setAltro({...altro, modifiche:false})}}
                ></BottoneSalva>
                <Box sx={{width:{xs:'8px', sm:'24px'}}} />
                <BottoneSalva
                    loading={altro.loading}
                    text={'Salva le modifiche'}
                    onClick={() => null}
                ></BottoneSalva>
            </Box>

            <Box sx={{ 
                width:{xs:'calc(100% - 80px)', md:'calc(100% - 195px)', xl:'calc(100% - 330px)'}, height:'55px',
                display:{xs:'none', sm:(visible && altro.modifiche && isEditor) ? 'flex' : 'none'}, alignItems:'flex-end', 
                justifyContent:'flex-end', zIndex:0, position:'absolute', top:{xs:'24px', xl:'40px'}
            }}>
                <BottoneSalva
                    loading={altro.loading}
                    text={'Salva le modifiche'}
                    onClick={() => null}
                ></BottoneSalva>
            </Box>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={'error'}
                message={altro.errore}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Chatbot salvato con successo!'}
            ></AppAlert>
        </form>
    );
}

export default ZonaImpostazioni;