import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        margin: 0,
        borderRadius: '12px',
        color:'black',
        fontSize:'inherit',
        '& fieldset': {
            borderWidth:0,
        },
        '&:hover fieldset': {
            borderWidth:0,
        },
        '&.Mui-focused fieldset': {
            borderWidth:0,
            borderColor: 'primary.main'
        }
    },
    "& .MuiOutlinedInput-input": {
        padding: 0
    }
})

function SitoTextField({name, placeholder, value, onChange, error}) {

    return (
        <Box sx={{display:'flex', flexDirection:'column', flex:1, fontSize:{xs:'13px', sm:'16px'}}}>
            <StyledTextField
                id={name + new Date().toLocaleTimeString()}
                variant="outlined"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                InputProps={{style:{color:"#737373"}, autoComplete:"off", autoCorrect: "off"}}
                sx={{
                    height:{xs:'34px', sm:'40px'}, display:'flex', justifyContent:'center', 
                    border:error ? '1px solid #FC2A1B' : '1px solid #DCDCDC', borderRadius:'12px',
                    padding: {xs:'0 12px', sm:'0 24px'},
                }}
                onKeyDown={(e) => e.stopPropagation()}
                autoComplete={undefined}
            />
        </Box>
    );
}

export default SitoTextField;