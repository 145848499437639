import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import chatbotApi from '../api/chatbot';
import useAuth from '../controllers/auth/useAuth';
import AppAlert from '../components/generale/AppAlert';
import RetrainQA from '../components/retrain/RetrainQA';
import useAgency from '../controllers/agency/useAgency';
import useChatbot from '../controllers/chatbots/useChatbot';
import RetrainTesto from '../components/retrain/RetrainTesto';
import RetrainSources from '../components/retrain/RetrainSources';
import useAbbonamento from '../controllers/abbonamento/useAbbonamento';
import ModalConfermaRetrain from '../components/retrain/ModalConfermaRetrain';
import IntestazioneRetrain from '../components/intestazione/IntestazioneRetrain';

function RetrainChatbot(props) {

    const navigate = useNavigate()
    const {state} = useLocation()

    const {agency} = useAgency()
    const {isEditor} = useAuth()
    const {subscription} = useAbbonamento()
    const {chatbots, chatbotsLoading, updateChatbot} = useChatbot()

    const [chatbot, setChatbot] = useState(null)
    const [training, setTraining] = useState(null)
    const [altro, setAltro] = useState({
        errore:null, loading:false, openAlert:0, 
        modifiche:false, visible:false, sendData:null
    })

    useEffect(() => {
        const aggData = () => {
            if(!state?.chatbot) return navigate('/')
            if(!state?.training) return navigate('/')
            const appo = chatbots.filter((el) => el.id == state.chatbot.id)[0]
            if(appo) setChatbot(appo)
            if(!appo && !chatbotsLoading) return navigate('/')
            setTraining(state.training)
        }
        aggData()
    }, [state, chatbots, chatbotsLoading])

    const contaCaratteriAttuali = useCallback(() => {
        let cont = 0;
        for(let i = 0; i < chatbot?.trainings.length; i++) {
            if(training?.id != chatbot?.trainings[i].id) cont += chatbot?.trainings[i].characters
        }
        return cont
    }, [chatbot, training])

    const retrainChatbot = async(contents, name, source, references, messageId) => {
        if(altro.loading) return
        if(!contents.length) return setAltro({...altro, visible:false, errore:'Le informazioni dell\'allenamento non possono essere vuote'})
        if(!name.length) return setAltro({...altro, visible:false, errore:'Inserire il nome dell\'allenamento'})
        setAltro({...altro, errore:null, loading:true, visible:false})
        const result = source != 'domande' ? await chatbotApi.retrainChatbot(agency.id, {
            trainingId:training.id, chatbotId:chatbot.id, contents:contents,
            name:name, trainingType:source, references:references, background:false
        }) : await chatbotApi.retrainChatbotQA(agency.id, {
            trainingId:training.id, chatbotId:chatbot.id, contents:contents,
            name:name, trainingType:source, messageId:messageId
        })
        if(!result.ok) return setAltro({...altro, errore:result.data.message, loading:false, visible:false})
        setTimeout(() => {
            const appo = {...chatbot}
            appo.trainings = appo.trainings.filter((el) => el.id != training.id)
            appo.trainings.push(result.data)
            updateChatbot(appo)
            setAltro({...altro, loading:false, errore:null, openAlert:1})
            navigate('/chatbot', {state:{chatbot:chatbot, situazione:3, isRetrain:true}})
        }, 500);
    }

    const clickConferma = (contents, name, source, references, messageId) => {
        setAltro({...altro, visible:true, sendData:{
            contents, name, source, references, messageId
        }})
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }

    if(chatbot && training) return (
        <Box sx={{
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'36px 12px 16px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <Box sx={{position:{xs:'unset', sm:'relative'}}}>
                <IntestazioneRetrain
                    training={training}
                    goBack={() => navigate('/chatbot', {state:{chatbot:chatbot, situazione:3}})}
                />

                {(training.trainingType == 'domande' || training.trainingType == 'testo') && <Box sx={{
                    width:{xs:'100%', lg:'100%'}, backgroundColor:'white', position:{xs:'relative', md:'unset'}, 
                    margin:{xs:'65px 0', sm:'78px 0 0', lg:'16px 0 0'}, overflow:{xs:'visible', md:'hidden'}, 
                    borderRadius:'20px', border:'1px solid #dcdcdc', boxSizing:'border-box'
                }}>
                    {training.trainingType == 'testo' &&
                        <RetrainTesto
                            isEditor={isEditor}
                            training={training}
                            conferma={clickConferma}
                            loading={altro.loading}
                            isModifiche={altro.modifiche}
                            premiUpgrade={() => navigate('/settings', {state:{situazione:3}})}
                            cambiaAltro={(value) => setAltro({...altro, ...value})}
                            charsLimitXBot={subscription?.metrics.charsLimitXBot}
                            caratteriAttuali={contaCaratteriAttuali()}
                        ></RetrainTesto>
                    }
                    {training.trainingType == 'domande' &&
                        <RetrainQA
                            isEditor={isEditor}
                            training={training}
                            conferma={clickConferma}
                            loading={altro.loading}
                            isModifiche={altro.modifiche}
                            premiUpgrade={() => navigate('/settings', {state:{situazione:3}})}
                            cambiaAltro={(value) => setAltro({...altro, ...value})}
                            charsLimitXBot={subscription?.metrics.charsLimitXBot}
                            caratteriAttuali={contaCaratteriAttuali()}
                        ></RetrainQA>
                    }
                </Box>}

                {training.trainingType != 'domande' && training.trainingType != 'testo' &&
                    <RetrainSources
                        isEditor={isEditor}
                        training={training}
                        conferma={clickConferma}
                        loading={altro.loading}
                        isModifiche={altro.modifiche}
                        premiUpgrade={() => navigate('/settings', {state:{situazione:3}})}
                        cambiaAltro={(value) => setAltro({...altro, ...value})}
                        charsLimitXBot={subscription?.metrics.charsLimitXBot}
                        caratteriAttuali={contaCaratteriAttuali()}
                    ></RetrainSources>
                }
            </Box>

            <ModalConfermaRetrain
                visible={altro.visible}
                conferma={async() => await retrainChatbot(
                    altro.sendData.contents, altro.sendData.name,
                    altro.sendData.source, altro.sendData.references,
                    altro.sendData.messageId
                )}
                chiudi={() => setAltro({...altro, visible:false})}
            ></ModalConfermaRetrain>
            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={"error"}
                message={altro.errore}
            ></AppAlert>
        </Box>
    );
}

export default RetrainChatbot;