import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import IconaRazzo from '../../images/razzo.png';

function BottoneAllena({
    text, onClick, Icon, isConferma, isSitoWeb, width, loading, disabled
}) {

    if(isConferma) return (
        <LoadingButton
            color={"primary"}
            variant="contained"
            loading={loading}
            disabled={disabled}
            loadingPosition={'end'}
            sx={{
                width:width ? width : 'auto', padding:{xs:'12px 24px', sm:'13px 35px'}, 
                borderRadius:'12px', textTransform: 'none', border:'1px solid #dcdcdc'
            }}
            endIcon={<Box component='img' alt='' src={IconaRazzo} sx={{width:{xs:'20px', sm:'26px'}, height:'auto'}} />}
            disableElevation={true}
            onClick={onClick}
        >
            <Typography component='span' sx={{fontSize:{xs:'15px', sm:'20px'}, fontWeight:'700',
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', marginRight:{xs:'4px', sm:'8px'}
            }}>{text}</Typography>
        </LoadingButton>
    );

    if(isSitoWeb) return (
        <LoadingButton
            color={"primary"}
            variant="contained"
            loading={loading}
            disabled={disabled}
            loadingPosition={'end'}
            sx={{
                width:width ? width : 'auto', padding:{xs:'12px 24px', sm:'13px 35px'},
                borderRadius:'12px', textTransform: 'none', border:'1px solid #dcdcdc'
            }}
            endIcon={<Icon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='white' />}
            disableElevation={true}
            onClick={onClick}
        >
            <Typography component='span' sx={{fontSize:{xs:'15px', sm:'20px'}, fontWeight:'700', lineHeight:'normal', 
                letterSpacing:'-0.2', textAlign:'center', marginRight:{xs:'4px', sm:'8px'}
            }}>{text}</Typography>
        </LoadingButton>
    );

    return (
        <Button
            color="primary"
            variant="contained"
            sx={{
                width:width ? width : '220px', padding:'12px 0', border:'1px solid #dcdcdc', 
                borderRadius:'12px', textTransform: 'none', height:'fit-content'
            }}
            disabled={disabled}
            startIcon={<Icon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='white' />}
            disableElevation={true}
            onClick={onClick}
        >
            <Typography component='span' sx={{fontSize:{xs:'15px', sm:'20px'}, fontWeight:'700',
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'6px', sm:'12px'}
            }}>{text}</Typography>
        </Button>
    );
}

export default BottoneAllena;