import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import chatbotApi from '../../api/chatbot';
import BottoneElimina from '../generale/BottoneElimina';
import useAgency from '../../controllers/agency/useAgency';

function ModalEliminaAllenamento({visible, chiudi, training, chatbot, removeTraining}) {

    const {agency} = useAgency()
    const [altro, setAltro] = useState({loading:false, errore:null})
    
    const isErrore = Boolean(altro.errore)
    const trainingName = training?.name.replace('Allenamento ', '')

    const conferma = async() => {
        setAltro({errore:null, loading:true})
        const result = await chatbotApi.deleteTraining(agency.id, training.id, {
            messageId:training.messageId
        })
        if(!result.ok) return setAltro({...altro, errore:result.data.message})
        setTimeout(() => {
            removeTraining(training.id)
            setAltro({errore:null, loading:false})
        }, 500);
    }

    if(training) return (
        <Modal
            open={visible}
            closeAfterTransition
            keepMounted
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', 
                width:'100%', height:'100%', position:'relative', backgroundColor:'rgba(0, 0, 0, 0.2)'
            }} onClick={chiudi}>
                <Box sx={{width:{xs:'calc(100% - 24)', sm:'fit-content'}, backgroundColor:'white', borderRadius:'16px',
                    padding:{xs:'24px 16px', sm:'45px 40px', md:'45px 40px', lg:'65px 40px'}, display:'flex', flexDirection:'column', alignItems:'center',
                    border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)', margin:{xs:'0 12px', sm:'0 100px'}
                }} onClick={(e) => e.stopPropagation()}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'19px', sm:'24px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Elimina Allenamento</Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969', margin:'5px 0 0',
                        fontSize:{xs:'15px', sm:'18px'}, fontWeight:'400', lineHeight:{xs:'1.1', sm:'normal'}
                    }}>Vuoi eliminare definitivamente questo allenamento?</Typography>
                    <Box sx={{padding:{xs:'20px 0 0', sm:'20px 0 0'}, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Typography component='span' sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'15px', sm:'18px'}, fontWeight:'400', lineHeight:{xs:'1.1', sm:'normal'},
                            maxWidth:{xs:'90%', sm:'70%'}
                        }}>
                            Cancellando l'allenamento toglierai al tuo chatbot
                            la conoscenza acquisita sugli argomenti dell'allenamento in questione.
                        </Typography>
                    </Box>
                    <Box sx={{padding:'20px 0 0'}}>
                        <Typography component='p' sx={{textAlign:'center', letterSpacing:'-0.2', color:'rosso.main',
                            fontSize:{xs:'15px', sm:'18px'}, fontWeight:'600', lineHeight:{xs:'1.1', sm:'normal'}
                        }}>
                            {trainingName}
                        </Typography>
                    </Box>
                    {isErrore &&
                        <Typography sx={{fontSize:'16px', margin:'8px 0', fontWeight:'600',
                            lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#FC2A1B'
                        }}>{altro.errore}</Typography>
                    }
                    <Stack direction='row' spacing='32px' sx={{paddingTop:'30px'}}>
                        <BottoneElimina
                            isLungo={true}
                            text={'Conferma'}
                            loading={altro.loading}
                            onClick={async() => await conferma()}
                        ></BottoneElimina>
                        <Button
                            color="nero"
                            variant="contained"
                            sx={{
                                border:'1px solid #dcdcdc', width:'100%', 
                                padding:{xs:'8px 24px', sm:'12px 40px'}, 
                                borderRadius:'12px', textTransform: 'none'
                            }}
                            disableElevation={true}
                            onClick={chiudi}
                        >
                            <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'},
                                fontWeight:'500', letterSpacing:'-0.2', textAlign:'left'
                            }}>Annulla</Typography>
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalEliminaAllenamento;