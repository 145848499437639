import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import utils from '../../libs/utils';

import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

function RigaAllenamento({training, premiElimina, premiModifica, isElimina, isPrimo, isModifica, isEditor}) {

    const isVecchio = training.isOldTraining;

    const scriviSottotitolo = () => {
        let appo = training.trainingType == 'testo' ? '' : training.references.length
        if(training.trainingType == 'sitoweb') appo += ' link - '
        if(training.trainingType == 'pdf') appo += ' file - '
        if(training.trainingType == 'documents') appo += ' file - '
        if(training.trainingType == 'domande') appo += ' domande - '
        if(training.trainingType == 'word') appo += ' file - '
        if(training.trainingType == 'excel') appo += ' file - '
        appo += moment(training.updatedAt).format('DD/MM/YYYY HH:mm');
        return appo
    }

    return (
        <Box sx={{
            width:{xs:'100%', sm:'100%'}, backgroundColor:'white', 
            marginTop:isPrimo ? {xs:0, sm:'18px'} : {xs:'12px', sm:'18px'}, p:{xs:'12px', sm:'24px', md:'36px'}, 
            border:'1px solid #dcdcdc', display:'flex', flexDirection:'column', borderRadius:'20px', 
            overflow:'hidden', boxSizing:'border-box'
        }}>
            <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, 
                alignItems:{xs:'flex-start', sm:'center'}, justifyContent:'space-between'
            }}>
                <Box sx={{display:'flex', flex:1, flexDirection:'column', alignItems:'flex-start', maxWidth:{xs:'100%', sm:'75%', md:'83%'}}}>
                    <Box sx={{display:'flex', width:'100%', maxWidth:'100%', alignItems:'center'}}>
                        {training.trainingType == 'sitoweb' &&
                            <LanguageIcon sx={{width:'25px', height:'auto'}} color='black' />
                        }
                        {training.trainingType == 'documents' &&
                            <ArticleIcon sx={{width:'25px', height:'auto'}} color='black' />
                        }
                        {training.trainingType == 'pdf' &&
                            <ArticleIcon sx={{width:'25px', height:'auto'}} color='black' />
                        }
                        {training.trainingType == 'testo' &&
                            <ModeEditIcon sx={{width:'25px', height:'auto'}} color='black' />
                        }
                        {training.trainingType == 'domande' &&
                            <QuestionAnswerIcon sx={{width:'25px', height:'auto'}} color='black' />
                        }
                        {training.trainingType == 'word' &&
                            <ArticleIcon sx={{width:'25px', height:'auto'}} color='black' />
                        }
                        {training.trainingType == 'excel' &&
                            <ArticleIcon sx={{width:'25px', height:'auto'}} color='black' />
                        }
                        <Typography sx={{fontSize:{xs:'17px', sm:'19px', md:'23px', lg:'25px'}, fontWeight:'500', color:'black', 
                            lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'8px', sm:'16px'},
                            maxWidth:{xs:'calc(100% - 33px)', sm:'calc(100% - 40px)'}, overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'
                        }}>{training.name}</Typography>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:{xs:'flex-start', sm:'center'}, paddingTop:{xs:0, sm:'6px'}}}>
                        <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, fontWeight:'600', color:'#808080', 
                            lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginTop:{xs:'6px', md:0}
                        }}>({scriviSottotitolo()})</Typography>
                        <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, fontWeight:'600', color:'#808080', 
                            lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', 
                            marginLeft:{xs:0, sm:'16px'}, marginTop:{xs:'6px', md:0}
                        }}>({utils.formatNumber(training.characters)} caratteri)</Typography>
                    </Box>
                </Box>
                {!training.completed &&
                    <Typography sx={{fontSize:{xs:'17px', sm:'17px', md:'17px', lg:'17px'}, fontWeight:'600', color:'orange', 
                        lineHeight:{xs:'1.0', sm:'normal'}, letterSpacing:'-0.2', textAlign:'left', marginRight:{xs:'24px', sm:'48px'},
                    }}>In esecuzione</Typography>
                }
                <Stack direction='row' spacing='20px' sx={{marginTop:{xs:'12px', md:0}}}>
                    {isModifica && !isVecchio &&
                        <IconButton sx={{width:{xs:'38px', sm:'50px'}, height:{xs:'38px', sm:'50px'},
                            backgroundColor:'white', border:'1px solid #808080', borderRadius:'10px'
                        }} onClick={premiModifica}>
                            <BuildIcon sx={{width:{xs:'22px', sm:'28px'}, height:'auto', color:'#808080'}} />
                        </IconButton>
                    }
                    {isEditor && <IconButton sx={{width:{xs:'38px', sm:'50px'}, height:{xs:'38px', sm:'50px'},
                        backgroundColor:'white', border:'1px solid #808080', borderRadius:'10px'
                    }} onClick={premiElimina}>
                        {!isElimina && <DeleteIcon sx={{width:{xs:'22px', sm:'28px'}, height:'auto', color:'#808080'}} />}
                        {isElimina && <CircularProgress sx={{width:{xs:'22px', sm:'28px'}, height:{xs:'22px', sm:'28px'}}} />}
                    </IconButton>}
                </Stack>
            </Box>
        </Box>
    );
}

export default RigaAllenamento;