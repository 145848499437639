import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function ItemMenuChatbot({text, active, onClick}) {

    return (
        <Box sx={{display:'flex', alignItems:'center',
            borderBottom: active ? '3px solid' : '3px solid',
            borderBottomColor:active ? 'primary.main' : 'transparent'
        }}>
            <Button
                type="submit"
                color="nero"
                variant="text"
                sx={{
                    border:'none', padding:{xs:0, sm:'12px 0 0'}, textTransform: 'none',
                    '&:hover': {backgroundColor:'transparent'}
                }}
                disableElevation={true}
                disableRipple={true}
                onClick={onClick}
            >
                <Typography component='span' sx={{fontSize:{xs:'16px', md:'18px', lg:'19px'}, fontWeight:'600', 
                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'black'
                }}>{text}</Typography>
            </Button>
        </Box>
    );
}

export default ItemMenuChatbot;