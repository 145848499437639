import { useContext } from "react";

import AgencyContext from "./context";

const useAgency = () => {

    const { agency, setAgency } = useContext(AgencyContext)

    const aggAgency = (v) => setAgency({...v})

    return {
        agency,
        aggAgency
    }
}

export default useAgency;