import { useContext } from "react";

import AbbonamentoContext from "./context";

const useAbbonamento = () => {

    const { abbonamento, setAbbonamento } = useContext(AbbonamentoContext);

    const aggSubscription = (value) => {
        setAbbonamento({...value})
    }

    return {
        subscription: abbonamento,
        aggSubscription
    }
}

export default useAbbonamento;