import { useContext } from "react";

import ChatbotContext from "./context";

const useChatbot = () => {

    const { chatbots, setChatbots } = useContext(ChatbotContext);

    const addChatBot = (value) => {
        const appo = [...chatbots.list]
        appo.push(value)
        setChatbots({loading:false, list:appo})
    }

    const updateChatbot = (value) => {
        const appo = [...chatbots.list]
        for(let i = 0; i < appo.length; i++) {
            if(appo[i].id == value.id) appo[i] = {...value}
        }
        setChatbots({loading:false, list:appo})
    }

    const removeChatbot = (id) => {
        const appo = [...chatbots.list].filter((el) => el.id != id)
        setChatbots({loading:false, list:appo})
    }

    return { 
        chatbots: chatbots.list,
        chatbotsLoading: chatbots.loading,

        addChatBot,
        updateChatbot,
        removeChatbot
    }
}

export default useChatbot;