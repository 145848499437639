import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import useAgency from '../../controllers/agency/useAgency';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

function Cella({isHeader, text, width, borderLeft, borderRight, align, isUltimo, 
    isAzioni, isChatbots, chatbots, vediChatbots, premiModifica, premiElimina
}) {

    const {agency} = useAgency()

    const isTuttiChatbots = useCallback(() => {
        if(!chatbots?.length) return;
        if(chatbots[0] == '*') return true;
        else return false;
    }, [chatbots])

    return (
        <TableCell
            sx={{
                width:width ? width : 'auto', backgroundColor:isHeader ? '#F9F9FF' : 'white', 
                padding:{xs:'11px', sm:'16px 24px'}, borderBottom:isUltimo ? 'none' : '1px solid #dcdcdc', borderTop:'none',
                borderLeft:borderLeft ? '1px solid #dcdcdc' : 'none',
                borderRight:borderRight ? '1px solid #dcdcdc' : 'none'
            }}
            align={align}
        >
            {!isAzioni && !isChatbots && <Box sx={{display:'flex', alignItems:'center'}}>
                <Typography noWrap sx={{fontSize:'16px', fontWeight:isHeader ? 500 : 600,
                    color:isHeader ? '#696969' : 'black'
                }}>
                    {text}
                </Typography>
            </Box>}

            {isChatbots && <Box sx={{display:'flex', alignItems:'center'}}>
                {isTuttiChatbots() ? (
                    <Typography sx={{fontSize:'15px', fontWeight:isHeader ? 500 : 600,
                        color:isHeader ? '#696969' : 'black'
                    }}>TUTTI</Typography>
                ) : (<>
                    <IconButton sx={{padding:'2px', margin:0, marginRight:'5px',
                        backgroundColor:'white', border:'none', borderRadius:'50%'
                    }} onClick={(event) => vediChatbots(event, chatbots)}>
                        <VisibilityOutlinedIcon sx={{width:{xs:'24px', sm:'24px'}, height:'auto', color:'#696969'}} />
                    </IconButton>
                    <Typography noWrap sx={{fontSize:'15px', fontWeight:isHeader ? 500 : 600,
                        color:isHeader ? '#696969' : 'black'
                    }}>{chatbots?.length} Chatbot</Typography>
                </>)}
            </Box>}

            {isAzioni && <Box sx={{display:'flex', alignItems:'center'}}>
                <Button
                    color="primary"
                    variant="text"
                    sx={{border:'none', padding:'0', textTransform: 'none'}}
                    disableElevation={true}
                    disableRipple={true}
                    onClick={premiModifica}
                >
                    <Typography component='span' sx={{fontSize:{xs:'13px', md:'16px'}, fontWeight:'500',
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:agency.titleColor
                    }}>Modifica</Typography>
                </Button>
                <Box sx={{width:'20px'}} />
                <Button
                    color="rosso"
                    variant="text"
                    sx={{border:'none', padding:'0', textTransform: 'none'}}
                    disableElevation={true}
                    disableRipple={true}
                    onClick={premiElimina}
                >
                    <Typography component='span' sx={{fontSize:{xs:'13px', md:'16px'}, fontWeight:'500',
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center'
                    }}>Elimina</Typography>
                </Button>
            </Box>}
        </TableCell>
    );
}

export default Cella;