import { useContext } from "react";

import AuthContext from "./context";

const useAuth = () => {

    const { user, setUser } = useContext(AuthContext);

    const login = async(data) => {
        setUser({...data, loading:false});
    }

    const logout = async() => {
        setUser({account:null, user:null, loading:true});
    }

    const aggUser = (v) => setUser({...user, user:v})
    const aggAccount = (v) => setUser({...user, account:v})

    return {
        user: user?.user,
        account: user?.account,
        userLoading: user?.loading,

        isAdmin: user?.user?.role === 'admin',
        isEditor: user?.user?.role === 'admin' || user?.user?.role === 'editor',

        login, 
        logout,
        aggUser,
        aggAccount
    }
}

export default useAuth;