import React from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const AppInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '12px',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #DCDCDC',
        fontSize: 'inherit',
        padding: '7px 26px 6px 24px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: '12px',
            border: '1px solid #DCDCDC',
            boxShadow: 'none',
        },
    },
}));

function AppSelect({text, options, value, onChange, width}) {
    
    return (
        <Box sx={{display:'flex', flexDirection:'column', width:width ? {xs:'100%', sm:width} : '100%'}}>
            {text && <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'4px', sm:'10px'}
            }}>{text}</Typography>}
            <FormControl sx={{width:width ? {xs:'100%', sm:width} : '100%'}}>
                <Select
                    value={value}
                    onChange={onChange}
                    displayEmpty
                    input={<AppInput />}
                    sx={{fontSize:{xs:'14px', sm:'16px'}}}
                >
                    {options.map((opt, index) => (
                        <MenuItem key={'opt' + index} value={opt.id}>{opt.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default AppSelect;