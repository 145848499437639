import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={'100%'}
        height={'100%'}
        viewBox="0 0 512 512"
        {...props}
    >
        <path d="M232.3 1C172.5 6.8 117.7 32.6 75.2 75c-39.7 39.7-64 88.2-72.9 146-2.4 16.1-2.4 53.9 0 70 8.9 57.7 33 105.9 72.7 145.8 39.7 39.7 88.2 64 146 72.9 16.1 2.4 53.9 2.4 70 0 57.7-8.9 105.9-33 145.8-72.7 39.7-39.7 64-88.2 72.9-146 2.4-16.1 2.4-53.9 0-70-8.9-57.7-33-105.9-72.7-145.8-39.4-39.5-88.5-64.1-145-72.7C279.5.6 245.2-.2 232.3 1zm56.2 41.5c46.2 7.2 86.9 27.9 120 61 33.4 33.4 53.8 73.8 61.2 121 1.2 7.8 1.7 17 1.7 31.5s-.5 23.7-1.7 31.5c-7.4 47.2-27.8 87.6-61.2 121-33.4 33.4-73.8 53.8-121 61.2-15.1 2.4-47.9 2.4-63 0-47.2-7.4-87.6-27.8-121-61.2-33.4-33.4-53.8-73.8-61.2-121-2.4-15.1-2.4-47.9 0-63 7.4-47.2 27.8-87.6 61.2-121 36.8-36.8 80.5-57.2 135-62.9 8.1-.8 40.3.4 50 1.9z" />
        <path d="M347 170.6c-2.2.9-25.7 23.7-64.7 62.7L221 294.4l-25.7-25.6c-14.2-14-27.2-26.2-28.8-27.1-10.3-5.2-21.9-1.5-27 8.7-3 6-3.2 11.5-.5 17 1.2 2.7 14.2 16.3 36.7 38.7 27.4 27.3 35.6 34.9 38.7 35.9 5.1 1.6 12.8.7 16.9-2.1 4.8-3.3 136.3-134.7 140-139.9 2.8-4 3.2-5.3 3.2-11.2 0-5.8-.4-7.2-3-10.9-5.6-7.9-15.9-11-24.5-7.3z" />
    </svg>
);

export default SvgComponent