import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import BottoneSito from './BottoneSito';
import BottoneAllena from './BottoneAllena';
import SitoTextField from '../generale/SitoTextField';

import LanguageIcon from '@mui/icons-material/Language';

function ModalAggiungiSiti({
    visible, chiudi, siti, errore, loading, addLink, removeLink, changeLinkValue, confermaSiti
}) {

    return (
        <Modal
            open={visible}
            closeAfterTransition
            keepMounted
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', 
                width:'100%', height:'100%', position:'relative', backgroundColor:'rgba(0, 0, 0, 0.2)'
            }} onClick={chiudi}>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'80vw', md:'65vw'}, backgroundColor:'white', borderRadius:'16px',
                    padding:{xs:'24px 12px', sm:'45px 25px'}, display:'flex', flexDirection:'column', alignItems:'center',
                    border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)', margin:{xs:'0 12px', sm:'0 100px'},
                    maxHeight:'80vh', overflowY:'auto'
                }} onClick={(e) => e.stopPropagation()}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'19px', sm:'24px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Aggiungi Siti Web</Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969', padding:{xs:'0 24px', sm:0},
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.1', sm:'normal'}, marginTop:{xs:'8px', sm:'12px'}
                    }}>
                        Aggiungi altri siti all'allenamento attuale. <br className='newline-no-mobile'/>
                        Esempio: https://esempio.com
                    </Typography>

                    <Box sx={{width:'100%', display:'flex', flexDirection:{xs:'column', sm:'row'},
                        alignItems:{xs:'flex-start', sm:'flex-end'}, justifyContent:'space-between', paddingTop:'30px'
                    }}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969', marginBottom:{xs:'3px', sm:0},
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                        }}>Hai inserito {siti.length} links.</Typography>
                        <BottoneSito
                            tipologia={2}
                            text={'Aggiungi Link'}
                            onClick={() => addLink()}
                        ></BottoneSito>
                    </Box>

                    <Stack direction='column' spacing={{xs:'20px', sm:'20px'}} sx={{
                        width:'100%', marginTop:{xs:'24px', sm:'10px'}, marginBottom:'30px'
                    }}>
                        {siti.map((sito, index) => (
                            <Stack key={'site' + index} direction={{xs:'column', sm:'row'}} 
                                spacing={{xs:'6px', sm:'12px'}} sx={{width:'100%'}}
                            >
                                <SitoTextField
                                    name={'Sito' + index}
                                    placeholder={'Link pagina web'}
                                    value={sito}
                                    error={errore === index}
                                    onChange={(e) => changeLinkValue(index, e.target.value)}
                                ></SitoTextField>
                                <BottoneSito
                                    tipologia={1}
                                    text={'Rimuovi Link'}
                                    onClick={() => removeLink(index)}
                                ></BottoneSito>
                            </Stack>
                        ))}
                    </Stack>

                    <BottoneAllena
                        isConferma={false}
                        isSitoWeb={true}
                        loading={loading}
                        Icon={LanguageIcon}
                        text={'Conferma Links'}
                        onClick={async() => await confermaSiti()}
                    ></BottoneAllena>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalAggiungiSiti;