import React from 'react';
import moment from 'moment/moment';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import useAuth from '../../controllers/auth/useAuth';

function Intestazione() {

    const {user, userLoading} = useAuth()

    const scriviTitolo = () => {
        let frase = ''
        const date = moment().subtract({hours:24});
        if(moment(user.createdAt).isBefore(date)) {
            frase = '👋 Bentornato {name}'
        } else frase = '👋 Benvenuto {name}'
        if(user) frase = frase.replace('{name}', user?.fullName?.split(' ')[0])
        return frase
    }

    return (
        <Box sx={{width:'100%', display:'flex', flexDirection:'column',
            position:'relative', marginBottom:{xs:'-8px', md:0}
        }}>
            {userLoading ? (
                <Skeleton variant="rounded" animation="wave" sx={{
                    width:{xs:'100px', sm:'450px'}, height:{xs:'30px', sm:'50px'}
                }} />
            ) : (
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'24px', sm:'36px'}, fontWeight:'700', lineHeight:'normal'
                }}>{scriviTitolo()}</Typography>
            )}
        </Box>
    );
}

export default Intestazione;