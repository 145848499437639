import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import chatbotApi from '../../api/chatbot'
import AppAlert from '../generale/AppAlert'
import AllenamentoChatbot from './AllenamentoChatbot'
import ModalCosaAggiungere from './ModalCosaAggiungere'
import useAgency from '../../controllers/agency/useAgency'
import ModalEliminaAllenamento from './ModalEliminaAllenamento'

function ZonaAllenaChatbot({
    visible, chatbot, subscription, aggChatbot, premiUpgrade, isDisabled, isEditor,
    isPaymentFailed, modificaAllenamento, correggiDomanda, goToStatistiche
}) {

    const {agency} = useAgency();

    const [situazione, setSituazione] = useState(0)
    const [elimina, setElimina] = useState({visible:false, training:null})
    const [altro, setAltro] = useState({loading:false, loadingAllena:false, openAlert:0, errore:null, visible:0})

    useEffect(() => {
        const aggSituazione = () => {
            setSituazione(0)
            setTimeout(() => {
                chiudiAlert();
            }, 1000);
        }
        if(!visible) aggSituazione()
    }, [visible])

    useEffect(() => {
        const aggDomanda = () => {
            setSituazione(4)
        }
        if(correggiDomanda) aggDomanda()
    }, [correggiDomanda])

    const allenaChatbot = async(contents, name, source, references) => {
        if(altro.loadingAllena) return
        if(isDisabled) return setAltro({...altro, errore:'Questo bot è stato disabilitato perchè non incluso nel tuo abbonamento.'})
        if(isPaymentFailed) return setAltro({...altro, errore:'Il tuo abbonamento non è valido.'})
        if(!contents.length) return setAltro({...altro, errore:'Le informazioni dell\'allenamento non possono essere vuote'})
        if(!name.length) return setAltro({...altro, errore:'Inserire il nome dell\'allenamento'})
        setAltro({...altro, errore:null, loadingAllena:true})
        const result = source != 'domande' ? (
            source != 'sitoweb' ? await chatbotApi.trainChatbot(agency.id, {
                chatbotId:chatbot.id, contents:contents, name:name,
                trainingType:source, references:references
            }) : await chatbotApi.createTraining(agency.id, {
                chatbotId:chatbot.id, contents:contents, name:name,
                trainingType:source, references:references
            })
        ) : await chatbotApi.trainChatbotQA(agency.id, {
            chatbotId:chatbot.id, contents:contents, messageId:correggiDomanda?.messageId,
            name:name, trainingType:source, references:references
        });
        if(!result.ok) return setAltro({...altro, errore:result.data.message, loadingAllena:false})
        setTimeout(() => {
            const appo = {...chatbot}
            appo.trainings.push(result.data)
            aggChatbot(appo)
            if(correggiDomanda) {
                return setTimeout(() => {
                    setAltro({...altro, loadingAllena:false, errore:null, openAlert:0})
                    goToStatistiche()
                }, 200);
            }
            setSituazione(0)
            setAltro({...altro, loadingAllena:false, errore:null, openAlert:1})
        }, 500);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }

    return (
        <Box sx={{width:'100%', backgroundColor:'transparent', borderRadius:'20px', 
            margin:0, overflow:'hidden', border:'none', display: visible ? 'flex' : 'none',
            flexDirection:'column', alignItems:'center'
        }}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'title.main',
                fontSize:'18px', fontWeight:'600', lineHeight:'normal', margin:'20px 0 -18px 4px',
                display:{xs:'block', sm:'none'}, width:'100%'
            }}>Allenamento</Typography>
            <AllenamentoChatbot
                isModifica={true}
                isAvvia={false}
                isEditor={isEditor}
                altro={altro}
                cambiaAltro={setAltro}
                situazione={situazione}
                cambiaSituazione={setSituazione}
                chatbotId={chatbot.id}
                trainings={chatbot.trainings}
                allenaChatbot={allenaChatbot}
                completaChatbot={() => null}
                correggiDomanda={correggiDomanda}
                modificaAllenamento={modificaAllenamento}
                eliminaAllenamento={(training) => setElimina({visible:true, training})}
                charsLimitXBot={subscription?.metrics.charsLimitXBot}
                apriCosaAggiungere={() => setAltro({...altro, visible:1})}
                premiUpgrade={premiUpgrade}
            ></AllenamentoChatbot>

            <ModalCosaAggiungere
                visible={altro.visible === 1}
                chiudi={() => setAltro({...altro, visible:0})}
            ></ModalCosaAggiungere>

            <ModalEliminaAllenamento
                chatbot={chatbot}
                visible={elimina.visible}
                training={elimina.training}
                chiudi={() => setElimina({visible:false, training:null})}
                removeTraining={(idTraining) => {
                    let appo = {...chatbot};
                    appo.trainings = chatbot.trainings.filter((t) => t.id != idTraining);
                    aggChatbot({...appo})
                    setElimina({visible:false, training:null})
                    setAltro({...altro, openAlert:2})
                }}
            ></ModalEliminaAllenamento>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={'error'}
                message={altro.errore}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Chatbot allenato con successo!'}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 2}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Allenamento eliminato con successo!'}
            ></AppAlert>
        </Box>
    );
}

export default ZonaAllenaChatbot;