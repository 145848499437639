import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import utils from '../../libs/utils';
import BottoneAllena from '../allenamento/BottoneAllena';

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        margin: 0,
        padding: 0,
        borderRadius: '12px',
        fontSize:'inherit',
        border:'none',
        color:'black',
        '& fieldset': {
            borderWidth:0,
        },
        '&:hover fieldset': {
            borderWidth:0,
        },
        '&.Mui-focused fieldset': {
            borderWidth:0,
            borderColor: 'primary.main'
        }
    },
    "& .MuiOutlinedInput-input": {
        padding: 0
    }
})

function RetrainQA({
    training, conferma, loading, charsLimitXBot, caratteriAttuali,
    cambiaAltro, premiUpgrade, isModifiche, isEditor
}) {

    const [domande, setDomande] = useState('')
    const [contenuto, setContenuto] = useState('')

    useEffect(() => {
        const aggDati = () => {
            setDomande(training.references[0])
            setContenuto(training.contents[0].content)
        }
        if(training) aggDati()
    }, [training])

    const contaTotaleCaratteri = () => {
        let cont = 0
        cont += domande.length
        cont += contenuto.length
        return cont
    }

    const isFuoriCaratteri = () => {
        if(charsLimitXBot === -1) return false;
        let cont = contaTotaleCaratteri()
        if(cont + caratteriAttuali >= charsLimitXBot) return true
        return false
    }

    const clickConferma = async() => {
        if(isFuoriCaratteri()) return cambiaAltro({errore:'Limite di caratteri superato.'})
        if(!contenuto.length) return cambiaAltro({errore:'Il contenuto della risposta non può essere vuoto.'})
        let questions = domande.trim().replace(/\s\s+/g, ' ').replace('\n', ' ');
        if(!questions.length) return cambiaAltro({errore:'Necessario inserire almeno una domanda.'})
        const nome = questions.split('?')[0] + (questions.includes('?') ? '?' : '');
        await conferma([{content:contenuto, cont:contenuto.length}], nome, 'domande', [questions], training.messageId);
    }

    return (
        <Box sx={{width:'100%', padding:{xs:'6px 0', sm:'18px 0 0'}, display:'flex', 
            flexDirection:'column', alignItems:'center', justifyContent:'center'
        }}>
            <Box sx={{margin:{xs:'8px 8px 0', sm:'0 25px 0'}, width:{xs:'calc(100% - 16px)', sm:'calc(100% - 50px)'}}}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969', marginBottom:{xs:'2px', sm:0},
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.1', sm:'normal'}, 
                }}>
                    Inserisci la domanda e le <b>sue possibili varianti</b> per includere diverse <b>parole chiave.</b><br className='newline-no-mobile'/>
                    ES) Che servizi offrite? Cosa potete sviluppare? Che prodotti potete creare?
                </Typography>
            </Box>
            <Box sx={{margin:{xs:'4px 8px 24px', sm:'8px 25px 25px'}, width:{xs:'calc(100% - 28px)', sm:'calc(100% - 98px)'}, height:'fit-content', 
                overflow:'auto', borderRadius:'12px', border:'1px solid #DCDCDC', padding:{xs:'4px 6px', sm:'24px'}, fontSize:{xs:'12px', sm:'15px'}
            }}>
                <StyledTextField
                    id={"question" + new Date().toLocaleTimeString()}
                    variant="outlined"
                    placeholder={"Domande"}
                    InputProps={{style:{color:"#737373"}, autoComplete:"off", autoCorrect: "off"}}
                    value={domande}
                    onChange={(e) => {setDomande(e.target.value); cambiaAltro({modifiche:true})}}
                    sx={{
                        width:'100%', height: 'auto', 
                        display:'flex', justifyContent:'center'
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                    autoComplete={undefined}
                    multiline={true}
                    minRows={2}
                />
            </Box>

            <Box sx={{margin:{xs:'4px 8px 0', sm:'8px 25px 0'}, width:{xs:'calc(100% - 16px)', sm:'calc(100% - 50px)'}}}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969', marginBottom:{xs:'2px', sm:0},
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                }}>Inserisci il contenuto della risposta. </Typography>
            </Box>
            <Box sx={{margin:{xs:'12px 8px 8px', sm:'7px 25px 25px', md:'12px 25px 25px'}, width:{xs:'calc(100% - 28px)', sm:'calc(100% - 98px)'}, height:'500px', 
                overflow:'auto', borderRadius:'12px', border:'1px solid #DCDCDC', padding:{xs:'6px 6px', sm:'24px'}, fontSize:{xs:'12px', sm:'15px'}
            }}>
                <StyledTextField
                    id={"content" + new Date().toLocaleTimeString()}
                    variant="outlined"
                    placeholder={"Contenuto"}
                    InputProps={{style:{color:"#737373"}, autoComplete:"off", autoCorrect: "off"}}
                    value={contenuto}
                    onChange={(e) => {setContenuto(e.target.value); cambiaAltro({modifiche:true})}}
                    sx={{
                        width:'100%', height: 'auto', 
                        display:'flex', justifyContent:'center'
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                    autoComplete={undefined}
                    multiline={true}
                    minRows={5}
                />
            </Box>
            {isFuoriCaratteri() &&
                <Box sx={{marginBottom:{xs:'24px', sm:'40px'}, marginTop:{xs:'20px', sm:'10px'}, display:'flex', 
                    flexDirection:'column', alignItems:'center', width:{xs:'80%', sm:'100%'}
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                    }}>
                        Il numero massimo di caratteri consentiti per Chatbot è di {utils.formatNumber(charsLimitXBot)}.
                    </Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                    }}>
                        Confermando l'allenamento il totale di caratteri arriverà a&nbsp;
                        <span style={{color:'#FC2A1B', fontWeight:'bold'}}>{utils.formatNumber(contenuto.length + caratteriAttuali)}</span>.
                    </Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                    }}>
                        Esegui&nbsp;
                        <Typography component='span' sx={{display:'inline-block', fontSize:{xs:'13px', sm:'16px'}, cursor:'pointer',
                            color:'primary.main', fontWeight:'700', textDecoration:'underline'
                        }} onClick={premiUpgrade}>l'upgrade</Typography>
                        &nbsp;per aumentare il limite di caratteri.
                    </Typography>
                </Box>
            }

            {isEditor && <Box sx={{position:'absolute', top:{xs:'-55px', sm:'-68px', md:'75px'},
                right:{xs:'auto', md:0}, left:{xs:0, md:'auto'}
            }}>
                <BottoneAllena
                    isConferma={true}
                    loading={loading}
                    disabled={!isModifiche}
                    onClick={async() => clickConferma()}
                    text={loading ? 'Magia AI in corso' : 'Allena Chatbot'}
                ></BottoneAllena>
            </Box>}
        </Box>
    );
}

export default RetrainQA;