import { API_URL, JWT_TOKEN } from "../config";
import storage from '../libs/storage';
import client from "./client";

const endpoint = API_URL + '/users/operator/'

const listVisitors = async(agencyId) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.get(endpoint, {}, {headers:{
        Authorization:'Bearer ' + token
    }})
}

const createVisitor = async(agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint, data, {headers:{
        Authorization:'Bearer ' + token
    }})
}

const updateVisitor = async(agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.put(endpoint, data, {headers:{
        Authorization:'Bearer ' + token
    }})
}

const deleteVisitor = async(agencyId, visitorId) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.delete(endpoint + visitorId, {}, {headers:{
        Authorization:'Bearer ' + token
    }})
}

export default {
    listVisitors,
    createVisitor,
    updateVisitor,
    deleteVisitor
}