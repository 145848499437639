import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BottoneLogin from './BottoneLogin';
import AppTextField from '../generale/AppTextField';

function FormLogin({conferma, errore, loading}) {

    const isErrore = Boolean(errore)

    const schema = yup.object().shape({
        email: yup.string().lowercase().required('La mail è obbligatoria.').email('La mail deve essere una email valida.'),
        password: yup.string().required('La password è obbligatoria.').min(8, 'La password deve avere almeno 8 caratteri.')
    });
    const {register, handleSubmit, formState: { errors }} = useForm({resolver: yupResolver(schema)});

    return (
        <Box
            component='form'
            id="login-form"
            name="login-form"
            data-name="Login Form"
            onSubmit={handleSubmit(conferma)}
            sx={{width:{xs:'100%', sm:'fit-content'}}}
        >
            <Stack direction='column' spacing='24px' sx={{width:{xs:'100%', sm:'fit-content'}}}>
                <AppTextField
                    isBianco={true}
                    name={'Email'}
                    isLabel={true}
                    error={errors.email?.message}
                    placeholder={'Inserisci la tua email'}
                    register={register("email", { required: false })}
                ></AppTextField>
                <AppTextField
                    isBianco={true}
                    name={'Password'}
                    isLabel={true}
                    isPassword={true}
                    error={errors.password?.message}
                    placeholder={'Inserisci la tua password'}
                    register={register("password", { required: false })}
                ></AppTextField>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    {isErrore &&
                        <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, marginBottom:{xs:'4px', sm:'8px'}, 
                            fontWeight:'600', lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center', color:'#FC2A1B'
                        }}>{errore}</Typography>
                    }
                    <BottoneLogin
                        text={'Accedi'}
                        loading={loading}
                        onClick={() => null}
                    ></BottoneLogin>
                </Box>
            </Stack>
        </Box>
    );
}

export default FormLogin;