import { API_URL } from "../config";
import client from "./client";

const endpoint = API_URL + '/widgets/agency/';

const getAgency = async(domain) => {
    return client.get(endpoint + domain);
}

export default {
    getAgency
}