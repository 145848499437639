import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import useAuth from '../controllers/auth/useAuth';
import ZonaChat from '../components/chat/ZonaChat';
import AppAlert from '../components/generale/AppAlert';
import useChatbot from '../controllers/chatbots/useChatbot';
import MenuChatbot from '../components/chatbots/MenuChatbot';
import ZonaGrafica from '../components/impostazioni/ZonaGrafica';
import ZonaCondividi from '../components/condividi/ZonaCondividi';
import ZonaIntegrazioni from '../components/condividi/ZonaIntegrazioni';
import useAbbonamento from '../controllers/abbonamento/useAbbonamento';
import ZonaStatistiche from '../components/statistiche/ZonaStatistiche';
import ZonaAllenaChatbot from '../components/allenamento/ZonaAllenaChatbot';
import ZonaImpostazioni from '../components/impostazioni/ZonaImpostazioni';
import ModalEliminaChatbot from '../components/chatbots/ModalEliminaChatbot';
import LoadingPaginaChatbot from '../components/chatbots/LoadingPaginaChatbot';
import IntestazioneChatbot from '../components/intestazione/IntestazioneChatbot';

function GestisciChatbot(props) {

    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const {isAdmin, isEditor} = useAuth()
    const {subscription} = useAbbonamento()
    const {chatbots, chatbotsLoading, updateChatbot, removeChatbot} = useChatbot()

    const [chatbot, setChatbot] = useState(null)
    const [visible, setVisible] = useState(false)
    const [isDomain, setIsDomain] = useState(false)
    const [situazione, setSituazione] = useState(1)
    const [menuVisible, setMenuVisible] = useState({open:false, anchorVariabili:null})
    const [altro, setAltro] = useState({errore:null, openAlert:0, correggiDomanda:null, condividi:null})

    useEffect(() => {
        const aggSituazione = () => {
            if(state?.situazione) setSituazione(state.situazione);
            if(state?.isRetrain) {
                setAltro({...altro, openAlert:1});
                setTimeout(() => {
                    setAltro({...altro, openAlert:0});
                }, 3000);
            }
        }
        aggSituazione()
    }, [state, chatbot])

    useEffect(() => {
        const aggState = () => {
            if(!state?.chatbot) return navigate('/')
            const appo = chatbots.filter((el) => el.id == state.chatbot.id)[0]
            if(appo) setChatbot(appo)
            if(!appo && !chatbotsLoading) return navigate('/')
        }
        aggState()
    }, [state, chatbots, chatbotsLoading])

    const aggChatbot = (value) => {
        setChatbot(value)
        updateChatbot(value)
        navigate(pathname, {replace:true, state:{chatbot:value, situazione:situazione}})
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }

    if(chatbotsLoading) return (
        <LoadingPaginaChatbot />
    );
    
    if(chatbot) return (
        <Box sx={{
            position:'relative', 
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'16px 12px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <IntestazioneChatbot
                name={chatbot.name}
                colore={chatbot.buttonColor}
                icona={chatbot.buttonPhoto}
                goBack={() => navigate('/')}
                disabled={!chatbot.enabled}
                premiMenu={(event)  => setMenuVisible({open:true, anchorVariabili:event.currentTarget})}
            ></IntestazioneChatbot>

            <MenuChatbot
                situazione={situazione}
                cambiaSituazione={(v) => {
                    setSituazione(v)
                    navigate(pathname, {replace:true, state:{
                        chatbot:chatbot, situazione:v
                    }})
                }}
                open={menuVisible.open}
                anchorEl={menuVisible.anchorVariabili}
                onClose={() => setMenuVisible({open:false, anchorVariabili:null})}
            ></MenuChatbot>

            <ZonaImpostazioni
                isEditor={isEditor}
                chatbot={chatbot}
                isDomain={isDomain}
                isAdmin={isAdmin}
                aggChatbot={aggChatbot}
                visible={situazione === 1}
                premiElimina={() => setVisible(true)}
                togliDomain={() => setIsDomain(false)}
            ></ZonaImpostazioni>
            
            <ZonaGrafica
                isEditor={isEditor}
                chatbot={chatbot}
                aggChatbot={aggChatbot}
                visible={situazione === 2}
            ></ZonaGrafica>

            <ZonaAllenaChatbot
                isEditor={isEditor}
                chatbot={chatbot}
                subscription={subscription}
                isPaymentFailed={false}
                aggChatbot={aggChatbot}
                visible={situazione === 3}
                isDisabled={!chatbot.enabled}
                correggiDomanda={altro.correggiDomanda}
                premiUpgrade={() => navigate('/settings', {state:{situazione:3}})}
                modificaAllenamento={(training) => {
                    if(!chatbot.enabled) return setAltro({...altro, errore:'Questo bot è stato disabilitato perchè non incluso nel tuo abbonamento.'})
                    navigate('/retrain-chatbot', {state:{training, chatbot}})
                }}
                goToStatistiche={() => {
                    setAltro({...altro, correggiDomanda:{...altro.correggiDomanda, ok:true}})
                    setTimeout(() => {
                        setSituazione(5)
                        navigate(pathname, {replace:true, state:{
                            chatbot:chatbot, situazione:5
                        }})
                    }, 100);
                }}
            ></ZonaAllenaChatbot>
            
            <ZonaChat
                chatbot={chatbot}
                visible={situazione === 4}
            ></ZonaChat>

            <ZonaStatistiche
                chatbot={chatbot}
                visible={situazione === 5}
                correggiDomanda={altro.correggiDomanda}
                premiCorreggiDomanda={(questionData) => {
                    setAltro({...altro, correggiDomanda:questionData})
                    setSituazione(3)
                }}
                pulisciDomanda={() => setAltro({...altro, correggiDomanda:null})}
            ></ZonaStatistiche>

            <ZonaCondividi
                code={chatbot.code}
                visible={situazione === 6}
                status={altro.condividi}
                isDomain={Boolean(chatbot.domain?.length > 0)}
                premiDominio={() => {
                    setIsDomain(true)
                    setSituazione(1)
                }}
                clearStatus={() => setAltro({...altro, condividi:null})}
            ></ZonaCondividi>

            <ZonaIntegrazioni
                isAdmin={isAdmin}
                isEditor={isEditor}
                chatbot={chatbot}
                aggChatbot={aggChatbot}
                visible={situazione === 7}
                goToCondividi={(status) => {
                    setAltro({...altro, condividi:status})
                    setSituazione(6)
                }}
            ></ZonaIntegrazioni>

            <ModalEliminaChatbot
                chatbot={chatbot}
                visible={visible}
                cambiaVisible={setVisible}
                removeChatbot={removeChatbot}
                navigaHome={() => navigate('/', {state:{chatbotEliminato:true}})}
            ></ModalEliminaChatbot>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                message={altro.errore}
                severity={'error'}
            ></AppAlert>
            <AppAlert
                open={Boolean(altro.openAlert)}
                chiudiAlert={chiudiAlert}
                message={"Allenamento modificato con successo!"}
                severity={'success'}
            ></AppAlert>
        </Box>
    );
}

export default GestisciChatbot;