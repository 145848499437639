import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import utils from '../../libs/utils';
import BottoneAllena from './BottoneAllena'
import AllenamentoQA from './AllenamentoQA'
import AllenamentoSito from './AllenamentoSito'
import AllenamentoTestuale from './AllenamentoTestuale'
import AllenamentoDocumenti from './AllenamentoDocumenti'
import ElencoAllenamenti from '../allenamento/ElencoAllenamenti'

import UndoIcon from '@mui/icons-material/Undo';
import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

function AllenamentoChatbot({
    isAvvia, situazione, cambiaSituazione, trainings, charsLimitXBot, allenaChatbot, chatbotId, correggiDomanda, 
    completaChatbot, eliminaAllenamento, altro, cambiaAltro, apriCosaAggiungere, premiUpgrade, isModifica, isEditor, modificaAllenamento
}) {

    const [configSito, setConfigSito] = useState({visible:false, isSitemap:false, isScraping:false});

    useEffect(() => {
        const aggConfigSito = () => setConfigSito({...configSito, isSitemap:false, isScraping:false})
        if(situazione == 0) aggConfigSito()
    }, [situazione])

    const contaCaratteriRimanenti = useCallback(() => {
        if(charsLimitXBot === -1) return <Typography component='span' sx={{
            fontSize:{xs:'22px', sm:'28px'}, color:'primary.main', fontWeight:'600', display:'inline-block'
        }}>∞</Typography>;
        let cont = 0;
        for(let i = 0; i < trainings.length; i++) cont += trainings[i].characters
        return utils.formatNumber(charsLimitXBot - cont)
    }, [trainings, charsLimitXBot])

    const contaCaratteriAttuali = useCallback(() => {
        let cont = 0;
        for(let i = 0; i < trainings.length; i++) cont += trainings[i].characters
        return cont
    }, [trainings])

    if(situazione === 0) return (
        <React.Fragment>
            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:'20px 0 12px', sm:'40px 0 0', lg:'40px 40px 0'}, overflow:'hidden', 
                border:'1px solid #dcdcdc', boxSizing:'border-box', display:isEditor ? 'block' : 'none'
            }}>
                <Box sx={{width:'100%', padding:{xs:'24px 0', sm:'40px 0'}, display:'flex', flexDirection:'column',
                    alignItems:'center', justifyContent:'center', position:'relative'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:'22px', fontWeight:'700', lineHeight:'normal'
                    }}>SORGENTE DEI DATI</Typography>
                    <Grid container direction='row' sx={{
                        maxWidth:{xs:'100%', sm:'80%', md:'550px'}, alignItems:'center', justifyContent:'center',
                        margin:{xs:'24px 0', sm:'32px 0 18px'}, display: configSito.visible ? 'none': 'flex'
                    }}>
                        <Grid item xs={12} sm={6} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'SITO WEB'}
                                Icon={LanguageIcon}
                                onClick={() => setConfigSito({...configSito, visible:true})}
                            ></BottoneAllena>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'DOCUMENTI'}
                                Icon={ArticleIcon}
                                onClick={() => cambiaSituazione(1)}
                            ></BottoneAllena>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'TESTO'}
                                Icon={ModeEditIcon}
                                onClick={() => cambiaSituazione(2)}
                            ></BottoneAllena>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'Q / A'}
                                Icon={QuestionAnswerIcon}
                                onClick={() => cambiaSituazione(4)}
                            ></BottoneAllena>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' sx={{
                        maxWidth:{xs:'100%', sm:'100%', md:'700px'}, alignItems:'center', justifyContent:'center', 
                        margin:{xs:'24px 0', sm:'32px 0 18px'}, display: !configSito.visible ? 'none': 'flex'
                    }}>
                        <Grid item xs={12} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'Scraping automatico'}
                                Icon={LanguageIcon}
                                width={'300px'}
                                onClick={() => {
                                    cambiaSituazione(3)
                                    setConfigSito({visible:true, isSitemap:false, isScraping:true})
                                }}
                            ></BottoneAllena>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'Pagine web specifiche'}
                                Icon={LanguageIcon}
                                width={'300px'}
                                onClick={() => {
                                    cambiaSituazione(3)
                                    setConfigSito({visible:true, isSitemap:false, isScraping:false})
                                }}
                            ></BottoneAllena>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'Sitemap del sito'}
                                width={'300px'}
                                Icon={LanguageIcon}
                                onClick={() => {
                                    cambiaSituazione(3)
                                    setConfigSito({visible:true, isSitemap:true, isScraping:false})
                                }}
                            ></BottoneAllena>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{
                            display:'flex', justifyContent:'center', marginBottom:{xs:'12px', sm:'24px'}
                        }}>
                            <BottoneAllena
                                text={'INDIETRO'}
                                width={'300px'}
                                Icon={UndoIcon}
                                onClick={() => setConfigSito({visible:false, isSitemap:false, isScraping:false})}
                            ></BottoneAllena>
                        </Grid>
                    </Grid>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#626262', cursor:'pointer',
                        fontSize:{xs:'15px', sm:'20px'}, fontWeight:'400', lineHeight:'normal', textDecoration:'underline'
                    }} onClick={apriCosaAggiungere}>Cosa posso aggiungere?</Typography>
                    {charsLimitXBot !== -1 && <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black', padding:{xs:'0 12px', sm:0},
                        fontSize:{xs:'15px', sm:'20px'}, fontWeight:'400', lineHeight:'normal', marginTop:{xs:'24px', sm:'24px'}
                    }}>
                        <b>{contaCaratteriRimanenti()}</b> caratteri rimanenti per questo Chatbot
                    </Typography>}
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, 
                margin:{xs:'20px 0 12px', sm:'60px 0 -10px', lg:'60px 40px -10px'}, 
                boxSizing:'border-box', display:!isEditor ? 'block' : 'none'
            }}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'primary.main',
                    fontSize:'22px', fontWeight:'700', lineHeight:'normal'
                }}>ELENCO ALLENAMENTI</Typography>
            </Box>

            <ElencoAllenamenti
                isAvvia={isAvvia}
                isEditor={isEditor}
                isModifica={isModifica}
                trainings={trainings}
                loading={altro.loading}
                completaChatbot={completaChatbot}
                eliminaAllenamento={eliminaAllenamento}
                modificaAllenamento={modificaAllenamento}
            ></ElencoAllenamenti>
        </React.Fragment>
    );

    return (
        <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', 
            margin:{xs:'24px 0', sm:'40px 0 0', lg:'40px 40px 0'}, overflow:'hidden', 
            borderRadius:'20px', border:'1px solid #dcdcdc', boxSizing:'border-box'
        }}>
            {situazione == 1 &&
                <AllenamentoDocumenti
                    conferma={allenaChatbot}
                    loading={altro.loading}
                    situazione={situazione}
                    loadingAllena={altro.loadingAllena}
                    goBack={() => cambiaSituazione(0)}
                    premiUpgrade={premiUpgrade}
                    cambiaAltro={(value) => cambiaAltro({...altro, ...value})}
                    charsLimitXBot={charsLimitXBot}
                    caratteriAttuali={contaCaratteriAttuali()}
                ></AllenamentoDocumenti>
            }
            {situazione == 2 &&
                <AllenamentoTestuale
                    conferma={allenaChatbot}
                    situazione={situazione}
                    loadingAllena={altro.loadingAllena}
                    goBack={() => cambiaSituazione(0)}
                    premiUpgrade={premiUpgrade}
                    cambiaAltro={(value) => cambiaAltro({...altro, ...value})}
                    charsLimitXBot={charsLimitXBot}
                    caratteriAttuali={contaCaratteriAttuali()}
                ></AllenamentoTestuale>
            }
            {situazione == 3 &&
                <AllenamentoSito
                    conferma={allenaChatbot}
                    loading={altro.loading}
                    situazione={situazione}
                    loadingAllena={altro.loadingAllena}
                    goBack={() => cambiaSituazione(0)}
                    premiUpgrade={premiUpgrade}
                    cambiaAltro={(value) => cambiaAltro({...altro, ...value})}
                    charsLimitXBot={charsLimitXBot}
                    caratteriAttuali={contaCaratteriAttuali()}
                    isSitemap={configSito.isSitemap}
                    isScraping={configSito.isScraping}
                    chatbotId={chatbotId}
                ></AllenamentoSito>
            }
            {situazione == 4 &&
                <AllenamentoQA
                    conferma={allenaChatbot}
                    loading={altro.loading}
                    situazione={situazione}
                    correggiDomanda={correggiDomanda}
                    loadingAllena={altro.loadingAllena}
                    goBack={() => cambiaSituazione(0)}
                    premiUpgrade={premiUpgrade}
                    cambiaAltro={(value) => cambiaAltro({...altro, ...value})}
                    charsLimitXBot={charsLimitXBot}
                    caratteriAttuali={contaCaratteriAttuali()}
                ></AllenamentoQA>
            }
        </Box>
    );
}

export default AllenamentoChatbot;