import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ModeIcon from '@mui/icons-material/Mode';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import utils from '../../libs/utils';

function Messaggio({
    contenuto, isUser, coloreSfondo, coloreTesto, displayName, isOperator, isReader, isDouble, 
    operatorStatus, isDark, isRevised, buttonColor, isCorreggiDomanda, premiCorreggiDomanda
}) {

    const { t } = useTranslation()
    const isNomeChat = Boolean(displayName?.length)
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        async function conversion() {
            if(!contenuto?.length) return;
            const result = await utils.convertMarkdown(contenuto);
            setHtmlContent(result);
        }
        if(!isUser) conversion();
    }, [contenuto, isUser])

    if(isOperator) return (
        <Box sx={{width:1, py:1, display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Box sx={{
                padding:{xs:'5px 16px', sm:'5px 16px'}, backgroundColor:isDark ? 'black' : 'white', borderRadius:'8px',
                marginBottom:{xs:'12px', sm:'12px'}, maxWidth:{xs:'80%', sm:'600px', md:'800px', lg:'850px'},
                overflowX:'hidden', border:'1px dashed', borderColor:buttonColor
            }}>
                <Typography className='chat-message-design' component="span" sx={{
                    textAlign:'left', letterSpacing:'-0.2', color:!isDark ? 'black' : 'white',
                    fontSize:{xs:'15px', sm:'15px', md:'15px'}, fontWeight:'400', lineHeight:'normal'
                }}>
                    {operatorStatus ? 'Un operatore è entrato in chat' : 'L\'operatore è uscito dalla chat'}
                </Typography>
            </Box>
        </Box>
    );

    if(isUser) return (
        <React.Fragment>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                <Box sx={{padding:{xs:'7px 12px', sm:'11px 12px'}, backgroundColor:coloreSfondo, borderRadius:'8px',
                    marginBottom:{xs:'12px', sm:'12px'}, maxWidth:{xs:'80%', sm:'600px', md:'800px', lg:'850px'}, overflowX:'hidden'
                }}>
                    <Typography className='chat-message-design' sx={{
                        textAlign:'left', letterSpacing:'-0.2', color:coloreTesto, whiteSpace:'pre-line',
                        fontSize:{xs:'15px', sm:'18px', md:'15px'}, fontWeight:'400', lineHeight:'normal', fontFamily:'Inter'
                    }} dangerouslySetInnerHTML={{__html:contenuto}}></Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
    
    return (
        <React.Fragment>
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                {isNomeChat && !isDouble && <Typography sx={{margin:{xs:'0 0 1px 3px', sm:'0 0 2px 3px'},
                    textAlign:'left', letterSpacing:'-0.2', color:isDark ? 'white' : 'black',
                    fontSize:{xs:'11px', sm:'14px', md:'12px'}, fontWeight:'500', lineHeight:'normal', fontFamily:'Inter'
                }}>{displayName}</Typography>}
                {isCorreggiDomanda && !isRevised && !isReader &&
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center',
                        margin:{xs:'0 0 1px 12px', sm:'0 0 3px 24px'}, cursor:'pointer',
                    }} onClick={premiCorreggiDomanda}>
                        <ModeIcon sx={{width:'16px', height:'auto', color:'rosso.main'}} />
                        <Typography sx={{
                            textAlign:'right', letterSpacing:'-0.2', color:'rosso.main', textDecorationLine:'underline',
                            fontSize:{xs:'11px', sm:'12px'}, fontWeight:'500', lineHeight:'normal', fontFamily:'Inter'
                        }}>{t('chat.correggi1')}</Typography>
                    </Box>
                }
                {isCorreggiDomanda && isRevised &&
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center',
                        margin:{xs:'0 0 1px 12px', sm:'0 0 3px 24px'}
                    }}>
                        <CheckCircleOutlineOutlinedIcon sx={{width:'16px', height:'auto', color:'title.main'}} />
                        <Typography sx={{marginLeft:'2px',
                            textAlign:'right', letterSpacing:'-0.2', color:'title.main',
                            fontSize:{xs:'11px', sm:'12px'}, fontWeight:'500', lineHeight:'normal', fontFamily:'Inter'
                        }}>{t('chat.correggi2')}</Typography>
                    </Box>
                }
            </Box>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <Box sx={{padding:{xs:'7px 12px', sm:'11px 12px'}, backgroundColor:coloreSfondo, borderRadius:'8px',
                    marginBottom:{xs:'12px', sm:'12px'}, maxWidth:{xs:'80%', sm:'600px', md:'800px', lg:'850px'},
                    overflowX:'hidden', marginTop:isDouble ? '-4px' : 0
                }}>
                    <Typography className='chat-message-design' component="div" sx={{
                        textAlign:'left', letterSpacing:'-0.2', color:coloreTesto,
                        fontSize:{xs:'15px', sm:'15px', md:'15px'}, fontWeight:'400', lineHeight:{xs:'1.4', sm:'1.5', md:'1.4'}, 
                        fontFamily:'Inter', '& a': {textDecorationLine:'underline !important', fontWeight:'600',
                            color:buttonColor === coloreSfondo ? coloreTesto + ' !important' : buttonColor + ' !important', 
                        }
                    }} dangerouslySetInnerHTML={{__html:htmlContent}}></Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Messaggio;