import React from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

function AppRadio({text, value, onChange, error}) {

    return (
        <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
                <Radio
                    color='primary'
                    checked={value}
                    onChange={onChange}
                    onClick={onChange}
                />
                <Typography sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', marginLeft:{xs:0, sm:'4px'},
                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'black'
                }}>{text}</Typography>
            </Box>
            {Boolean(error) &&
                <Typography sx={{fontSize:{xs:'12px', sm:'14px'}, marginBottom:{xs:'4px', sm:'8px'}, fontWeight:'400',
                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', color:'#FC2A1B'
                }}>{error}</Typography>
            }
        </Box>
    );
}

export default AppRadio;