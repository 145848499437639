import React, {useEffect, useState, useCallback} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import useAuth from '../controllers/auth/useAuth';
import AppAlert from '../components/generale/AppAlert';
import useChatbot from '../controllers/chatbots/useChatbot';
import ListaChatbots from '../components/chatbots/ListaChatbots';
import useAbbonamento from '../controllers/abbonamento/useAbbonamento';
import IntestazioneDashboard from '../components/intestazione/IntestazioneDashboard';

function Dashboard(props) {

    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const {user, isAdmin} = useAuth()
    const {subscription} = useAbbonamento()
    const {chatbots, chatbotsLoading} = useChatbot()

    const CHATBOTS_LIMIT = subscription?.metrics.botsLimit

    const [altro, setAltro] = useState({
        errore:null, loading:false, openAlert:0
    })

    useEffect(() => {
        const aggState = () => {
            if(state?.chatbotCreato) {
                setAltro({...altro, openAlert:1})
                navigate(pathname, {replace:true})
            }
            if(state?.chatbotEliminato) {
                setAltro({...altro, openAlert:2})
                navigate(pathname, {replace:true})
            }
        }
        aggState()
    }, [state])

    const filtraChatbots = useCallback(() => {
        const enabled = user?.chatbots || [];
        if(enabled.includes('*')) return chatbots;
        return chatbots.filter((bot) => {
            let trovato = enabled.find((el) => el === bot.id);
            if(trovato) return true;
            return false;
        });
    }, [chatbots, user])

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }
    
    return (
        <Box sx={{
            width:{xs:'calc(100% - 24px)', sm:'calc(100% - 80px)', lg:'calc(100% - 160px)', xl:'calc(100% - 300px)'}, 
            padding:{xs:'16px 12px', sm:'30px 40px 30px', lg:'30px 80px 40px', xl:'40px 150px 50px'}
        }}>
            <IntestazioneDashboard />

            <ListaChatbots
                loading={chatbotsLoading}
                chatbots={filtraChatbots()}
                limit={CHATBOTS_LIMIT}
                isAdmin={isAdmin}
                premiNuovo={() => {
                    if(CHATBOTS_LIMIT === -1) return navigate('/create-chatbot', {state:{isCreaChatbot:true}})
                    if(chatbots.length >= CHATBOTS_LIMIT) return setAltro({...altro, errore:'Limite di Chatbots già raggiunto.'})
                    else navigate('/create-chatbot', {state:{isCreaChatbot:true}})
                }}
                premiChatbot={(bot) => navigate('/chatbot', {state:{chatbot:bot}})}
                isMargineSopra={false}
            ></ListaChatbots>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                message={altro.errore}
                severity={'error'}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                message={'Chatbot creato con successo!'}
                severity={'success'}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 2}
                chiudiAlert={chiudiAlert}
                message={'Chatbot eliminato con successo!'}
                severity={'success'}
            ></AppAlert>
        </Box>
    );
}

export default Dashboard;