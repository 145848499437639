import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import utils from '../../libs/utils';
import BottoneAllena from '../allenamento/BottoneAllena';

import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        margin: 0,
        padding: 0,
        borderRadius: '12px',
        fontSize:'inherit',
        border:'none',
        color:'black',
        '& fieldset': {
            borderWidth:0,
        },
        '&:hover fieldset': {
            borderWidth:0,
        },
        '&.Mui-focused fieldset': {
            borderWidth:0,
            borderColor: 'primary.main'
        }
    },
    "& .MuiOutlinedInput-input": {
        padding: 0
    }
})

const MAX_NUM = 500000

function RetrainSources({
    training, conferma, loading, charsLimitXBot, caratteriAttuali,
    cambiaAltro, premiUpgrade, isModifiche, isEditor
}) {

    const textFieldRef = useRef(null);
    const contentRef = useRef(null);

    const [contents, setContents] = useState([])
    const [modificati, setModificati] = useState([])
    const [selezionato, setSelezionato] = useState(0)

    useEffect(() => {
        const aggContents = () => {
            setContents(training.contents)
            setModificati(new Array(training.contents.length).fill(false))
        }
        if(training) aggContents()
    }, [training])

    useEffect(() => {
        if(textFieldRef.current) {
            textFieldRef.current.focus();
            textFieldRef.current.setSelectionRange(0, 0);
        }
        if(contentRef.current) {
            contentRef.current.scrollTop = 0;
        }
    }, [selezionato])

    const scriviTipo = () => {
        if(training.trainingType == 'sitoweb') return 'Link Sito Web'
        if(training.trainingType == 'documents') return 'Documenti'
        if(training.trainingType == 'excel') return 'Documenti'
        if(training.trainingType == 'word') return 'Documenti'
        if(training.trainingType == 'pdf') return 'Documenti'
        if(training.trainingType == 'domande') return 'Q/A'
        return 'TESTO'
    }

    const cambiaContenuto = (value) => {
        const appo = [...contents]
        appo[selezionato].content = value
        setContents(appo)
        const appo2 = [...modificati]
        appo2[selezionato] = true
        setModificati(appo2)
    }

    const rimuoviContenuto = (index) => {
        if(contents.length == 1) return cambiaAltro({errore:'Obbligatorio lasciare almeno un contenuto.'})
        const appo = [...contents];
        appo.splice(index, 1);
        setContents(appo);
        cambiaAltro({modifiche:true});
        if(selezionato === index) setSelezionato(0)
    }

    const premiLink = (link) => {
        window.open(link, '_blank');
    }

    const isFuoriCaratteri = () => {
        if(charsLimitXBot === -1) return false;
        let cont = contaTotaleCaratteri()
        if(cont + caratteriAttuali >= charsLimitXBot) return true
        return false
    }

    const contaTotaleCaratteri = () => {
        let cont = 0
        for(let i = 0; i < contents.length; i++) cont += contents[i].content?.length
        return cont
    }

    const clickConferma = async() => {
        if(isFuoriCaratteri()) return cambiaAltro({errore:'Limite di caratteri superato.'})
        let references = []
        for(let i = 0; i < contents.length; i++) {
            contents[i].cont = contents[i].content.length;
            if(contents[i].link) references.push(contents[i].link)
            if(contents[i].fileName) references.push(contents[i].fileName)
        }
        await conferma(contents, references[0], training.trainingType, references)
    }
    
    if(contents.length > 0) return (<>
        <Box sx={{width:{xs:'100%', lg:'100%'}, borderRadius:'20px', boxSizing:'border-box',
            margin:{xs:'68px 0', sm:'83px 0 0', md:'16px 0 0', lg:'16px 0 0'}, display:'flex', flexDirection:{xs:'column', md:'row'},
            overflow:{xs:'visible', md:'hidden'}, position:{xs:'relative', md:'unset'}
        }}>
            <Box sx={{width:{xs:'100%', md:'35%'}, backgroundColor:'white', border:'1px solid #dcdcdc', 
                borderRadius:'20px', boxSizing:'border-box', overflow:'hidden', height:'fit-content'
            }}>
                <Box sx={{padding:{xs:'16px 12px', sm:'20px'}}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'20px', sm:'24px'}, fontWeight:'600', lineHeight:'normal', zIndex:200,
                    }}>{scriviTipo()}</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969', marginBottom:{xs:'2px', sm:0},
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                    }}>
                        Nuovo totale caratteri: <b>{utils.formatNumber(contaTotaleCaratteri())}</b>
                    </Typography>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <Box sx={{width:'8px', height:'8px', borderRadius:'8px', backgroundColor:'primary.main'}}></Box>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969', marginLeft:'6px',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:{xs:'1.0', sm:'normal'}, 
                        }}>
                            = contenuto modificato
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{width:'100%', height:'1px', backgroundColor:'#dcdcdc'}} />

                {contents.map((content, index) => (
                    <Box key={index} onClick={() => setSelezionato(index)} sx={{
                        display:'flex', flexDirection:'row', alignItems:'center',
                        justifyContent:'space-between', flex:1, padding:{xs:'16px 12px', sm:'20px 10px', md:'20px 6px', lg:'20px 10px'},
                        backgroundColor:'white', cursor:'pointer',
                        border:index === selezionato ? '2px solid' : 'none', borderColor:'primary.main',
                        borderBottom:(index == (contents.length - 1) && index != selezionato) ? 'none' : 
                            (index === selezionato ? '2px solid' : '1px solid #dcdcdc'),
                        borderBottomLeftRadius:index == (contents.length - 1) ? '20px' : 0,
                        borderBottomRightRadius:index == (contents.length - 1) ? '20px' : 0,
                        borderBottomColor:index === selezionato ? 'primary.main' : '#dcdcdc'
                    }}>
                        <Box sx={{display:'flex', alignItems:'center', width:{xs:'80%', md:'75%', lg:'80%'}, maxWidth:{xs:'80%', md:'75%', lg:'80%'}}}>
                            {modificati[index] && <Box sx={{marginRight:{xs:'4px', sm:'6px'},
                                width:'8px', height:'8px', borderRadius:'8px', backgroundColor:'primary.main'
                            }}></Box>}
                            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                fontSize:{xs:'12px', sm:'14px'}, fontWeight:'500', lineHeight:'normal',
                                maxWidth:'calc(100% - 26px)', overflow:'hidden'
                            }}>
                                {content.link ? content.link : content.fileName}
                            </Typography>
                        </Box>
                        <Stack direction='row' spacing='5px' sx={{width:'fit-content'}}>
                            {content.link && <IconButton sx={{width:{xs:'24px', sm:'26px'}, height:{xs:'24px', sm:'26px'},
                                backgroundColor:'white', border:'1px solid #DCDCDC', borderRadius:'10px'
                            }} onClick={(e) => {premiLink(content.link); e.stopPropagation()}}>
                                <VisibilityIcon sx={{width:{xs:'16px', sm:'16px'}, height:'auto', color:'#808080'}} />
                            </IconButton>}
                            <IconButton sx={{width:{xs:'24px', sm:'26px'}, height:{xs:'24px', sm:'26px'},
                                backgroundColor:'white', border:'1px solid #DCDCDC', borderRadius:'10px'
                            }} onClick={(e) => {rimuoviContenuto(index); e.stopPropagation()}}>
                                <DeleteIcon sx={{width:{xs:'16px', sm:'16px'}, height:'auto', color:'#808080'}} />
                            </IconButton>
                        </Stack>
                    </Box>
                ))}
            </Box>

            <Box sx={{width:{xs:'100%', md:'calc(65% - 24px)'}, marginLeft:{xs:0, md:'24px'}, marginTop:{xs:'16px', md:0}}}>
                {isFuoriCaratteri() && <Box sx={{width:'100%'}}>
                    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', width:{xs:'100%', sm:'100%'}, backgroundColor:'white',
                        padding:{xs:'16px 36px', sm:'20px'}, borderRadius:'20px', boxSizing:'border-box',
                        border:'1px solid #dcdcdc', marginBottom:{xs:'16px', sm:0}
                    }}>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Il numero massimo di caratteri consentiti per Chatbot è di {utils.formatNumber(charsLimitXBot)}.
                        </Typography>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Confermando l'allenamento il totale di caratteri arriverà a&nbsp;
                            <span style={{color:'#FC2A1B', fontWeight:'bold'}}>{utils.formatNumber(contaTotaleCaratteri() + caratteriAttuali)}</span>.
                        </Typography>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2',
                            fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'normal', color:'#696969'
                        }}>
                            Esegui&nbsp;
                            <Typography component='span' sx={{display:'inline-block', fontSize:{xs:'13px', sm:'16px'}, cursor:'pointer',
                                color:'primary.main', fontWeight:'700', textDecoration:'underline'
                            }} onClick={premiUpgrade}>l'upgrade</Typography>
                            &nbsp;per aumentare il limite di caratteri.
                        </Typography>
                    </Box>
                </Box>}

                <Box sx={{width:'100%', backgroundColor:'white', border:'1px solid #dcdcdc', 
                    borderRadius:'20px', boxSizing:'border-box', overflow:'auto',
                    padding:{xs:'8px 6px', sm:'24px'}, fontSize:{xs:'12px', sm:'15px'}, height:'570px',
                    marginTop:isFuoriCaratteri() ? {xs:'12px', sm:'16px', md:'24px'} : 'none'
                }} ref={contentRef}>
                    {contents[selezionato]?.content?.length < MAX_NUM && <StyledTextField
                        id={"content" + new Date().toLocaleTimeString()}
                        inputRef={textFieldRef}
                        variant="outlined"
                        placeholder={"Contenuto"}
                        InputProps={{style:{color:"#737373"}, autoComplete:"off", autoCorrect: "off"}}
                        value={contents[selezionato].content}
                        onChange={(e) => {cambiaContenuto(e.target.value); cambiaAltro({modifiche:true})}}
                        sx={{
                            width:'100%', height: 'auto', 
                            display:'flex', justifyContent:'center'
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        autoComplete={undefined}
                        multiline={true}
                        minRows={5}
                    />}
                    {contents[selezionato]?.content?.length >= MAX_NUM && <Box sx={{
                        width:'100%', display:'flex', justifyContent:'center'
                    }}>
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'rosso.main',
                            fontSize:{xs:'18px', sm:'26px'}, fontWeight:'500', lineHeight:'normal',
                            padding:'30px 0', maxWidth:'70%'
                        }}>
                            La ripetizione degli allenamenti non è al momento consentita per 
                            contenuti di {utils.formatNumber(MAX_NUM)} caratteri o maggiori.
                        </Typography>
                    </Box>}
                </Box>
            </Box>

            {isEditor && <Box sx={{position:'absolute', top:{xs:'-55px', sm:'-68px', md:'75px'}, 
                right:{xs:'auto', md:0}, left:{xs:0, md:'auto'}
            }}>
                <BottoneAllena
                    isConferma={true}
                    loading={loading}
                    disabled={!isModifiche}
                    onClick={async() => clickConferma()}
                    text={loading ? 'Magia AI in corso' : 'Allena Chatbot'}
                ></BottoneAllena>
            </Box>}
        </Box>
    </>);
}

export default RetrainSources;