import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DrawerMobile from './DrawerMobile';
import BottoneHeader from './BottoneHeader';

import MenuIcon from '@mui/icons-material/Menu';
import useAgency from '../../controllers/agency/useAgency';

function Header() {

    const scrollRef = useRef()
    const {agency} = useAgency()

    const navigate = useNavigate()
    const {pathname, state} = useLocation()

    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
        scrollRef?.current?.scrollTo(0, 0)
    }, [pathname, state])

    const isRouteAttuale = useCallback((page) => {
        if(pathname === page) return true
        return false
    }, [pathname])

    const toggleDrawer = () => {
        setTimeout(() => {
            setOpenDrawer(!openDrawer);
        }, 50);
    }

    return (
        <Box sx={{width:'100%', height:{xs:window.innerHeight, sm:window.innerHeight, md:'100vh'}, overflow:'hidden'}}>
            <AppBar
                color='bianco'
                position="static" 
                elevation={0}
                sx={{borderBottom:'1px solid #dcdcdc', p:0, m:0, height:{xs:'60px', sm:'80px'}, zIndex:9999}}
            >
                <Toolbar disableGutters sx={{
                    width:{xs:'calc(100% - 32px)', sm:'calc(100% - 50px)', md:'calc(100% - 130px)'}, p:{xs:'0 16px', sm:'0 25px', md:'0 65px'}, 
                    height:'100%', m:0, alignItems:'center', justifyContent:'space-between'
                }}>
                    <Box sx={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={() => navigate('/')}>
                        <Box component='img' alt='' src={agency.logo} sx={{
                            width:'auto', height:{xs:'40px', sm:'50px'}, objectFit:'contain'
                        }} />
                        {agency.showTitle && <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:agency.titleColor,
                            fontSize:{xs:'24px', sm:'34px'}, fontWeight:'700', lineHeight:'normal', marginLeft:{xs:'6px', sm:'12px'}
                        }}>{agency.name}</Typography>}
                    </Box>
                    <Stack direction='row' spacing={{xs:'60px', md:'90px'}} sx={{
                        display:{xs:'none', sm:'flex'}, height:'100%'
                    }}>
                        <BottoneHeader
                            text={'I miei Chatbot'}
                            active={isRouteAttuale('/') || isRouteAttuale('/create-chatbot') || isRouteAttuale('/chatbot')}
                            onClick={() => navigate('/')}
                        ></BottoneHeader>
                        <BottoneHeader
                            text={'Account'}
                            active={isRouteAttuale('/settings')}
                            onClick={() => navigate('/settings')}
                        ></BottoneHeader>
                    </Stack>
                    <IconButton
                        onClick={toggleDrawer}
                        sx={{display:{xs:'flex', sm:'none'}, width:'30px', height:'30px'}}
                    >
                        <MenuIcon sx={{color:'black', width:'30px', height:'auto'}} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <DrawerMobile
                agency={agency}
                openDrawer={openDrawer}
                toggleDrawer={toggleDrawer}
                isRouteAttuale={isRouteAttuale}
            ></DrawerMobile>

            <Box sx={{
                width:'100%', overflow:'auto', backgroundColor:'#f9f9f9', position:'relative', 
                height:{xs:'calc(' + window.innerHeight + 'px - 60px)', sm:'calc(' + window.innerHeight + 'px - 80px)', md:'calc(100vh - 80px)'}
            }} ref={scrollRef}>
                <Outlet />
            </Box>
        </Box>
    );
}

export default Header;