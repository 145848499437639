import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import it from './lang/it';
import en from './lang/en';

const resources = {
    en: {
        translation:en
    },
    it: {
        translation: it
    }
}

i18n.use(initReactI18next).init({
    resources, lng:'en', default:'en',
    interpolation: {escapeValue: false}
});

export default i18n;