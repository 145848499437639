import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

function ContattiChatbot({visible, register, getValues, chatbot, cambiaAltro}) {

    const contacts = getValues('contacts')

    if(chatbot) return (
        <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
            margin:{xs:'12px 0 0', sm:'25px 0 0', lg:'25px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', display:visible ? 'block' : 'none'
        }}>
            <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'0px solid #dcdcdc'}}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                }}>Gestione Form Contatti</Typography>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                }}>
                    Abilita il tuo bot a far comparire nella chat un form di contatto
                    quando l'utente richiedere di essere contattato o di parlare con un umano. <br className='newline-no-mobile'/>
                    Puoi anche personalizzare i campi del form per richiedere all'utente le informazioni necessarie per il contatto. <br className='newline-no-mobile'/>
                    I contatti raccolti dal tuo bot saranno visibili nella scheda Statistiche.
                </Typography>

                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:{xs:'16px', sm:'20px'}
                    }}>Abilitato</Typography>
                    <Switch
                        {...register("contacts.enabled", {
                            required: false,
                            value: chatbot.contacts?.enabled,
                            onChange:() => cambiaAltro({modifiche:true})
                        })}
                        defaultChecked={chatbot.contacts ? chatbot.contacts.enabled : false}
                    />
                </Box>

                {contacts?.enabled && <Box sx={{marginTop:'20px'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Bene! Ora puoi selezionare i campi che vuoi abilitare nel form di contatto:
                    </Typography>
                    <Grid container spacing={{xs:'10px', sm:'10px'}} sx={{maxWidth:{xs:'100%', md:'700px'}}}>
                        <Grid item xs={6} sm={4}>
                            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <Switch
                                    {...register("contacts.email", {
                                        required: false,
                                        value: chatbot.contacts?.email,
                                        onChange:() => cambiaAltro({modifiche:true})
                                    })}
                                    defaultChecked={chatbot.contacts ? chatbot.contacts.email : false}
                                />
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'1.1', marginRight:{xs:0, md:'20px'}
                                }}>Email</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <Switch
                                    {...register("contacts.phone", {
                                        required: false,
                                        value: chatbot.contacts?.phone,
                                        onChange:() => cambiaAltro({modifiche:true})
                                    })}
                                    defaultChecked={chatbot.contacts ? chatbot.contacts.phone : false}
                                />
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'1.1', marginRight:{xs:0, md:'20px'}
                                }}>Telefono</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <Switch
                                    {...register("contacts.fullName", {
                                        required: false,
                                        value: chatbot.contacts?.fullName,
                                        onChange:() => cambiaAltro({modifiche:true})
                                    })}
                                    defaultChecked={chatbot.contacts ? chatbot.contacts.fullName : false}
                                />
                                <Typography noWrap sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'1.1', marginRight:{xs:0, md:'20px'}
                                }}>Nome Completo</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>}
            </Box>
        </Box>
    );
}

export default ContattiChatbot;