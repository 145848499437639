import './App.css';
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import {JWT_TOKEN} from "./config";
import storage from './libs/storage';

import authApi from './api/auth';
import userApi from './api/user';
import agencyApi from './api/agency';
import chatbotApi from './api/chatbot';
import visitorApi from './api/visitor';
import Navigator from './pages/Navigator';
import AuthContext from './controllers/auth/context';
import AgencyContext from './controllers/agency/context';
import VisitorContext from './controllers/visitors/context';
import ChatbotContext from './controllers/chatbots/context';
import AbbonamentoContext from './controllers/abbonamento/context';

function App() {

    const { i18n } = useTranslation()

    const [agency, setAgency] = useState(false);
    const [errore, setErrore] = useState(false);
    const [abbonamento, setAbbonamento] = useState(null);
    const [visitors, setVisitors] = useState({loading:true, list:[]});
    const [chatbots, setChatbots] = useState({loading:true, list:[]});
    const [user, setUser] = useState({account:null, user:null, loading:true});
  
    useEffect(() => {
        const aggAgency = async() => {
            //scarico agency
            const domain = window.location.hostname;
            const result = await agencyApi.getAgency(domain);
            if(!result.ok) {
                if(!parseInt(result.status) || parseInt(result.status) > 499) return setErrore('Errore di caricamento del Portale (ERR. 101)')
                return setErrore('Errore di caricamento del Portale (ERR. 102)')
            }
            //controllo abbonamento
            const subscription = result.data.subscription;
            if(!subscription?.metrics.agency || subscription.paymentFailed)
                return setErrore('Errore di caricamento del Portale (ERR. 103)')
            //alla fine i dati
            setAgency({...result.data.agency})
            setAbbonamento({...result.data.subscription})
        }
        aggAgency()
    }, [])

    useEffect(() => {
        const aggUser = async() => {
            //controllo pagina
            if(window.location.pathname == '/login' ||
                window.location.pathname == '/forgot-password') return;
            //controllo iframe
            if(window.location.pathname.includes('chatbot-iframe')) return;
            //scarico info utente
            const TOKEN_KEY = JWT_TOKEN + '-' + agency.id;
			let result = await userApi.getMe(agency.id)
            if(!result.ok) {
                storage.setItem(TOKEN_KEY, null)
                return window.location.replace('/login')
            }
            setTimeout(async() => {
                setUser({...result.data, loading:false})
                //aggiorno il token
                result = await authApi.refreshToken(agency.id)
                if(!result.ok) return
                storage.setItem(TOKEN_KEY, result.data.token)
            }, 300);
        }
        if(agency) aggUser()
    }, [agency])

    useEffect(() => {
        const aggLang = () => {
            if(user?.user?.language) {
                return i18n.changeLanguage(user?.user.language);
            }
            const lang = navigator.language || navigator.userLanguage;
            i18n.changeLanguage(lang.split('-')[0] === 'it' ? 'it' : 'en');
        }
        aggLang()
    }, [user?.user])

    useEffect(() => {
        const aggCustomGraphics = () => {
            //aggiorno il nome
            document.title = agency.name || 'Portale Chatbot';
            //aggiorno il logo
            let theLogo = agency.browserLogo;
            if(!theLogo?.length) theLogo = agency.logo;
            if(!theLogo?.length) return;
            const favicon = document.getElementById("favicon");
            if(favicon) favicon.href = theLogo;
        }
        if(agency) aggCustomGraphics()
    }, [agency])

    useEffect(() => {
        const aggChatbots = async() => {
            const result = await chatbotApi.listChatbots(agency.id)
            if(!result.ok) return
            setTimeout(() => {
                setChatbots({loading:false, list:result.data})
            }, 300);
        }
        const clearChatbots = () => setChatbots({loading:true, list:[]})
        if(user?.account) aggChatbots()
        if(!user.account && chatbots.length > 0) clearChatbots()
    }, [user?.account])

    useEffect(() => {
        const aggVisitors = async() => {
            const result = await visitorApi.listVisitors(agency.id)
            if(!result.ok) return setVisitors({loading:false, list:[]})
            setTimeout(() => {
                setVisitors({loading:false, list:result.data})
            }, 300);
        }
        if(user?.user && user?.user.role === 'admin') aggVisitors()
    }, [user?.user])

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1000,
                lg: 1200,
                xl: 1636,
            },
        },
        palette: {
            primary: {
                main: agency ? agency.primaryColor : '#000',
                dark: agency ? agency.primaryColor : '#000',
                contrastText: agency ? agency.secondaryColor : "#FFF"
            },
            title: {
                main: agency ? agency.titleColor : '#000',
                dark: agency ? agency.titleColor : '#000',
                contrastText:"#FFF"
            },
            secondary: {
                main: '#EAECF0',
                contrastText: "#000",
                dark: "#EAECF0"
            },
            bianco: {
                main: '#FFF',
                contrastText: "#000",
                dark: "#FFF"
            },
            nero: {
                main: '#000',
                contrastText: "#FFF",
                dark: "#000"
            },
            rosso: {
                main: '#FC2A1B',
                contrastText: "#FFF",
                dark: "#FC2A1B"
            },
            verde: {
                main: '#34A853',
                contrastText: "#FFF",
                dark: "#34A853"
            },
            grigio: {
                main: '#DCDCDC',
                contrastText: "#000",
                dark: "#DCDCDC"
            },
            grigioScuro: {
                main: '#696969',
                contrastText: "#000",
                dark: "#696969"
            },
            discord: {
                main: '#7289D9',
                contrastText: "#FFF",
                dark: "#7289D9"
            },
            linkedin: {
                main: '#0A66C2',
                contrastText: "#FFF",
                dark: "#0A66C2"
            }
        },
        typography: {
            fontFamily: `"Nunito", sans-serif`
        }
    })

    if(errore) return (
        <Box sx={{width:'100%', height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <span style={{fontWeight:'600', fontSize:'25px', color:'red'}}>{errore}</span>
        </Box>
    );

    if(agency) return (
        <ThemeProvider theme={theme}>
            <AgencyContext.Provider value={{agency, setAgency}}>
                <AuthContext.Provider value={{user, setUser}}>
                    <ChatbotContext.Provider value={{chatbots, setChatbots}}>
                        <AbbonamentoContext.Provider value={{abbonamento, setAbbonamento}}>
                            <VisitorContext.Provider value={{visitors, setVisitors}}>
                                <Navigator></Navigator>
                            </VisitorContext.Provider>
                        </AbbonamentoContext.Provider>
                    </ChatbotContext.Provider>
                </AuthContext.Provider>
            </AgencyContext.Provider>
        </ThemeProvider>
    );
}

export default App;
