import { API_URL } from "../config";
import client from "./client";

const getChatbotByCode = (code, isBackend) => {
    if(isBackend) return client.get(API_URL + '/widgets/chatbots/named/' + code + '?isBackend=true');
    return client.get(API_URL + '/widgets/chatbots/named/' + code);
}

export default {
    getChatbotByCode
}