import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AppRadio from '../generale/AppRadio';

function RigaBotUtente({chatbot, selezionato, premiChatbot, isUltimo, isVedi}) {

    return (
        <Box sx={{width:'100%', display:'flex', boxSizing:'border-box', 
            padding:{xs:'8px 8px', sm:isVedi ? '8px 12px' : '8px', lg:isVedi ? '8px 12px' : '8px'}, alignItems:'center',
            borderBottom:isUltimo ? 0 : '1px solid #dcdcdc'
        }}>
            {!isVedi && <AppRadio
                text={null}
                value={selezionato}
                onChange={premiChatbot}
            ></AppRadio>}

            <Box sx={{display:'flex', alignItems:'center'}}>
                <Box sx={{width:{xs:'20px', sm:'30px'}, height:{xs:'20px', sm:'30px'}, borderRadius:'25px', backgroundColor:chatbot.buttonColor,
                    display:'flex', alignItems:'center', justifyContent:'center'
                }}>
                    {chatbot.buttonPhoto &&
                        <Box component='img' alt='' src={chatbot.buttonPhoto} sx={{
                            width:{xs:'16px', sm:'24px'}, height:{xs:'16px', sm:'24px'}, objectFit:'contain'
                        }} />
                    }
                </Box>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginLeft:{xs:'4px', sm:'8px'}
                }}>{chatbot.name}</Typography>
            </Box>
        </Box>
    );
}

export default RigaBotUtente;