import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AppAlert from '../generale/AppAlert';
import BottoniAccount from './BottoniAccount';
import AppTextField from '../generale/AppTextField';

function ZonaPassword({visible, conferma, annulla, loading, errore, chiudiAlert}) {

    const schema = yup.object().shape({
        vecchia: yup.string().required('La vecchia password è obbligatoria.').min(8, 'La password deve avere almeno 8 caratteri.'),
        nuova: yup.string().required('La nuova password è obbligatoria.').min(8, 'La password deve avere almeno 8 caratteri.'),
        ripeti: yup.string().required('Ripeti la nuova password per conferma.').min(8, 'La password deve avere almeno 8 caratteri.')
    });
    const {register, handleSubmit, setValue, formState: { errors }} = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const aggDati = () => {
            setValue('vecchia', '')
            setValue('nuova', '')
            setValue('ripeti', '')
        }
        if(visible) aggDati()
    }, [visible])
    
    return (
        <form
            id="cambia-pwd-form"
            name="cambia-pwd-form"
            data-name="Cambia Password Form"
            onSubmit={handleSubmit(conferma)}
        >
            <Box sx={{width:'100%', backgroundColor:'transparent', borderRadius:'20px', 
                margin:{xs:'20px 0 0', sm:'40px 0 0'}, display: visible ? 'block' : 'none'
            }}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:{xs:'title.main', sm:'black'}, p:{xs:0, sm:'30px 0 0'},
                    fontSize:{xs:'20px', sm:'30px'}, fontWeight:'500', lineHeight:'normal', marginBottom:{xs:'4px', sm:'10px'}
                }}>Cambio Password</Typography>
                <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:{xs:'200px', lg:'300px'}
                    }}>Vecchia Password</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'vecchia'}
                        width={'400px'}
                        isLabel={false}
                        isPassword={true}
                        error={errors.vecchia?.message}
                        placeholder={'Vecchia Password'}
                        register={register("vecchia", {required: true})}
                    ></AppTextField>
                </Box>
                <Box sx={{padding:{xs:'12px 0', sm:'25px 0'}, display:'flex', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:{xs:'200px', lg:'300px'}
                    }}>Nuova Password</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'nuova'}
                        width={'400px'}
                        isLabel={false}
                        isPassword={true}
                        error={errors.nuova?.message}
                        placeholder={'Nuova Password'}
                        register={register("nuova", {required: true})}
                    ></AppTextField>
                </Box>
                <Box sx={{padding:{xs:'12px 0', sm:'25px 0 45px'}, display:'flex', 
                    flexDirection:{xs:'column', sm:'row'}, alignItems:'flex-start'
                }}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'16px', fontWeight:'500', lineHeight:'normal', width:{xs:'200px', lg:'300px'}
                    }}>Ripeti Password</Typography>
                    <AppTextField
                        isBianco={true}
                        name={'ripeti'}
                        width={'400px'}
                        isLabel={false}
                        isPassword={true}
                        error={errors.ripeti?.message}
                        placeholder={'Ripeti Password'}
                        register={register("ripeti", {required: true})}
                    ></AppTextField>
                </Box>
                {/* <Box sx={{width:'100%', border:'1px solid #D0D5DD', backgroundColor:'#D0D5DD'}} /> */}
                <BottoniAccount
                    annulla={annulla}
                    loading={loading}
                ></BottoniAccount>
            </Box>

            <AppAlert
                open={Boolean(errore)}
                chiudiAlert={chiudiAlert}
                severity={'error'}
                message={errore}
            ></AppAlert>
        </form>
    );
}

export default ZonaPassword;