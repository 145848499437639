import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './login/Login';
import Settings from './Settings';
import Dashboard from './Dashboard';
import ChatFrame from './ChatFrame';
import CreaChatbot from './CreaChatbot';
import RetrainChatbot from './RetrainChatbot';
import GestisciChatbot from './GestisciChatbot';
import Header from '../components/header/Header';

function Navigator(props) {

    return (
        <BrowserRouter>
            <Routes>
                {/* BACKEND */}
                <Route path="/" element={<Header />}>
                    <Route index element={<Dashboard />} />
                    <Route path='chatbot' element={<GestisciChatbot />} />
                    <Route path='create-chatbot' element={<CreaChatbot />} />
                    <Route path='retrain-chatbot' element={<RetrainChatbot />} />

                    <Route path='settings'>
                        <Route index element={<Settings />} />
                    </Route>
                </Route>

                {/* CHAT FRAME */}
                <Route path="/chatbot-iframe">
                    <Route index element={<React.Fragment></React.Fragment>} />
                    <Route path=':chatbotName' element={<ChatFrame />} />
                </Route>

                {/* AUTH */}
                <Route path="/login" element={<Login />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Navigator;