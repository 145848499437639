import { API_URL, JWT_TOKEN } from "../config";
import storage from '../libs/storage';
import client from "./client";

const endpoint1 = API_URL + '/chatbots/'
const endpoint2 = API_URL + '/trainings/'
const endpoint3 = API_URL + '/chatbots/integrations/'

//// API DEL CHATBOT
const listChatbots = (agencyId) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.get(endpoint1, {}, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const createChatbot = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint1, data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const updateChatbot = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.put(endpoint1, data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const createButtonImage = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint1 + 'button-image', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const deleteChatbot = (agencyId, idChatbot) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.delete(endpoint1 + idChatbot, {}, {headers:{
        Authorization:'Bearer ' + token
    }})
}

//// API DI ALLENAMENTO
const trainChatbot = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2, data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const createTraining = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'create', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const trainChatbotQA = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'QA', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const retrainChatbot = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'retrain', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const retrainChatbotQA = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'retrain/QA', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const deleteTraining = (agencyId, trainingId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.delete(endpoint2 + trainingId, data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const readFiles = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'read-files', data, {
        headers:{
            Authorization:'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
        }
    });
}
const readWebsites = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'read-website', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const readWebsiteByScraping = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'read-website/scraping', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}
const readWebsiteBySitemap = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint2 + 'read-website/sitemap', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}

//// API DI INTEGRAZIONI
const updateWaData = (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.put(endpoint3 + 'wa', data, {headers:{
        Authorization:'Bearer ' + token
    }})
}

export default {
    listChatbots,
    createChatbot,
    updateChatbot,
    createButtonImage,
    deleteChatbot,

    trainChatbot,
    createTraining,
    trainChatbotQA,
    retrainChatbot,
    retrainChatbotQA,
    deleteTraining,

    readFiles,
    readWebsites,
    readWebsiteByScraping,
    readWebsiteBySitemap,

    updateWaData
}