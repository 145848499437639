import { API_URL, JWT_TOKEN } from "../config";
import storage from '../libs/storage';
import client from "./client";

const endpoint = API_URL + '/auth/';

const logout = async (agencyId) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    storage.deleteKey(TOKEN_KEY);
}
  
const login = async (agencyId, data) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const result = await client.post(endpoint + "agency/login", data);
    storage.setItem(TOKEN_KEY, result.data.token);
    return result;
}

const forgotPassword = async(data) => {
    return client.post(endpoint + "send-forgot-password-code", data);
}

const resetPassword = async(data) => {
    return client.post(endpoint + "reset-password", data);
}

const refreshToken = async(agencyId) => {
    const TOKEN_KEY = JWT_TOKEN + '-' + agencyId;
    const token = storage.getItem(TOKEN_KEY);
    return client.post(endpoint + "refresh-token", {}, {headers:{
        Authorization:'Bearer ' + token
    }});
}

export default {
    logout,
    login,
    forgotPassword,
    resetPassword,
    refreshToken
}