import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useVisitor from '../../controllers/visitors/useVisitor';

import visitorApi from '../../api/visitor';
import TabellaUtenti from './TabellaUtenti';
import AppAlert from '../generale/AppAlert';
import ModalCreaUtente from './ModalCreaUtente';
import MenuVediChatbot from './MenuVediChatbot';
import useAuth from '../../controllers/auth/useAuth';
import ModalEliminaUtente from './ModalEliminaUtente';
import useChatbot from '../../controllers/chatbots/useChatbot';

import AddIcon from '@mui/icons-material/Add';

function ZonaGestioneUtenti({visible, agency}) {

    const {user} = useAuth();
    const {chatbots} = useChatbot();
    const {visitors, visitorsLoading, addVisitor, updateVisitor, removeVisitor} = useVisitor();

    const [selezionato, setSelezionato] = useState(null)
    const [vediChatbots, setVediChatbots] = useState({anchorVariabili:null, open:false, list:[]})
    const [altro, setAltro] = useState({loading:false, errore:null, visible:0, openAlert:0})

    const creaVisitatore = async(values) => {
        if(altro.loading) return;
        if(!values.chatbots.length) return setAltro({...altro, errore:'Associare almeno un chatbot!'})
        setAltro({...altro, loading:1})
        values.language = user.language;
        const result = await visitorApi.createVisitor(agency.id, values);
        if(!result.ok) return setTimeout(() => {
            setAltro({...altro, loading:false, errore:result.data.message})
        }, 300);
        setTimeout(() => {
            addVisitor(result.data)
            setAltro({...altro, visible:0, errore:null, loading:false, openAlert:1})
            setSelezionato(null)
        }, 600);
    }

    const modificaVisitatore = async(values) => {
        if(altro.loading) return;
        if(!values.chatbots.length) return setAltro({...altro, errore:'Associare almeno un chatbot!'})
        if(values.password?.length > 0) {
            if(values.password?.length < 8) return setAltro({...altro, errore:'La nuova password deve avere almeno 8 caratteri.'})
        }
        setAltro({...altro, loading:1})
        values.language = user.language;
        const result = await visitorApi.updateVisitor(agency.id, {id:selezionato.id, ...values});
        if(!result.ok) return setTimeout(() => {
            setAltro({...altro, loading:false, errore:result.data.message})
        }, 300);
        setTimeout(() => {
            updateVisitor(result.data)
            setAltro({...altro, visible:0, errore:null, loading:false, openAlert:2})
            setSelezionato(null)
        }, 600);
    }

    const eliminaVisitatore = async() => {
        if(altro.loading) return;
        setAltro({...altro, loading:2})
        const result = await visitorApi.deleteVisitor(agency.id, selezionato.id);
        if(!result.ok) return setTimeout(() => {
            setAltro({...altro, loading:false, errore:result.data.message})
        }, 300);
        setTimeout(() => {
            removeVisitor(selezionato.id)
            setAltro({...altro, visible:0, errore:null, loading:false, openAlert:3})
            setSelezionato(null)
        }, 600);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, errore:null, openAlert:0})
    }
    
    return (
        <Box sx={{width:'100%', backgroundColor:'transparent', borderRadius:'20px', 
            margin:{xs:'20px 0 0', sm:'40px 0 0'}, display: visible ? 'block' : 'none'
        }}>
            <Box sx={{width:'100%', display:'flex', flexDirection:{xs:'column', sm:'row'}, 
                alignItems:{xs:'flex-start', sm:'flex-end'}, justifyContent:'space-between'
            }}>
                <Box><Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:{xs:'title.main', sm:'black'}, p:{xs:0, sm:'30px 0 0'},
                    fontSize:{xs:'20px', sm:'30px'}, fontWeight:'500', lineHeight:'normal'
                }}>Gestione Utenti</Typography>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                    fontSize:{xs:'15px', sm:'18px'}, fontWeight:'400', lineHeight:'normal',
                    margin:{xs:'2px 0 12px', sm:'2px 0 0'}
                }}>Crea e gestisci gli utenti che hanno accesso ai tuoi chatbot</Typography></Box>
                <Button
                    color="primary"
                    variant="contained"
                    sx={{border:'none', padding:'12px 24px', border:'1px solid #dcdcdc',
                        borderRadius:'12px', textTransform: 'none', height:'fit-content'
                    }}
                    startIcon={<AddIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='white' />}
                    disableElevation={true}
                    onClick={() => setAltro({...altro, visible:1})}
                >
                    <Typography component='span' sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500',
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'4px'}
                    }}>Aggiungi Utente</Typography>
                </Button>
            </Box>

            <TabellaUtenti
                visitors={visitors.filter((el) => !el.accountOwner)}
                visitorsLoading={visitorsLoading}
                premiModifica={(utente) => {
                    setSelezionato(utente)
                    setAltro({...altro, visible:1})
                }}
                premiElimina={(utente) => {
                    setSelezionato(utente)
                    setAltro({...altro, visible:2})
                }}
                vediChatbots={(event, list) => setVediChatbots({
                    anchorVariabili:event.currentTarget, open:true, list:list
                })}
            ></TabellaUtenti>

            <ModalCreaUtente
                chatbots={chatbots}
                selezionato={selezionato}
                visible={altro.visible === 1}
                loading={altro.loading === 1}
                confermaCreazione={creaVisitatore}
                confermaModifiche={modificaVisitatore}
                chiudi={() => {
                    setAltro({...altro, visible:0})
                    if(selezionato) setSelezionato(null)
                }}
            ></ModalCreaUtente>

            <ModalEliminaUtente
                visible={altro.visible === 2}
                selezionato={selezionato}
                loading={altro.loading === 2}
                conferma={eliminaVisitatore}
                cambiaAltro={(v) => setAltro({...altro, ...v})}
                chiudi={() => {
                    setAltro({...altro, visible:0})
                    if(selezionato) setSelezionato(null)
                }}
            ></ModalEliminaUtente>

            <MenuVediChatbot
                chatbots={chatbots}
                open={vediChatbots.open}
                list={vediChatbots.list}
                anchorVariabili={vediChatbots.anchorVariabili}
                onClose={() => setVediChatbots({anchorVariabili:null, open:false, list:[]})}
            ></MenuVediChatbot>

            <AppAlert
                open={Boolean(altro.errore)}
                chiudiAlert={chiudiAlert}
                severity={'error'}
                message={altro.errore}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Utente creato con successo!'}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 2}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Utente modificato con successo!'}
            ></AppAlert>
            <AppAlert
                open={altro.openAlert === 3}
                chiudiAlert={chiudiAlert}
                severity={'success'}
                message={'Utente eliminato con successo!'}
            ></AppAlert>
        </Box>
    );
}

export default ZonaGestioneUtenti;