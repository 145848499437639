import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function ModalLogout({visible, conferma, chiudi}) {
    
    return (
        <Modal
            open={visible}
            closeAfterTransition
            keepMounted
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', 
                width:'100%', height:'100%', position:'relative', backgroundColor:'rgba(0, 0, 0, 0.2)'
            }} onClick={chiudi}>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'fit-content'}, backgroundColor:'white', 
                    borderRadius:'16px', border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    padding:{xs:'45px 16px', sm:'45px 40px', md:'45px 80px', lg:'65px 120px'}, display:'flex', flexDirection:'column',
                    margin:{xs:'16px 24px', sm:0}, height:'fit-content', overflowY:{xs:'scroll', sm:'auto'}
                }} onClick={(e) => e.stopPropagation()}>
                    
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'20px', sm:'24px'}, fontWeight:'700', lineHeight:'normal'
                    }}>Effettua il Logout</Typography>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#696969', margin:{xs:'12px 0 0', sm:'5px 0 0'},
                        fontSize:{xs:'15px', sm:'18px'}, fontWeight:'400', lineHeight:{xs:'1.1', sm:'normal'},
                        maxWidth:{xs:'70%', sm:'100%'}, marginLeft:{xs:'15%', sm:0}
                    }}>Sei sicuro di voler effttuare il logout dal tuo account?</Typography>

                    <Box sx={{display:'flex', width:{xs:'100%', sm:'50%'}, backgroundColor:'transparent', 
                        margin:{xs:'30px 0 0', lg:'30px 0 0'}, flexDirection:{xs:'column', sm:'row'}, alignItems:'center'
                    }}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            sx={{
                                padding:{xs:'5px 24px', sm:'9px 42px'}, borderRadius:'12px', textTransform: 'none',
                                minWidth:'180px', border:'1px solid #dcdcdc'
                            }}
                            onClick={conferma}
                            disableElevation={true}
                        >
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'600', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>Conferma</Typography>
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"nero"}
                            sx={{
                                padding:{xs:'5px 24px', sm:'9px 42px'}, borderRadius:'12px', textTransform: 'none',
                                border:'1px solid #dcdcdc', minWidth:'180px',
                                marginLeft:{xs:0, sm:'24px'}, marginTop:{xs:'12px', sm:0}
                            }}
                            onClick={chiudi}
                            disableElevation={true}
                        >
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'600', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>Annulla</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalLogout;