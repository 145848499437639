import React from 'react';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

function BottoneSalva({text, loading, isElimina, isPiccolo, Icon, onClick}) {

    if(isPiccolo) return (
        <LoadingButton
            type={isElimina ? 'button' : 'submit'}
            color={isElimina ? "rosso" : "primary"}
            variant="contained"
            sx={{border:'none', padding:{xs:'8px 12px', sm:'10px 24px'}, borderRadius:'16px', textTransform: 'none'}}
            disableElevation={true}
            loading={loading}
            loadingPosition={Icon ? 'center' : 'center'}
            startIcon={Icon ? <Icon sx={{width:'24px', height:'auto', color:'white', marginRight:'4px'}} /> : null}
            onClick={onClick}
        >
            <Typography component='span' sx={{fontSize:{xs:'15px', sm:'17px', lg:'18px'}, fontWeight:'700', lineHeight:'normal', 
                letterSpacing:'-0.2', textAlign:'center', color:'white'
            }}>{text}</Typography>
        </LoadingButton>
    );

    return (
        <LoadingButton
            type={isElimina ? 'button' : 'submit'}
            color={isElimina ? "rosso" : "primary"}
            variant="contained"
            sx={{border:'1px solid #dcdcdc', padding:{xs:'10px 20px', sm:'13px 35px'}, borderRadius:'16px', textTransform: 'none'}}
            disableElevation={true}
            loading={loading}
            loadingPosition={'center'}
            onClick={onClick}
        >
            <Typography component='span' sx={{fontSize:{xs:'15px', sm:'18px', lg:'20px'}, fontWeight:'700',
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'center'
            }}>{text}</Typography>
        </LoadingButton>
    );
}

export default BottoneSalva;