import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function BottoneSito({text, onClick, tipologia}) {

    return (
        <Button
            color={tipologia == 1 ? 'grigio' : 'primary'}
            variant="contained"
            sx={{
                minWidth:{xs:'140px', sm:'160px'}, maxWidth:'fit-content', height:{xs:'30px', sm:'40px'},
                border:'1px solid #dcdcdc', borderRadius:'12px', textTransform: 'none'
            }}
            disableElevation={true}
            onClick={onClick}
        >
            <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', 
                letterSpacing:'-0.2', textAlign:'left', color:tipologia == 1 ? 'black' : 'inherit'
            }}>{text}</Typography>
        </Button>
    );
}

export default BottoneSito;