import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function RigaChatbot({bot, isUltimo, premiChatbot}) {

    return (
        <Box sx={{width:{xs:'calc(100% - 24px)', sm:'calc(100% - 50px)', lg:'calc(100% - 50px)'}, display:'flex', 
            padding:{xs:'14px 12px', sm:'25px', lg:'25px'}, alignItems:'center', justifyContent:'space-between',
            borderBottom:isUltimo ? 0 : '1px solid #dcdcdc', cursor:'pointer'
        }} onClick={() => premiChatbot(bot)}>
            <Box sx={{display:'flex', alignItems:'center'}}>
                <Box sx={{width:{xs:'38px', sm:'50px'}, height:{xs:'38px', sm:'50px'}, borderRadius:'25px',
                    backgroundColor:bot.buttonColor, display:'flex', alignItems:'center', justifyContent:'center'
                }}>
                    {bot.buttonPhoto &&
                        <Box component='img' alt='' src={bot.buttonPhoto} sx={{
                            width:{xs:'30px', sm:'40px'}, height:{xs:'30px', sm:'40px'}, objectFit:'contain'
                        }} />
                    }
                </Box>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'18px', sm:'24px'}, fontWeight:'600', lineHeight:'normal', marginLeft:{xs:'12px', sm:'38px'}
                }}>{bot.name}</Typography>
            </Box>
            
            <Box sx={{display:'flex', width:'fit-content'}}>
                {!bot.enabled && <Box sx={{
                    padding:{xs:'4px 6px', sm:'10px 16px'}, borderRadius:'12px', 
                    textTransform: 'none', marginRight:{xs:'10px', md:'30px'}, backgroundColor:'rosso.main'
                }}>
                    <Typography noWrap sx={{fontSize:{xs:'10px', sm:'15px', lg:'16px'}, fontWeight:'700',
                        color:'white', lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                    }}>Disabilitato</Typography>
                </Box>}
                <KeyboardBackspaceIcon sx={{width:{xs:'30px', sm:'40px'}, height:'auto', rotate:'180deg'}} color='black' />
            </Box>
        </Box>
    );
}

export default RigaChatbot;