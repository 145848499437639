import React, {useRef, useEffect, useCallback} from 'react';
import Lottie from 'lottie-react';

import './chat.css';
import Messaggio from './Messaggio';
import FormContatti from './FormContatti';
import PrivacyPolicy from './PrivacyPolicy';

import * as animationData from '../../images/animations/animation.json'
import * as animationWhiteData from '../../images/animations/animation-white.json'

function CorpoChatFrame({
    chatbot, lang, messaggi, nuovoMessaggio, loading, caricamento, isPrivacyPolicy,
    accettaPolicy, errore, contactFormData, confermaFormContatto, apriFormContatto
}) {

    const scrollRef = useRef()

    const isDark = chatbot?.theme === 1;
    const startMessages = chatbot?.startMessages[lang];
    const isNuovoMessaggio = Boolean(nuovoMessaggio?.length);

    useEffect(() => {
        const aggScroll = () => {
            scrollRef?.current?.scrollIntoView({
                behavior:"smooth", block:"end"
            });
        }
        setTimeout(() => aggScroll(), 10);
    }, [loading, messaggi.length])

    useEffect(() => {
        const aggScroll = () => {
            if(!nuovoMessaggio?.length) return;
            scrollRef?.current?.scrollIntoView({
                behavior:"smooth", block:"end"
            });
        }
        aggScroll();
    }, [nuovoMessaggio])

    useEffect(() => {
        const aggChiudiForm = () => apriFormContatto(false);
        if(contactFormData.chiudi) aggChiudiForm();
    }, [messaggi.length])

    const isContactForm = useCallback(() => {
        if(contactFormData.send) return true;
        if(contactFormData.chiudi) return false;
        if(!chatbot.contacts?.enabled) return false;
        for(let msg of messaggi) {
            if(msg.metadata?.contacts) return false;
        }
        return true;
    }, [chatbot, messaggi, contactFormData])

    return (
        <div 
            className='chat-container'
            style={{backgroundColor:isDark ? 'black' : 'white'}}
        >
            {!caricamento ? (
                <div className='zona-errore'>
                    <div style={{width:'100px'}}>
                        <Lottie 
                            height={'auto'}
                            loop={true}
                            autoPlay={true}
                            animationData={isDark ? animationWhiteData : animationData}
                            rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}}
                        />
                    </div>
                </div>
            ) : (<>
                {chatbot ? (isPrivacyPolicy ? (<>
                    <PrivacyPolicy
                        accettaPolicy={accettaPolicy}
                        buttonColor={chatbot.buttonColor}
                        link={chatbot.privacyPolicy[lang]}
                        secondaryColor={chatbot.secondaryColor}
                    ></PrivacyPolicy>
                </>) : (<>
                    {startMessages.map((msg, index) => (
                        <Messaggio
                            isUser={false}
                            isDark={isDark}
                            contenuto={msg}
                            isDouble={index > 0}
                            key={"start" + index}
                            buttonColor={chatbot.buttonColor}
                            coloreSfondo={chatbot.botMessageColor}
                            coloreTesto={chatbot.botTextColor}
                            displayName={chatbot.displayName}
                        ></Messaggio>
                    ))}
                    {messaggi.map((msg, index) => (
                        <Messaggio
                            key={index}
                            isDark={isDark}
                            contenuto={msg.content}
                            isOperator={msg.operator}
                            isUser={msg.role == 'user'}
                            operatorStatus={msg.status}
                            buttonColor={chatbot.buttonColor}
                            displayName={chatbot.displayName}
                            isDouble={messaggi[index - 1]?.role === msg.role}
                            coloreSfondo={msg.role == 'user' ? chatbot.userMessageColor : chatbot.botMessageColor}
                            coloreTesto={msg.role == 'user' ? chatbot.userTextColor : chatbot.botTextColor}
                        ></Messaggio>
                    ))}
                    {loading && !isNuovoMessaggio &&
                        <div className='loading-container'>
                            <div style={{width:'80px'}}>
                                <Lottie 
                                    height={'auto'}
                                    loop={true}
                                    autoPlay={true}
                                    animationData={isDark ? animationWhiteData : animationData}
                                    rendererSettings={{preserveAspectRatio: 'xMidYMid slice'}}
                                />
                            </div>
                        </div>
                    }
                    {loading && isNuovoMessaggio &&
                        <Messaggio
                            isUser={false}
                            isDark={isDark}
                            contenuto={nuovoMessaggio}
                            buttonColor={chatbot.buttonColor}
                            coloreSfondo={chatbot.botMessageColor}
                            coloreTesto={chatbot.botTextColor}
                            displayName={chatbot.displayName}
                        ></Messaggio>
                    }
                    {isContactForm() && !loading && !isNuovoMessaggio &&
                        messaggi.length > 0 && !errore &&
                        <FormContatti 
                            chatbot={chatbot}
                            contactFormData={contactFormData}
                            annulla={() => apriFormContatto(true)}
                            confermaInvio={confermaFormContatto}
                        />
                    }
                    {errore && <div className='container-errore-chat'>
                        <div className='zona-errore-chat'>
                            <span
                                className='testo-errore-chat'
                                style={{color:isDark ? 'white' : 'black'}}
                            >{errore}</span>
                        </div>
                    </div>}
                </>)
                ) : (
                    <div className='zona-errore'>
                        <span>{errore}</span>
                    </div>
                )}
            </>)}
            <div ref={scrollRef} id='scroll-target' />
        </div>
    );
}

export default CorpoChatFrame;