import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

function FooterChatFrame({chatbot, lang, loading, branding, caricamento, inviaMessaggio}) {

    const { t } = useTranslation()

    const [valore, setValore] = useState('')
    const [question, setQuestion] = useState(-1)
    const [inputSel, setInputSel] = useState(false)

    const isDark = chatbot?.theme === 1;
    const suggestedQuestions = chatbot?.suggestedQuestions[lang];

    const premiInvia = async(question) => {
        if(!valore?.length && !question?.length) return
        if(question) {
            question = question.replace(/\[.*?\]/g, '');
        }
        inviaMessaggio(question ? question : valore)
        setTimeout(() => {
            setValore('')
        }, 10);
    }

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') {
            //e.preventDefault(); QUESTO NON FUNZIONA
            if(!valore?.length) return null
            else premiInvia()
        } else return null
    }

    const scriviQuestion = (quest) => {
        let risultato = quest.match(/\[(.*?)\]/g);
        if(!risultato) return quest;
        return risultato[0].replace('[', '').replace(']', '');
    }
    
    return (
        <div style={{backgroundColor:isDark ? 'black' : 'white'}}>
            {suggestedQuestions?.length > 0 &&
                <ul
                    className='footer-zona-domande'
                    style={{backgroundColor:isDark ? 'black' : 'white'}}
                >
                    {suggestedQuestions.map((quest, index) => (
                        <li 
                            key={'quest' + index}
                            className='domanda-footer'
                            style={{
                                border:'1px solid ' + chatbot.buttonColor,
                                backgroundColor: question == index ? chatbot.buttonColor : (isDark ? 'black' : 'white'),
                                color: question == index ? chatbot.secondaryColor : (isDark ? 'white' : 'black')
                            }}
                            onMouseOver={() => setQuestion(index)}
                            onMouseLeave={() => setQuestion(-1)}
                            onClick={async() => await premiInvia(quest)}
                        >{scriviQuestion(quest)}</li>
                    ))}
                </ul>
            }
            <div 
                className='footer-zona-input'
                style={{
                    backgroundColor:isDark ? 'black' : 'white',
                    border:inputSel ? '1px solid ' + chatbot?.buttonColor : '1px solid #c4c4c4'
                }}
            >
                <input 
                    type="text" aria-label="chat-input" required="" 
                    maxLength="650" className="footer-text-input"
                    placeholder={t('chat.scriviDomanda')}
                    value={valore} onChange={(e) => setValore(e.target.value)} 
                    onKeyDown={handleKeyDown}
                    style={{color:isDark ? 'white' : 'black'}}
                    onBlur={() => setInputSel(false)}
                    onFocus={() => setInputSel(true)}
                    autoComplete={'off'} autoCorrect={'off'}
                />
                <button
                    disabled={loading || !caricamento}
                    onClick={async() => await premiInvia()}
                    className="footer-btn-invia"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={chatbot?.buttonColor} aria-hidden="true" className='icona-invia'>
                        <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"></path>
                    </svg>
                </button>
            </div>

            <div className='footer-zona-powered'>
                {branding && <>
                    <a target="_blank" style={{display:'flex', alignItems:'center'}} href="https://supportfast.ai">
                        <p 
                            className='footer-powered-by'
                            style={{color:isDark ? 'white' : '#3f3f46'}}
                        >Powered by</p>&nbsp;
                        <p 
                            className='footer-powered-by powered-by-link'
                            style={{color:isDark ? 'white' : '#3f3f46'}}
                        >
                            Supportfast.ai
                        </p>
                    </a>
                </>}
            </div>
        </div>
    );
}

export default FooterChatFrame;