import React from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import AppTextField from '../generale/AppTextField';

function NotificheChatbot({chatbot, visible, register, errors, cambiaAltro}) {
    
    if(chatbot) return (
        <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
            margin:{xs:'12px 0 0', sm:'25px 0 0', lg:'25px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', display:visible ? 'block' : 'none'
        }}>
            <Box sx={{padding:{xs:'16px 12px', sm:'30px'}, borderBottom:'0px solid #dcdcdc'}}>
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                }}>Gestione Notifiche</Typography>

                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'18px 0 2px'
                }}>
                    Ricevi una email ad ogni nuova chat avviata.
                </Typography>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:{xs:'16px', sm:'20px'}
                    }}>Nuova Chat</Typography>
                    <Switch
                        {...register("notifications.chat", {
                            required: false,
                            value: chatbot.notifications.chat,
                            onChange:() => cambiaAltro({modifiche:true})
                        })}
                        defaultChecked={chatbot.notifications ? chatbot.notifications.chat : false}
                    />
                </Box>

                {/* <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'18px 0 2px'
                }}>
                    Ricevi una email ogni volta che un utente richiede l'intervento di un operatore umano.
                </Typography>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:{xs:'16px', sm:'20px'}
                    }}>Richiesta Operatore</Typography>
                    <Switch
                        {...register("notifications.operator", {
                            required: false,
                            value: chatbot.notifications.operator,
                            onChange:() => cambiaAltro({modifiche:true})
                        })}
                        defaultChecked={chatbot.notifications ? chatbot.notifications.operator : false}
                    />
                </Box> */}

                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                    fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'18px 0 2px'
                }}>
                    Ricevi una email ogni volta che un utente compila il form di contatto del tuo chatbot.
                </Typography>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginRight:{xs:'16px', sm:'20px'}
                    }}>Nuovo Contatto</Typography>
                    <Switch
                        {...register("notifications.contacts", {
                            required: false,
                            value: chatbot.notifications.contacts,
                            onChange:() => cambiaAltro({modifiche:true})
                        })}
                        defaultChecked={chatbot.notifications ? chatbot.notifications.contacts : false}
                    />
                </Box>

                <Box sx={{marginTop:'20px'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'400', lineHeight:'normal', margin:'2px 0 14px'
                    }}>
                        Modifica l'indirizzo email che riceverà le notifiche di segnalazione attivate. <br className='newline-no-mobile' />
                        Puoi anche lasciare vuoto questo campo per ricevere la segnalazioni sulla email principale del portale.
                    </Typography>
                    <AppTextField
                        name={'mailTo'}
                        isLabel={false}
                        width={'700px'}
                        placeholder={'Mail di notifica'}
                        error={errors.notifications?.mailTo?.message}
                        register={register("notifications.mailTo", {
                            required: false,
                            value:chatbot.notifications?.mailTo,
                            onChange:()=>cambiaAltro({modifiche:true})})}
                    ></AppTextField>
                </Box>
            </Box>
        </Box>
    );
}

export default NotificheChatbot;