import { remark } from 'remark';
import html from 'remark-html';
import gfm from 'remark-gfm';

const formatMoney = (number, currency = "EUR") => {
    return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: currency
    }).format(number);
}

const formatNumber = (number) => {
    return new Intl.NumberFormat("de-DE").format(number)
}

const isAdmin = (user) => {
    if(!user) return false
    return user.role === 'admin'
}

const validateEmail = (email) => {
    let emailCheck=/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
}

const validateTelefono = (telefono) => {
    let telefonoCheck=/^\d{10}$/;
    return telefonoCheck.test(telefono);
}

const codificaEmails = (content) => {
    let punteggiatura = false;
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    return content.replace(regex, function(match) {
        if(match[match.length - 1] == '.') {
            punteggiatura = true;
            match = match.slice(0, match.length - 1);
        } else punteggiatura = false;
        let tag = '<a href="mailto:' + match + '">' + match + '</a>';
        if(punteggiatura) tag += '.';
        return tag;
    });
}

const convertMarkdown = async(messaggio) => {
    const result = await remark().use(html).use(gfm).process(codificaEmails(messaggio.toString()));
    let htmlResult = result.toString();
    htmlResult = htmlResult.replace(/<a href="([^"]+)"/g, '<a href="$1" target="_blank"');
    return htmlResult;
}

const trimStringValues = (obj) => {
    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim();
        }
    }
    return obj;
}

const assemblaStringa = (vett) => {
    let result = ''
    for(let i = 0; i < vett.length - 1; i++) result += vett[i] + '\n'
    if(vett.length > 0) result += vett[vett.length - 1]
    return result
}

const assemblaOggetto = (oggetto) => {
    const result = {}
    for(let key of Object.keys(oggetto)) {
        result[key] = smontaStringa(oggetto[key])
    }
    return result;
}

const smontaStringa = (str) => {
    if(!str?.length) return []
    const vett = str.split('\n')
    const appo = []
    for(let i = 0; i < vett.length; i++) {
        if(vett[i].trim().length) appo.push(vett[i].trim())
    }
    return appo
}

const smontaOggetto = (oggetto) => {
    const result = {}
    for(let key of Object.keys(oggetto)) {
        result[key] = assemblaStringa(oggetto[key])
    }
    return result;
}

export default {  
    formatMoney, formatNumber, validateEmail, convertMarkdown, codificaEmails,
    validateTelefono, codificaEmails, trimStringValues, isAdmin,
    assemblaStringa, smontaStringa, smontaOggetto, assemblaOggetto
}