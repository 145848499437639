import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TutorialWP1 from '../../images/tutorial-wp-1.png';
import TutorialWP2 from '../../images/tutorial-wp-2.png';
import TutorialHtml1 from '../../images/tutorial-html-1.png';
import TutorialShopify1 from '../../images/tutorial-shopify-1.png';
import TutorialShopify2 from '../../images/tutorial-shopify-2.png';
import TutorialShopify3 from '../../images/tutorial-shopify-3.png';

function ZonaComeIntegrare({situazione}) {
    
    return (
        <Box sx={{width:'100%', height:'auto', padding:{xs:'28px 0 0', sm:'28px 0 0'}}}>
            <Box sx={{display:situazione === 0 ? 'block' : 'none'}}>
                <Typography sx={{textAlign:'left', letterSpacing:'normal', color:'black',
                    fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                }}>
                    1. Vai nella sezione <b>Plugin</b> e clicca su <b>aggiungi nuovo</b><br/>
                    2. Cerca il plugin <b>"Simple Custom CSS & JS"</b> e installalo
                </Typography>
                <Box sx={{margin:'20px 0'}}>
                    <Box component='img' alt='' src={TutorialWP1} sx={{width:'700px', maxWidth:'80%', height:'auto'}} />
                </Box>
                <Typography sx={{textAlign:'left', letterSpacing:'normal', color:'black',
                    fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                }}>
                    3. Cerca il plugin all'interno della <b>barra laterale</b> di Wordpress<br/>
                    4. Clicca su <b>"Add Custom HTML"</b> (punto 1)<br/>
                    5. Assegna il nome che preferisci al custom HTML (punto 2)<br/>
                    6. Nell'area di testo sotto al titolo <b>incolla lo script</b> del chatbot (punto 3)<br/>
                    7. Clicca su <b>"After body tag"</b> dalle opzioni all'interno della barra laterale a destra (punto 4)<br/>
                    8. Clicca il pulsante <b>Pubblica</b> (punto 5)
                </Typography>
                <Box sx={{margin:'20px 0'}}>
                    <Box component='img' alt='' src={TutorialWP2} sx={{
                        width:'1100px', maxWidth:{xs:'100%', md:'80%'}, height:'auto'
                    }} />
                </Box>
            </Box>

            <Box sx={{display:situazione === 1 ? 'block' : 'none'}}>
                <Typography sx={{textAlign:'left', letterSpacing:'normal', color:'black',
                    fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                }}>
                    1. Nella barra laterale a sinistra clicca sulla voce <b>"Themes"</b><br/>
                    2. Clicca sui 3 puntini ed in seguito clicca sulla voce <b>"Edit Code"</b>
                </Typography>
                <Box sx={{margin:'20px 0'}}>
                    <Box component='img' alt='' src={TutorialShopify1} sx={{
                        width:'1100px', maxWidth:{xs:'100%', md:'80%'}, height:'auto'
                    }} />
                </Box>
                <Typography sx={{textAlign:'left', letterSpacing:'normal', color:'black',
                    fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                }}>
                    3. Nella barra di ricerca a sinistra cerca <b>"theme.liquid"</b> e clicca sul file
                </Typography>
                <Box sx={{margin:'20px 0'}}>
                    <Box component='img' alt='' src={TutorialShopify2} sx={{
                        width:{xs:'500px', sm:'300px', md:'500px'}, maxWidth:'80%', height:'auto'
                    }} />
                </Box>
                <Typography sx={{textAlign:'left', letterSpacing:'normal', color:'black',
                    fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                }}>
                    4. All'interno del codice del file <b>cerca il tag &lt;/head&gt;</b> (puoi utilizzare <b>CTRL + F</b>)<br/>
                    5. Incolla lo script del Chatbot <b>prima della chiusura</b> del tag &lt;/head&gt; e salva le modifiche
                </Typography>
                <Box sx={{margin:'20px 0'}}>
                    <Box component='img' alt='' src={TutorialShopify3} sx={{
                        width:'1100px', maxWidth:{xs:'100%', md:'80%'}, height:'auto'
                    }} />
                </Box>
            </Box>

            <Box sx={{display:situazione === 2 ? 'block' : 'none'}}>
                <Typography sx={{textAlign:'left', letterSpacing:'normal', color:'black',
                    fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                }}>
                    1. Per aggiungere il widget di chat al tuo sito web, copia il frammento di codice e incollalo <b>prima della chiusura del tag &lt;/head&gt;</b>
                </Typography>
                <Box sx={{margin:'20px 0'}}>
                    <Box component='img' alt='' src={TutorialHtml1} sx={{
                        width:'1100px', maxWidth:{xs:'100%', md:'80%'}, height:'auto'
                    }} />
                </Box>
            </Box>
        </Box>
    );
}

export default ZonaComeIntegrare;