import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

function ModalCosaAggiungere({visible, chiudi}) {
    
    return (
        <Modal
            open={visible}
            closeAfterTransition
            keepMounted
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', 
                width:'100%', height:'100%', position:'relative', backgroundColor:'rgba(0, 0, 0, 0.2)'
            }} onClick={chiudi}>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'fit-content'}, backgroundColor:'white', 
                    borderRadius:'16px', border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    padding:{xs:'16px 12px', sm:'25px 30px'}, display:'flex', flexDirection:'column',
                    margin:{xs:'16px 12px', sm:0}, height:{xs:'fit-content', sm:'fit-content'}, 
                    overflowY:{xs:'scroll', sm:'auto'}
                }} onClick={(e) => e.stopPropagation()}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'19px', sm:'24px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Contenuti che puoi aggiungere</Typography>

                    <Box sx={{marginTop:{xs:'12px', sm:'30px'}}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'18px'}, fontWeight:'600', lineHeight:'normal'
                        }}>Assicurati che i tuoi file siano:</Typography>
                        <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, 
                            alignItems:{xs:'flex-start', sm:'flex-end'}, marginTop:'5px'
                        }}>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                                <CheckIcon sx={{color:'verde.main', width:'26px', height:'auto'}} />
                                <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'black', 
                                    lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'8px'},
                                    marginRight:'12px',
                                }}>Più piccoli di 52MB</Typography>
                            </Box>
                            <Typography style={{color:'#808080'}}>
                                (<Typography
                                    component='a' href='https://www.ilovepdf.com/compress_pdf' target='_blank'
                                    sx={{fontSize:'14px', fontWeight:'500', color:'#808080', cursor:'pointer', 
                                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left',
                                        textDecoration:'underline !important'
                                    }}
                                >aiutami a ridurre le dimensioni del mio file</Typography>)
                            </Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:{xs:'center', sm:'flex-end'}, marginTop:'5px'}}>
                            <CheckIcon sx={{color:'verde.main', width:'26px', height:'auto'}} />
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'8px'}
                            }}>Uno di questi formati</Typography>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'column', marginTop:{xs:'1px', sm:'5px'}, 
                            marginLeft:{xs:'40px', sm:'50px'}
                        }}>
                            <Typography sx={{fontSize:'14px', fontWeight:'400', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>.txt</Typography>
                            <Typography sx={{fontSize:'14px', fontWeight:'400', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>.pdf</Typography>
                            <Typography sx={{fontSize:'14px', fontWeight:'400', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>.doc o .docx</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:{xs:'center', sm:'flex-end'}, marginTop:'25px'}}>
                            <ClearIcon sx={{color:'#FC2A1B', width:'26px', height:'auto'}} />
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'8px'}
                            }}>No file scritti a mano o scannerizati</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:{xs:'center', sm:'flex-end'}, marginTop:'5px'}}>
                            <ClearIcon sx={{color:'#FC2A1B', width:'26px', height:'auto'}} />
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'8px'}
                            }}>No file potetti da password</Typography>
                        </Box>
                    </Box>

                    <Box sx={{marginTop:'30px'}}>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'18px'}, fontWeight:'600', lineHeight:'normal'
                        }}>Assicurati che i tuoi link siano:</Typography>
                        <Box sx={{display:'flex', alignItems:{xs:'center', sm:'flex-end'}, marginTop:'5px'}}>
                            <CheckIcon sx={{color:'verde.main', width:'26px', height:'auto'}} />
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'8px'}
                            }}>Per un sito web con contenuti testuali</Typography>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'column', marginTop:{xs:'-1px', sm:'5px'}, 
                            marginLeft:{xs:'40px', sm:'50px'}
                        }}>
                            <Typography sx={{fontSize:{xs:'12px', sm:'14px'}, fontWeight:'400', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>(le immagini e i file verranno ignorati)</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:{xs:'center', sm:'flex-end'}, marginTop:'5px'}}>
                            <CheckIcon sx={{color:'verde.main', width:'26px', height:'auto'}} />
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'8px'}
                            }}>Per le pagine web specifiche da cui si desidera ottenere il contenuto</Typography>
                        </Box>
                        <Box sx={{display:'flex', flexDirection:'column', marginTop:{xs:'-1px', sm:'5px'}, 
                            marginLeft:{xs:'40px', sm:'50px'}
                        }}>
                            <Typography sx={{fontSize:{xs:'12px', sm:'14px'}, fontWeight:'400', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>(non ci interessando tutte le pagine del sito, ad esempio)</Typography>
                        </Box>
                        <Box sx={{display:'flex', alignItems:{xs:'center', sm:'flex-end'}, marginTop:'25px'}}>
                            <ClearIcon sx={{color:'#FC2A1B', width:'26px', height:'auto'}} />
                            <Typography sx={{fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', color:'black', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'4px', sm:'8px'}
                            }}>Non richiedono un login o una password per accedere</Typography>
                        </Box>
                    </Box>

                    <Box sx={{marginTop:'30px', width:'100%', display:'flex', justifyContent:'center'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{padding:'8px 36px', borderRadius:'12px', textTransform:'none', border:'1px solid #dcdcdc'}}
                            disableElevation={true}
                            onClick={chiudi}
                        >
                            <Typography sx={{fontSize:'16px', fontWeight:'500', 
                                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                            }}>Ho capito!</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalCosaAggiungere;