import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

function LoadingPaginaSettings({situazione}) {

    return (
        <Box sx={{
            position:'relative', width:'100%', marginTop:'40px'
        }}>
            <Stack direction='row' spacing={{xs:'10px', lg:'85px'}} sx={{
                width:'60%', alignItems:'center', justifyContent:'space-between', 
                backgroundColor:'transparent', marginTop:{xs:0, sm:'20px'},
                display:{xs:'none', sm:'flex'}
            }}>
                <Skeleton variant="rounded" width={'180px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'180px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'180px'} height={'40px'} animation="wave" />
                <Skeleton variant="rounded" width={'180px'} height={'40px'} animation="wave" />
            </Stack>

            <Box sx={{marginTop:{xs:'-10px', sm:'70px'}}}>
                <Skeleton variant="rounded" width={'220px'} animation="wave" sx={{
                    height:{xs:'20px', sm:'50px'}
                }} />
            </Box>
            
            {situazione === 1 && <> 
                <Stack direction={{xs:'column', sm:'row'}} spacing={{xs:'6px', sm:'200px'}} sx={{marginTop:{xs:'25px', sm:'45px'}}}>
                    <Skeleton variant="rounded" width={'180px'} height={'30px'} animation="wave" />
                    <Skeleton variant="rounded" width={'400px'} height={'50px'} animation="wave" />
                </Stack>
                <Stack direction={{xs:'column', sm:'row'}} spacing={{xs:'6px', sm:'200px'}} sx={{marginTop:{xs:'25px', sm:'35px'}}}>
                    <Skeleton variant="rounded" width={'180px'} height={'30px'} animation="wave" />
                    <Skeleton variant="rounded" width={'400px'} height={'50px'} animation="wave" />
                </Stack>
                <Stack direction={{xs:'column', sm:'row'}} spacing={{xs:'6px', sm:'200px'}} sx={{marginTop:{xs:'25px', sm:'35px'}}}>
                    <Skeleton variant="rounded" width={'180px'} height={'30px'} animation="wave" />
                    <Skeleton variant="rounded" width={'400px'} height={'50px'} animation="wave" />
                </Stack>
                <Stack direction={{xs:'column', sm:'row'}} spacing={{xs:'6px', sm:'200px'}} sx={{marginTop:{xs:'25px', sm:'35px'}}}>
                    <Skeleton variant="rounded" width={'180px'} height={'30px'} animation="wave" />
                    <Skeleton variant="rounded" width={'400px'} height={'50px'} animation="wave" />
                </Stack>
            </>}
        </Box>
    );
}

export default LoadingPaginaSettings;