import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';

import RigaBotUtente from './RigaBotUtente';

const AppMenu = styled(Menu)({
    '& .MuiPaper-root': {
        backgroundColor:'white',
        borderRadius:'12px',
        border:'1px solid white'
    }
});

const Backdrop = styled('div')({
    position: 'fixed', top: 0,
    right: 0, bottom: 0, left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

function MenuVediChatbot({anchorVariabili, open, onClose, chatbots, list}) {

    const filtraBot = useCallback(() => {
        return chatbots.filter((bot) => {
            return list.includes(bot.id)
        })
    }, [chatbots, list])

    return (<>
        <Backdrop style={{display:open ? 'block' : 'none', zIndex:3000}} onClick={onClose} />
        <AppMenu
            anchorEl={anchorVariabili}
            open={open}
            onClose={onClose}
            TransitionComponent={Fade}
            hideBackdrop={false}
            sx={{marginTop:'10px', zIndex:4000, backgroundColor:'transparent'}}
            anchorOrigin={{vertical:"bottom", horizontal:"left"}}
            disableScrollLock={true}
        >
            <Box sx={{width:'300px', maxHeight:'300px', borderRadius:'12px', margin:'-8px 0',
                backgroundColor:'white', border:'0px solid #dcdcdc', overflow:'auto'
            }} className='stats-messages'>
                {filtraBot().map((chatbot, index) => (
                    <RigaBotUtente
                        key={index}
                        isVedi={true}
                        chatbot={chatbot}
                        selezionato={false}
                        premiChatbot={() => null}
                        isUltimo={index === filtraBot().length - 1}
                    ></RigaBotUtente>
                ))}
            </Box>
        </AppMenu>
    </>);
}

export default MenuVediChatbot;