import React, { useState, useEffect, useRef } from 'react';
import copy from 'copy-to-clipboard';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ZonaCodice from './ZonaCodice';
import AppAlert from '../generale/AppAlert';
import ZonaComeIntegrare from './ZonaComeIntegrare';
import useAgency from '../../controllers/agency/useAgency';

import IconaHtml from '../../images/html.png';
import IconaShopify from '../../images/shopify.png';
import IconaWordpress from '../../images/wordpress.png';

function ZonaCondividi({visible, code, isDomain, status, premiDominio, clearStatus}) {

    const bollaRef = useRef();
    const {agency} = useAgency();

    const [situazione, setSituazione] = useState(0)
    const [openAlert, setOpenAlert] = useState(false)

    useEffect(() => {
        if(!visible) setTimeout(() => {
            chiudiAlert();
        }, 1000);
    }, [visible])

    useEffect(() => {
        const aggStatus = () => {
            if(status === 'wordpress') {
                setSituazione(0)
                bollaRef.current?.scrollIntoView();
            }
            if(status === 'shopify') {
                setSituazione(1)
                bollaRef.current?.scrollIntoView();
            }
            clearStatus()
        }
        if(status) aggStatus()
    }, [status])

    const scriviLink = () => {
        return 'https://' + agency.domain + '/chatbot-iframe/' + code
    }

    const scriviScript = () => {
        const name = agency.name.toLowerCase().replaceAll(' ', '-')
        return '<script id="' + name + '-script" src="https://cdn.supportfast.ai/chatbot.js"></script>'
    }

    const scriviIframe = () => {
        return '<iframe ' +
        'src="https://' + agency.domain + '/chatbot-iframe/' + code +
        '" width="100%" height="100%" frameborder="0"></iframe>'
    }

    const doCopia = (text) => {
        copy(text)
        setOpenAlert(true)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setOpenAlert(false)
    }

    return (
        <Box sx={{position:'relative'}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'title.main',
                fontSize:'18px', fontWeight:'600', lineHeight:'normal', margin:'20px 0 2px 4px',
                display:{xs:visible ? 'block' : 'none', sm:'none'}
            }}>Condividi</Typography>
        
            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:0, sm:'70px 0 0', lg:'40px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'block' : 'none'
            }}>
                <Box sx={{padding:{xs:'12px', sm:'30px'}, borderBottom:'0px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Condividi il link del tuo Chatbot</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#808080',
                        fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                    }}>
                        Utilizza la chat come se fosse un sito web. 
                        <b> Salva e condividi il tuo link</b> per utilizzare la chat in qualsiasi momento.
                    </Typography>
                    <ZonaCodice
                        codice={scriviLink()}
                        copia={() => doCopia(scriviLink())}
                    ></ZonaCodice>
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:0, sm:'40px 0 0', lg:'40px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'block' : 'none'
            }} ref={bollaRef}>
                <Box sx={{padding:{xs:'12px', sm:'30px'}, borderBottom:'0px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>iFrame</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#808080',
                        fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal'
                    }}>
                        Inserisci il tuo agente in una pagina del tuo sito web. 
                        Per inserire il tuo bot, <b>incolla il seguente codice</b> in qualsiasi punto nella tua pagina.
                    </Typography>
                    <ZonaCodice
                        codice={scriviIframe()}
                        copia={() => doCopia(scriviIframe())}
                    ></ZonaCodice>
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'20px', 
                margin:{xs:'16px 0 0', sm:'40px 0 0', lg:'40px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'block' : 'none'
            }}>
                <Box sx={{padding:{xs:'12px', sm:'30px'}, borderBottom:'0px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Bottone Chat</Typography>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#808080',
                        fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                    }}>
                        Integra il tuo agente nel tuo sito web o piattaforma sotto forma di bottone in ogni pagina. 
                        Per inserire il tuo bot, <b>incolla il seguente codice prima del tag BODY</b> del tuo sito.
                    </Typography>
                    {!isDomain &&
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#FC2A1B',
                            fontSize:{xs:'15px', sm:'18px'}, fontWeight:'500', lineHeight:'normal', 
                            marginTop:{xs:'6px', sm:'15px'}
                        }}>
                            Attenzione: dominio non configurato.&nbsp;
                            <span style={{color:'black'}}>
                                <span onClick={premiDominio} style={{
                                    fontWeight:'500', textDecoration:'underline', cursor:'pointer'
                                }}>Clicca qui</span>
                                &nbsp;per configurare il dominio.
                            </span>
                        </Typography>
                    }
                    <ZonaCodice
                        codice={scriviScript()}
                        copia={() => doCopia(scriviScript())}
                    ></ZonaCodice>
                </Box>

                <Box sx={{padding:{xs:'12px', sm:'10px 30px 30px'}, borderBottom:'0px solid #dcdcdc'}}>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'15px', sm:'18px'}, fontWeight:'600', lineHeight:'normal'
                    }}>Come integrare il Bottone Chat</Typography>
                    <Stack direction='row' spacing={{xs:'8px', sm:'24px'}} sx={{marginTop:{xs:'8px', sm:'8px'}}}>
                        <Button
                            color={situazione == 0 ? 'title' : 'grigioScuro'}
                            variant="outlined"
                            sx={{padding:'8px 20px', borderRadius:'12px', textTransform: 'none', flex:{xs:1, sm:'none'},
                                borderWidth:situazione == 0 ? '3px' : '1px', '&:hover':{borderWidth:situazione == 0 ? '3px' : '1px'}
                            }}
                            disableElevation={true}
                            startIcon={<Box component='img' alt='' src={IconaWordpress} sx={{width:'auto', height:'18px'}} />}
                            onClick={() => setSituazione(0)}
                        >
                            <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'-2px', sm:'2px'}
                            }}>Wordpress</Typography>
                        </Button>
                        <Button
                            color={situazione == 1 ? 'title' : 'grigioScuro'}
                            variant="outlined"
                            sx={{padding:'8px 20px', borderRadius:'12px', textTransform: 'none', flex:{xs:1, sm:'none'},
                                borderWidth:situazione == 1 ? '3px' : '1px', '&:hover':{borderWidth:situazione == 1 ? '3px' : '1px'}
                            }}
                            disableElevation={true}
                            startIcon={<Box component='img' alt='' src={IconaShopify} sx={{width:'auto', height:'18px'}} />}
                            onClick={() => setSituazione(1)}
                        >
                            <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'-2px', sm:'2px'}
                            }}>Shopify</Typography>
                        </Button>
                        <Button
                            color={situazione == 2 ? 'title' : 'grigioScuro'}
                            variant="outlined"
                            sx={{padding:'8px 20px', borderRadius:'12px', textTransform: 'none', flex:{xs:1, sm:'none'},
                                borderWidth:situazione == 2 ? '3px' : '1px', '&:hover':{borderWidth:situazione == 2 ? '3px' : '1px'}
                            }}
                            disableElevation={true}
                            startIcon={<Box component='img' alt='' src={IconaHtml} sx={{width:'auto', height:'18px'}} />}
                            onClick={() => setSituazione(2)}
                        >
                            <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, fontWeight:'500', lineHeight:'normal', 
                                letterSpacing:'-0.2', textAlign:'left', marginLeft:{xs:'-2px', sm:'2px'}
                            }}>Standard (HTML)</Typography>
                        </Button>
                    </Stack>

                    <ZonaComeIntegrare
                        situazione={situazione}
                    ></ZonaComeIntegrare>
                </Box>
            </Box>

            <AppAlert
                open={openAlert}
                chiudiAlert={chiudiAlert}
                severity={"success"}
                message={'Copiato negli appunti!'}
            ></AppAlert>
        </Box>
    );
}

export default ZonaCondividi;