import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function ZonaChat({visible, chatbot}) {

    const [random, setRandom] = useState(0)
    const [random2, setRandom2] = useState(0)

    useEffect(() => {
        const aggRandom = () => setRandom(random + 1)
        aggRandom()
    }, [chatbot])

    useEffect(() => {
        const aggRandom2 = () => setRandom2(random2 + 1)
        aggRandom2()
    }, [chatbot, visible])

    const scriviLink = () => {
        let link = window.location.protocol + '//'
        link += window.location.host + '/chatbot-iframe/' + chatbot.code
        link += '?isBackend=true'
        return link
    }

    return (
        <Box sx={{position:'relative'}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'title.main',
                fontSize:'18px', fontWeight:'600', lineHeight:'normal', margin:{xs:'20px 0 2px 4px', sm:0},
                display:{xs:visible ? 'block' : 'none', md:'none'}, visibility:{xs:'visible', sm:'hidden'}
            }}>Chat</Typography>
            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, padding:0,
                margin:{xs:'20px 0 12px', sm:'40px 0 0', lg:'40px 40px 0'}, position:'relative',
                height:{xs:'1200px', sm:'1300px', md:'1125px', xl:'1225px'}, display: {xs:'none', md:visible ? 'block' : 'none'}
            }}>
                <iframe
                    key={'iframe' + random}
                    width="100%" 
                    height="100%"
                    style={{border:'none'}}
                    src={scriviLink()}
                ></iframe>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, padding:0, 
                margin:{xs:'0 0 12px', sm:'48px 0 0', lg:'40px 40px 0'}, position:'relative',
                height:{xs:'1200px', sm:'800px', md:'600px', xl:'700px'}, display: {xs:visible ? 'block' : 'none', md:'none'}
            }}>
                <iframe
                    key={'iframe' + random2}
                    width="100%" 
                    height="100%"
                    style={{border:'none'}}
                    src={scriviLink()}
                ></iframe>
            </Box>
        </Box>
    );
}

export default ZonaChat;