import React, {useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';

import ItemMenuChatbot from '../chatbots/ItemMenuChatbot';

const AppMenu = styled(Menu)({
    '& .MuiPaper-root': {
        backgroundColor:'white',
        borderRadius:'12px',
        border:'1px solid #dcdcdc',
        boxShadow:'none'
    }
})

function MenuSettings({situazione, isAdmin, cambiaSituazione, anchorEl, open, onClose}) {

    useEffect(() => {
        setTimeout(() => {
            onClose()
        }, 50);
    }, [situazione])

    if(isAdmin) return (
        <React.Fragment>
            <Stack direction='row' spacing='85px' sx={{display:{xs:'none', sm:'flex'},
                width:isAdmin ? '25%' : '20%', alignItems:'center', justifyContent:'space-between', 
                backgroundColor:'transparent', marginTop:'20px', marginBottom:'-12px'
            }}>
                <ItemMenuChatbot
                    text={'Profilo'}
                    active={situazione === 1}
                    onClick={() => cambiaSituazione(1)}
                ></ItemMenuChatbot>
                {isAdmin && <ItemMenuChatbot
                    text={'Gestione Utenti'}
                    active={situazione === 2}
                    onClick={() => cambiaSituazione(2)}
                ></ItemMenuChatbot>}
            </Stack>

            <AppMenu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                disableScrollLock={true}
                sx={{p:0, m:0}}
            >
                <Stack direction='column' spacing='0' sx={{minWidth:'160px', p:0, margin:'-8px 0'}}>
                <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Profilo'}
                            active={situazione === 1}
                            onClick={() => cambiaSituazione(1)}
                        ></ItemMenuChatbot>
                    </Box>
                    {isAdmin && <Box sx={{p:'6px 12px 5px', borderBottom:'1px solid #dcdcdc', display:'flex', 
                        alignItems:'center', justifyContent:'center'
                    }}>
                        <ItemMenuChatbot
                            text={'Gestione Utenti'}
                            active={situazione === 2}
                            onClick={() => cambiaSituazione(2)}
                        ></ItemMenuChatbot>
                    </Box>}
                </Stack>
            </AppMenu>
        </React.Fragment>
    );
}

export default MenuSettings;