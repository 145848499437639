import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import utils from '../../libs/utils';

import ArticleIcon from '@mui/icons-material/Article';
import LanguageIcon from '@mui/icons-material/Language';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function IntestazioneRetrain({training, goBack}) {

    const scriviTipo = () => {
        if(training.trainingType == 'sitoweb') return 'SITO WEB'
        if(training.trainingType == 'domande') return 'Q/A'
        if(training.trainingType == 'documents') return 'DOCUMENTI'
        if(training.trainingType == 'excel') return 'DOCUMENTI'
        if(training.trainingType == 'word') return 'DOCUMENTI'
        if(training.trainingType == 'pdf') return 'DOCUMENTI'
        return 'TESTO'
    }

    const scriviSottotitolo = () => {
        let appo = training.trainingType == 'testo' ? '' : training.references.length
        if(training.trainingType == 'sitoweb') appo += ' link - '
        if(training.trainingType == 'pdf') appo += ' file - '
        if(training.trainingType == 'documents') appo += ' file - '
        if(training.trainingType == 'domande') appo += ' domande - '
        if(training.trainingType == 'word') appo += ' file - '
        if(training.trainingType == 'excel') appo += ' file - '
        appo += moment(training.updatedAt).format('DD/MM/YYYY HH:mm');
        return appo
    }

    const scriviIcona = () => {
        if(training.trainingType == 'sitoweb') return <LanguageIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='black' />
        if(training.trainingType == 'domande') return <QuestionAnswerIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='black' />
        if(training.trainingType == 'excel') return <ArticleIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='black' />
        if(training.trainingType == 'word') return <ArticleIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='black' />
        if(training.trainingType == 'pdf') return <ArticleIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='black' />
        if(training.trainingType == 'documents') return <ArticleIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='black' />
        return <ModeEditIcon sx={{width:{xs:'20px', sm:'25px'}, height:'auto'}} color='black' />
    }

    return (<>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', marginRight:'-5px'}}>
            <Stack direction='row' spacing={{xs:'14px', sm:'30px'}} sx={{alignItems:'center', zIndex:200, width:'100%', position:'relative'}}>
                <KeyboardBackspaceIcon
                    onClick={goBack}
                    sx={{width:{xs:'38px', sm:'50px'}, height:'auto', cursor:'pointer', zIndex:200, color:'black'}}
                />
                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                    fontSize:{xs:'21px', sm:'32px'}, fontWeight:'700', lineHeight:'1.1', zIndex:200
                }}>Modifica Allenamento {scriviTipo()}</Typography>
            </Stack>
        </Box>

        <Box sx={{width:'100%', display:'flex', alignItems:'center', marginTop:{xs:'16px', sm:'24px'}}}>
            <Box sx={{paddingLeft:{xs:0, sm:'10px'}, width:'100%'}}>
                <Box sx={{display:'flex', alignItems:'center', width:'100%'}}>
                    {scriviIcona()}
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black', marginLeft:'6px',
                        fontSize:{xs:'18px', sm:'22px'}, fontWeight:'500', lineHeight:'1.1', zIndex:200,
                        maxWidth:{xs:'100%', md:'70%'}, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'
                    }}>Allenamento {training.name}</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', paddingTop:{xs:0, sm:'2px'}}}>
                    <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, fontWeight:'600', color:'#808080', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
                    }}>({scriviSottotitolo()})</Typography>
                    <Typography sx={{fontSize:{xs:'13px', sm:'15px'}, fontWeight:'600', color:'#808080', 
                        lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left', 
                        marginLeft:{xs:'8px', sm:'16px'}
                    }}>({utils.formatNumber(training.characters)} caratteri)</Typography>
                </Box>
            </Box>
        </Box>
    </>);
}

export default IntestazioneRetrain;