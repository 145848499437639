import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AppRadio from '../generale/AppRadio';
import RigaBotUtente from './RigaBotUtente';
import AppTextField from '../generale/AppTextField';

function ModalCreaUtente({
    visible, chiudi, confermaCreazione, confermaModifiche, loading, chatbots, selezionato
}) {

    const [bots, setBots] = useState([])
    const [role, setRole] = useState('editor')

    const schema = yup.object().shape({
        fullName: yup.string().required('Il nome è obbligatorio.'),
        email: yup.string().email('La mail deve essere una email valida').required('La email è obbligatoria'),
        password: selezionato ? yup.string() : yup.string().min(8, 'La password deve avere almeno 8 caratteri.').required('La password è obbligatoria.'),
    });
    const {register, handleSubmit, setValue, clearErrors, formState: { errors }} = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const aggDati = () => {
            clearErrors()
            setBots(selezionato ? selezionato.chatbots : [])
            setRole(selezionato ? selezionato.role : 'editor')
            setValue('email', selezionato ? selezionato.email : '')
            setValue('fullName', selezionato ? selezionato.fullName : '')
            setValue('password', '')
        }
        aggDati()
    }, [selezionato])

    const clickConferma = async(values) => {
        values.role = role;
        values.chatbots = bots;
        if(selezionato) return await confermaModifiche(values)
        await confermaCreazione(values)
    }

    const isSelected = useCallback((id) => {
        return bots.includes(id);
    }, [bots])

    const isTutti = useCallback(() => {
        return bots[0] == '*';
    }, [bots, chatbots])

    const switchChatbot = (id) => {
        if(isTutti()) {
            const appo = chatbots.map((el) => el.id);
            setBots(appo.filter((el) => el != id && el != '*'));
            return;
        }
        if(isSelected(id)) {
            const appo = [...bots];
            setBots(appo.filter((el) => el != id && el != '*'));
        } else {
            const appo = [...bots];
            appo.push(id);
            setBots(appo.filter((el) => el != '*'));
        }
    }

    const premiTutti = () => {
        if(isTutti()) setBots([])
        else setBots(['*'])
    }

    return (
        <Modal
            open={visible}
            closeAfterTransition
            keepMounted
        >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', 
                width:'100%', height:'100%', position:'relative', backgroundColor:'rgba(0, 0, 0, 0.2)'
            }} onClick={chiudi}>
                <Box sx={{width:{xs:'calc(100% - 24px)', sm:'fit-content'}, backgroundColor:'white', borderRadius:'16px',
                    padding:{xs:'24px 12px', sm:'24px 36px'}, display:'flex', flexDirection:'column',
                    border:'1px solid #dcdcdc', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2)', margin:{xs:'0 12px', sm:'0 100px'},
                    maxHeight:'80vh', overflowY:'auto'
                }} onClick={(e) => e.stopPropagation()}>

                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'19px', sm:'24px'}, fontWeight:'600', lineHeight:'normal'
                    }}>{selezionato ? 'Modifica Utente' : 'Aggiungi Nuovo Utente'}</Typography>
                    {selezionato && <span>{selezionato.email}</span>}

                    <Box
                        component='form'
                        id="fatturazione-form"
                        name="fatturazione-form"
                        data-name="Fatturazione Form"
                        onSubmit={handleSubmit(clickConferma)}
                        sx={{display:'flex', flexDirection:{xs:'column', md:'row'}}}
                    >
                        <Box sx={{width:{xs:'100%', sm:'470px'}}}>
                            <Box sx={{padding:{xs:'10px 0', sm:'24px 0 0'}}}>
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'2px', sm:'6px'}
                                }}>Nome Completo</Typography>
                                <AppTextField
                                    isBianco={true}
                                    name={'fullName'}
                                    width={'100%'}
                                    isLabel={false}
                                    error={errors.fullName?.message}
                                    placeholder={'Nome Completo'}
                                    register={register("fullName", {required: false, onChange:() => null})}
                                ></AppTextField>
                            </Box>
                            {!selezionato && <Box sx={{padding:{xs:'10px 0', sm:'16px 0 0'}}}>
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'2px', sm:'6px'}
                                }}>Email</Typography>
                                <AppTextField
                                    isBianco={true}
                                    name={'email'}
                                    width={'100%'}
                                    isLabel={false}
                                    error={errors.email?.message}
                                    placeholder={'Email'}
                                    register={register("email", {required: false, onChange:() => null})}
                                ></AppTextField>
                            </Box>}
                            <Box sx={{padding:{xs:'10px 0', sm:'16px 0 0'}}}>
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal',
                                    marginBottom:selezionato ? 0 : {xs:'2px', sm:'6px'}
                                }}>Password</Typography>
                                {selezionato && <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'#696969',
                                    fontSize:{xs:'12px', sm:'14px'}, fontWeight:'400', lineHeight:'normal', marginBottom:{xs:'2px', sm:'6px'}
                                }}>Lasciare il campo vuoto se NON si desidera aggiornare la password.</Typography>}
                                <AppTextField
                                    isBianco={true}
                                    name={'password'}
                                    width={'100%'}
                                    isLabel={false}
                                    isPassword={true}
                                    error={errors.password?.message}
                                    placeholder={'Password'}
                                    register={register("password", {required: false, onChange:() => null})}
                                ></AppTextField>
                            </Box>
                            <Box sx={{padding:{xs:'10px 0', sm:'20px 0 0'}}}>
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal'
                                }}>Ruolo</Typography>
                                <Box sx={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                                    <AppRadio
                                        text={"Editor"}
                                        value={role === 'editor'}
                                        onChange={() => setRole('editor')}
                                    ></AppRadio>
                                    <Box sx={{marginLeft:{xs:'32px', sm:'64px'}, display:'block'}} />
                                    <AppRadio
                                        text={"Reader"}
                                        value={role === 'reader'}
                                        onChange={() => setRole('reader')}
                                    ></AppRadio>
                                </Box>
                            </Box>

                            <Stack direction='row' spacing='32px' sx={{
                                paddingTop:{xs:'20px', sm:'30px'}, display:{xs:'none', sm:'flex'}
                            }}>
                                <LoadingButton
                                    type='submit'
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        border:'1px solid #dcdcdc', width:'100%',
                                        padding:{xs:'8px 24px', sm:'12px 40px'},
                                        borderRadius:'12px', textTransform: 'none'
                                    }}
                                    disableElevation={true}
                                    loading={loading}
                                    loadingPosition={'center'}
                                >
                                    <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'},
                                        fontWeight:'500', letterSpacing:'-0.2', textAlign:'left'
                                    }}>Conferma</Typography>
                                </LoadingButton>
                                <Button
                                    color="nero"
                                    variant="contained"
                                    sx={{
                                        border:'1px solid #dcdcdc', width:'100%', 
                                        padding:{xs:'8px 24px', sm:'12px 40px'}, 
                                        borderRadius:'12px', textTransform: 'none'
                                    }}
                                    disableElevation={true}
                                    onClick={chiudi}
                                >
                                    <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'},
                                        fontWeight:'500', letterSpacing:'-0.2', textAlign:'left'
                                    }}>Annulla</Typography>
                                </Button>
                            </Stack>
                        </Box>

                        <Box sx={{width:{xs:'100%', sm:'400px'}, marginLeft:{xs:0, sm:'64px'}, padding:{xs:'0 0 10px', sm:'24px 0 0'}}}>
                            <Box sx={{width:'100%', display:'flex', alignItems:'flex-end', justifyContent:'space-between'}}>
                                <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                                    fontSize:{xs:'14px', sm:'16px'}, fontWeight:'600', lineHeight:'normal', marginBottom:{xs:'6px', sm:'6px'}
                                }}>Chatbot</Typography>
                                <Box sx={{display:'flex', alignItems:'center'}}>
                                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black', marginRight:'-3px',
                                        fontSize:{xs:'13px', sm:'15px'}, fontWeight:'600', lineHeight:'normal'
                                    }}>TUTTI</Typography>
                                    <AppRadio
                                        text={null}
                                        value={isTutti()}
                                        onChange={premiTutti}
                                    ></AppRadio>
                                </Box>
                            </Box>
                            <Box sx={{width:'400px', maxHeight:'300px', borderRadius:'12px', marginTop:'-4px',
                                backgroundColor:'white', border:'1px solid #dcdcdc', overflow:'auto'
                            }} className='stats-messages'>
                                {chatbots.map((chatbot, index) => (
                                    <RigaBotUtente
                                        key={index}
                                        chatbot={chatbot}
                                        selezionato={isSelected(chatbot.id) || isTutti()}
                                        premiChatbot={() => switchChatbot(chatbot.id)}
                                        isUltimo={index === chatbots.length - 1}
                                    ></RigaBotUtente>
                                ))}
                            </Box>
                        </Box>

                        <Stack direction='row' spacing='32px' sx={{
                            marginTop:{xs:'20px', sm:'30px'}, display:{xs:'flex', sm:'none'}
                        }}>
                            <LoadingButton
                                type='submit'
                                color="primary"
                                variant="contained"
                                sx={{
                                    border:'1px solid #dcdcdc', width:'100%',
                                    padding:{xs:'8px 24px', sm:'12px 40px'},
                                    borderRadius:'12px', textTransform: 'none'
                                }}
                                disableElevation={true}
                                loading={loading}
                                loadingPosition={'center'}
                            >
                                <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'},
                                    fontWeight:'500', letterSpacing:'-0.2', textAlign:'left'
                                }}>Conferma</Typography>
                            </LoadingButton>
                            <Button
                                color="nero"
                                variant="contained"
                                sx={{
                                    border:'1px solid #dcdcdc', width:'100%', 
                                    padding:{xs:'8px 24px', sm:'12px 40px'}, 
                                    borderRadius:'12px', textTransform: 'none'
                                }}
                                disableElevation={true}
                                onClick={chiudi}
                            >
                                <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'},
                                    fontWeight:'500', letterSpacing:'-0.2', textAlign:'left'
                                }}>Annulla</Typography>
                            </Button>
                        </Stack>
                    </Box>

                </Box>
            </Box>
        </Modal>
    );
}

export default ModalCreaUtente;