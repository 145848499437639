import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import { API_URL } from "../config";
import widgetsApi from '../api/widgets';
import conversationApi from '../api/conversation';
import CorpoChatFrame from '../components/chat/CorpoChatFrame';
import HeaderChatFrame from '../components/chat/HeaderChatFrame';
import FooterChatFrame from '../components/chat/FooterChatFrame';
import ContenutiChatFrame from '../components/chat/ContenutiChatFrame';

function ChatFrame(props) {

    const { t } = useTranslation()
    const {pathname, search} = useLocation()

    const [msg, setMsg] = useState('')
    const [lang, setLang] = useState(null)
    const [height, setHeight] = useState(0)
    const [contents, setContents] = useState([])
    const [source, setSource] = useState('iframe')
    const [chatbot, setChatbot] = useState(null)
    const [conversation, setConversation] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [contactFormData, setContactFormData] = useState({
        loading:false, errore:null, send:false, chiudi:false, operator:false
    })
    const [altro, setAltro] = useState({
        errore:null, loading:false, caricamento:false,
        onboardingStatus:false, privacyPolicy:false
    })

    const isDark = chatbot?.theme === 1;
    const isBackend = source == 'backend';
    const PRIVACY_KEY = 'supportfast-agency-privacy-policy-' + chatbot?.id;
    const MSG_KEY = 'supportfast-agency-conversation-' + chatbot?.id + (isBackend ? '-backend' : '');

    useEffect(() => {
        const aggHeight = () => setHeight(window.innerHeight);
        aggHeight();
    }, [])

    useEffect(() => {
        const aggChatbot = async() => {
            //ottengo il codice del chatbot
            const vett = pathname.split('/');
            const chatbotName = vett[vett.length - 1];
            if(!chatbotName) return setAltro({...altro, errore:t('alert.botNonDisp'), caricamento:true});
            //scarico i dati dalle API
            const appoSearch = new URLSearchParams(search);
            const result = await widgetsApi.getChatbotByCode(chatbotName, Boolean(appoSearch.get('isBackend')));
            if(!result?.ok) return setAltro({...altro, errore:t(result.data.message), caricamento:true});
            if(result.data.subscription.paymentFailed) return setAltro({
                ...altro, errore:t('alert.abbNonValidoFrame'), caricamento:true
            });
            if(!result.data.chatbot.enabled) return setAltro({
                ...altro, errore:t('alert.botDisabilitato2'), caricamento:true
            });
            const theChatbot = result.data.chatbot;
            //gestione lingua
            const navLang = navigator.language.split('-')[0];
            const theLang = theChatbot.languages.includes(navLang) ? navLang
                : (theChatbot.languages.includes('en') ? 'en' : theChatbot.languages[0]);
            setLang(theLang);
            //aggiorno bot e subscription
            setChatbot(result.data.chatbot);
            setSubscription(result.data.subscription);
            //aggiorno loading a altro
            setAltro({
                ...altro, caricamento:true, errore:null, privacyPolicy:false, 
                onboardingStatus: result.data.onboardingStatus
            });
        }
        if(pathname) aggChatbot()
    }, [pathname])

    useEffect(() => {
        const aggSource = () => {
            const appo = new URLSearchParams(search);
            if(Boolean(appo.get('isBackend'))) setSource('backend');
        }
        aggSource();
    }, [search])

    useEffect(() => {
        const aggPrivacyPolicy = () => {
            if(chatbot.privacyPolicy[lang]?.length) {
                let privacy = false;
                let cookie = localStorage.getItem(PRIVACY_KEY);
                if(!cookie) privacy = true;
                else {
                    cookie = JSON.parse(cookie);
                    const now = new Date();
                    now.setMonth(now.getMonth() - 1);
                    if(now.getTime() > new Date(cookie.date).getTime()) privacy = true;
                }
                setAltro({...altro, privacyPolicy:privacy});
            }
        }
        if(chatbot && lang) aggPrivacyPolicy();
    }, [chatbot, lang])

    useEffect(() => {
        const loadConversation = async() => {
            try {
                //leggo la chache
                let data = localStorage.getItem(MSG_KEY);
                if(!data) return localStorage.removeItem(MSG_KEY);
                if(!data.length) return localStorage.removeItem(MSG_KEY);
                data = JSON.parse(data);
                //aggiorno la conversazione
                const result = await conversationApi.getConversation(data.id);
                if(!result.ok) {
                    if(result.status != 404) return;
                    return localStorage.removeItem(MSG_KEY);
                }
                setConversation(result.data);
            } catch(err) {
                localStorage.removeItem(MSG_KEY);
            }
        }
        if(chatbot) loadConversation()
    }, [chatbot])

    useEffect(() => {
        // titolo della pagina
        document.title = chatbot?.displayName || 'Chatbot';
        //favicon
        const favicon = document.getElementById("favicon");
        if(chatbot?.buttonPhoto?.length > 0) favicon.href = chatbot?.buttonPhoto;
    }, [chatbot]);

    useEffect(() => {
        const aggCache = () => {
            localStorage.setItem(
                MSG_KEY, JSON.stringify(conversation)
            );
        }
        const clearData = () => {
            setAltro({...altro, loading:false, errore:null});
            setContactFormData({loading:false, errore:null, send:false, chiudi:false, operator:false});
        }
        if(conversation) aggCache();
        if(!conversation) clearData();
    }, [conversation])

    const createConversation = async(agg = false) => {
        const result = await conversationApi.createConversation({
            chatbotId:chatbot.id, source:source
        });
        if(!result.ok) return setAltro({...altro, loading:false, errore:t('alert.nuovaConv')});
        if(agg) setConversation(result.data);
        return result.data;
    }

    const inviaMessaggio = async(content) => {
        //controlli iniziali
        if(!altro.caricamento || !chatbot) return
        if(altro.privacyPolicy) return
        if(!content?.length) return
        if(altro.loading) return
        setContents([])
        setAltro({...altro, errore:null, loading:true})
        //gestisco la conversazione
        let internalConv = conversation;
        if(!internalConv) internalConv = await createConversation();
        if(!internalConv) return;
        internalConv.messages.push({role:'user', content:content});
        setConversation({...internalConv});
        //preparo i dati del messaggio
        const sendData = {
            message: content,
            conversationId: internalConv.id,
            onboardingStatus: altro.onboardingStatus
        }
        //invio richiesta di messaggio
        const response = await fetch(API_URL + '/conversation/message', {
            method: "POST",
            cache: "no-cache",
            keepalive: true,
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/event-stream",
                "X-Accel-Buffering": "no"
            },
            body: JSON.stringify(sendData)
        });
        //controllo errore
        if(!response.ok) {
            //pulisco il messaggio
            internalConv.messages = internalConv.messages.slice(0, internalConv.messages.length - 1);
            setConversation({...internalConv});
            //stampo il messaggio di errore
            const reader = response.body.getReader();
            const {value} = await reader.read();
            let text = new TextDecoder().decode(value);
            const body = JSON.parse(text);
            if(body.isMessagesLimit) return setAltro({...altro, loading:false, errore:t('alert.limiteSuperato')})
            if(body.isBlockLimit) return setAltro({...altro, loading:false, errore:chatbot.limitMessage[lang]})
            if(body.isOpenaiKey) return setAltro({...altro, loading:false, errore:t('alert.problemaChiave1')})
            return setAltro({...altro, loading:false, errore:t('alert.troppeRichieste')})
        }
        //gestisco lo streaming del testo
        const reader = response.body.getReader();
        let text = null, totale = '';
        while (true) {
            const {value, done} = await reader.read();
            text = new TextDecoder().decode(value);
            if(text) {
                if(text.includes('[ERROR]')) {
                    return setTimeout(() => {
                        internalConv.messages = internalConv.messages.slice(0, internalConv.messages.length - 1);
                        setConversation({...internalConv}); setMsg('');
                        setAltro({...altro, loading:false, errore:t('alert.troppeRichieste')})
                    }, 500);
                } else if(text.includes('[ERROR-KEY]')) {
                    return setTimeout(() => {
                        internalConv.messages = internalConv.messages.slice(0, internalConv.messages.length - 1);
                        setConversation({...internalConv}); setMsg('');
                        setAltro({...altro, loading:false, errore:t('alert.problemaChiave2')})
                    }, 500);
                } else if(text.includes('[CONTENTS]')) {
                    setContents(JSON.parse(text.replace('[CONTENTS]', '')));
                } else {
                    totale += text
                    setMsg(totale)
                }
            }
            if(done) break;
        }
        //confermo il messaggio
        if(totale?.length > 0) internalConv.messages.push({role:'assistant', content:totale});
        setConversation({...internalConv});
        //pulisco i dati
        setMsg('');
        setAltro({...altro, errore:null, loading:false});
    }

    const confermaFormContatto = async(msg) => {
        //controllo conversazione
        let internalConv = conversation;
        if(!internalConv) internalConv = await createConversation(true);
        if(!internalConv) return;
        //preparo il messaggio
        let value = ''
        if(msg.fullName?.length) value += '{{name}}: ' + msg.fullName + '\n'
        if(msg.email?.length) value += '{{email}}: ' + msg.email + '\n'
        if(msg.phone?.length) value += '{{phone}}: ' + msg.phone + '\n'
        //invio i dati
        setContactFormData({...contactFormData, loading:true});
        const sendData = {
            accountId:chatbot.accountId, conversationId:internalConv.id,
            formData:msg, message:value, chatbotId:chatbot.id, operator:false
        }
        const result = await conversationApi.createContactMessage(sendData);
        if(!result.ok) return setTimeout(() => {
            setContactFormData({...contactFormData, loading:false, errore:result.data.message});
        }, 300);
        //confermo l'invio
        setTimeout(() => {
            setContactFormData({...contactFormData, loading:false, errore:null, send:true});
            setTimeout(() => {
                let internalConv = {...conversation};
                internalConv.messages.push(result.data);
                setConversation(internalConv);
                setContactFormData({...contactFormData, loading:false, errore:null, send:false});
            }, 3000);
        }, 500);
    }

    const reloadChat = () => {
        if(altro.loading) return;
        setAltro({...altro, loading:true, errore:null});
        setContents([]);
        setConversation(null);
        localStorage.removeItem(MSG_KEY);
        setTimeout(() => {
            setAltro({...altro, loading:false, errore:null})
            setContactFormData({loading:false, errore:null, send:false, chiudi:false, operator:false})
        }, 300);
    }

    const accettaPolicy = () => {
        setAltro({...altro, privacyPolicy:false});
        let cookie = {date:new Date(), code:chatbot.code};
        localStorage.setItem(PRIVACY_KEY, JSON.stringify(cookie));
    }

    return (
        <Box sx={{
            width:'100%', height:{xs:height, md:'100vh'}, 
            display:'flex', flexDirection:'column', overflow:'hidden'
        }}>
            <Box sx={{display:'flex', flexDirection:'column', borderRadius:isBackend ? '20px' : 'none', overflow:'hidden',
                height:isBackend ? {xs:'650px', sm:'570px', md:'570px', xl:'725px'} : {xs:height, md:'100vh'},
                border:isBackend ? '1px solid #dcdcdc' : 'none', backgroundColor:isDark ? 'black' : 'white'
            }}>
                <HeaderChatFrame
                    chatbot={chatbot}
                    reloadChat={reloadChat}
                ></HeaderChatFrame>
                <CorpoChatFrame
                    lang={lang}
                    chatbot={chatbot}
                    nuovoMessaggio={msg}
                    errore={altro.errore}
                    loading={altro.loading}
                    caricamento={altro.caricamento}
                    contactFormData={contactFormData}
                    messaggi={conversation?.messages || []}
                    isPrivacyPolicy={altro.privacyPolicy && source != 'backend'}
                    accettaPolicy={accettaPolicy}
                    confermaFormContatto={confermaFormContatto}
                    apriFormContatto={(v) => setContactFormData({...contactFormData, chiudi:v})}
                ></CorpoChatFrame>
                <FooterChatFrame
                    lang={lang}
                    chatbot={chatbot}
                    loading={altro.loading}
                    caricamento={altro.caricamento}
                    branding={subscription?.metrics?.branding}
                    inviaMessaggio={inviaMessaggio}
                ></FooterChatFrame>
            </Box>

            {isBackend && contents.length > 0 &&
                <ContenutiChatFrame contents={contents} />
            }
        </Box>
    );
}

export default ChatFrame;