import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import LogoIT from '../../images/it.png';
import LogoEN from '../../images/en.png';
import LogoDE from '../../images/german.png';
import LogoFR from '../../images/france.png';
import LogoES from '../../images/spain.png';

function ElencoLingue({lingue, premiLingua, isGrande, disponibili}) {
    
    return (
        <Stack
            direction='row'
            spacing={isGrande ? {xs:'16px', sm:'24px'} : {xs:'8px', sm:'16px'}}
            sx={{
                margin:isGrande ? 'none' : {xs:'-4px 0 8px', sm:'-4px 0 12px'}
            }}
        >
            {disponibili.includes('en') && <Box
                component='img' alt=''
                sx={{
                    opacity:lingue.includes('en') ? 1 : 0.3,
                    width:isGrande ? '32px' : '26px', height:isGrande ? '32px' : '26px', cursor:'pointer'
                }}
                onClick={() => premiLingua('en')}
                src={LogoEN}
            />}
            {disponibili.includes('it') && <Box
                component='img' alt=''
                sx={{
                    opacity:lingue.includes('it') ? 1 : 0.3,
                    width:isGrande ? '32px' : '26px', height:isGrande ? '32px' : '26px', cursor:'pointer'
                }}
                onClick={() => premiLingua('it')}
                src={LogoIT}
            />}
            {disponibili.includes('de') && <Box
                component='img' alt=''
                sx={{
                    opacity:lingue.includes('de') ? 1 : 0.3,
                    width:isGrande ? '32px' : '26px', height:isGrande ? '32px' : '26px', cursor:'pointer'
                }}
                onClick={() => premiLingua('de')}
                src={LogoDE}
            />}
            {disponibili.includes('fr') && <Box
                component='img' alt=''
                sx={{
                    opacity:lingue.includes('fr') ? 1 : 0.3,
                    width:isGrande ? '32px' : '26px', height:isGrande ? '32px' : '26px', cursor:'pointer'
                }}
                onClick={() => premiLingua('fr')}
                src={LogoFR}
            />}
            {disponibili.includes('es') && <Box
                component='img' alt=''
                sx={{
                    opacity:lingue.includes('es') ? 1 : 0.3,
                    width:isGrande ? '32px' : '26px', height:isGrande ? '32px' : '26px', cursor:'pointer'
                }}
                onClick={() => premiLingua('es')}
                src={LogoES}
            />}
        </Stack>
    );
}

export default ElencoLingue;