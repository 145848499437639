import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function BottoneHeader({text, active, onClick}) {

    return (
        <Box sx={{height:'100%', display:'flex', alignItems:'center', boxSizing:'border-box',
            borderBottom: active ? '4px solid' : '4px solid', zIndex:10, 
            borderColor: active ? 'primary.main' : 'transparent'
        }}>
            <Button
                type="submit"
                color="nero"
                variant="text"
                sx={{
                    border:'none', padding:'0', textTransform: 'none',
                    '&:hover': {backgroundColor:'white'}
                }}
                disableElevation={true}
                disableRipple={true}
                onClick={onClick}
            >
                <Typography component='span' sx={{fontSize:{xs:'20px', md:'22px'}, fontWeight:'500', lineHeight:'normal', 
                    letterSpacing:'-0.2', textAlign:'center', color:'black'
                }}>{text}</Typography>
            </Button>
        </Box>
    );
}

export default BottoneHeader;