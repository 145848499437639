import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import utils from '../../libs/utils';

function RiassuntoStatistiche({conversations, loading}) {

    const [stats, setStats] = useState({conversations:0, messages:0, contacts:0});

    useEffect(() => {
        const aggStats = () => {
            let contM = 0, contC = 0, contS = 0
            for(let i = 0; i < conversations.length; i++) {
                contC++; 
                contM += conversations[i].messages.length;
                for(let j = 0; j < conversations[i].messages.length; j++) {
                    if(conversations[i].messages[j].metadata?.contacts) contS++;
                }
            }
            setStats({conversations:contC, messages:contM, contacts:contS})
        }
        aggStats()
    }, [conversations])
    
    return (
        <Box sx={{width:'100%', margin:{xs:'24px 0 12px', sm:'20px 0 0'}}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:{xs:'title.main', sm:'black'},
                fontSize:{xs:'16px', sm:'20px'}, fontWeight:'500', lineHeight:'normal', width:'100%'
            }}>Riassunto:</Typography>

            <Stack direction={{xs:'column', md:'row'}} spacing={{xs:'8px', md:'24px'}} sx={{
                marginTop:{xs:'4px', sm:'6px'}, width:'100%'
            }}>
                <Box sx={{display:'flex', flex:1, flexDirection:'column', alignItems:'center', justifyContent:'center', 
                    minHeight:'100%', padding:{xs:'24px', lg:'20px 40px'}, border:'1px solid #dcdcdc', borderRadius:'20px', backgroundColor:'white'
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'14px', fontWeight:'400', lineHeight:'normal'
                    }}>Numero di Conversazioni</Typography>
                    {loading ? (
                        <Skeleton variant="rounded" width={'130px'} height={'18px'} animation="wave" sx={{marginTop:'4px'}} />
                    ) : (
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                            fontSize:'16px', fontWeight:'600', lineHeight:'normal', marginTop:'4px'
                        }}>{utils.formatNumber(stats.conversations)}</Typography>
                    )}
                </Box>

                <Box sx={{display:'flex', flex:1, flexDirection:'column', alignItems:'center', justifyContent:'center', 
                    minHeight:'100%', padding:{xs:'24px', lg:'20px 40px'}, border:'1px solid #dcdcdc', borderRadius:'20px', backgroundColor:'white'
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'14px', fontWeight:'400', lineHeight:'normal'
                    }}>Numero di Messaggi</Typography>
                    {loading ? (
                        <Skeleton variant="rounded" width={'130px'} height={'18px'} animation="wave" sx={{marginTop:'4px'}} />
                    ) : (
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                            fontSize:'16px', fontWeight:'600', lineHeight:'normal', marginTop:'4px'
                        }}>{utils.formatNumber(stats.messages)}</Typography>
                    )}
                </Box>

                <Box sx={{display:'flex', flex:1, flexDirection:'column', alignItems:'center', justifyContent:'center', 
                    minHeight:'100%', padding:{xs:'24px', lg:'20px 40px'}, border:'1px solid #dcdcdc', borderRadius:'20px', backgroundColor:'white'
                }}>
                    <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'#344054',
                        fontSize:'14px', fontWeight:'400', lineHeight:'normal'
                    }}>Contatti generati</Typography>
                    {loading ? (
                        <Skeleton variant="rounded" width={'130px'} height={'18px'} animation="wave" sx={{marginTop:'4px'}} />
                    ) : (
                        <Typography sx={{textAlign:'center', letterSpacing:'-0.2', color:'black',
                            fontSize:'16px', fontWeight:'600', lineHeight:'normal', marginTop:'4px'
                        }}>{parseInt(stats.contacts)}</Typography>
                    )}
                </Box>
            </Stack>
        </Box>
    );
}

export default RiassuntoStatistiche;