import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import chatbotApi from '../../api/chatbot';
import AppAlert from '../generale/AppAlert';
import IntegrazioneWA from './IntegrazioneWA';
import BottoneSalva from '../generale/BottoneSalva';
import useAgency from '../../controllers/agency/useAgency';

import PowerIcon from '@mui/icons-material/Power';

function ZonaIntegrazioni({visible, isAdmin, isEditor, chatbot, goToCondividi, aggChatbot}) {
    
    const {agency} = useAgency();

    const WA = chatbot.waIntegration;
    const [altro, setAltro] = useState({errore:null, loading:0, openAlert:0});

    const confermaDatiWA = async(values) => {
        if(altro.loading) return;
        setAltro({...altro, errore:null, loading:2});
        const result = await chatbotApi.updateWaData(agency.id, {
            chatbotId:chatbot.id, ...values
        });
        if(!result.ok) return setTimeout(() => {
            setAltro({...altro, errore:result.data.message, loading:0});
        }, 500);
        setTimeout(() => {
            const chatbotData = {...chatbot};
            chatbotData.waIntegration = {...result.data};
            aggChatbot(chatbotData);
            setAltro({...altro, errore:null, loading:0, openAlert:1});
        }, 800);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return   
        setAltro({...altro, openAlert:0, errore:null});
    }

    return (
        <Box sx={{position:'relative'}}>
            <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'primary.main',
                fontSize:'18px', fontWeight:'600', lineHeight:'normal', margin:'20px 0 2px 4px',
                display:{xs:visible ? 'block' : 'none', sm:'none'}
            }}>Integrazioni</Typography>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'16px', 
                margin:{xs:0, sm:'40px 0 0', lg:'40px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'block' : 'none', boxSizing:'border-box', padding:{xs:'12px 12px', sm:'12px 12px'}
            }}>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Box sx={{width:{xs:'45px', sm:'65px'}, height:{xs:'45px', sm:'65px'}, borderRadius:'16px', backgroundColor:'#f0f0f0', 
                            display:'flex', alignItems:'center', justifyContent:'center', boxSizing:'border-box', padding:{xs:'5px', sm:'8px'}
                        }}>
                            <Box component='img' alt='' src={require('../../images/wa.png')} sx={{width:'100%', height:'auto'}} />
                        </Box>
                        <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                            fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', marginLeft:{xs:'12px', sm:'20px'}
                        }}>Integra con WhatsApp</Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        {!isAdmin && !WA.active &&
                            <Typography sx={{textAlign:'right', letterSpacing:'-0.2', color:'primary.main', textDecorationLine:'underline',
                                fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'1.1', marginRight:{xs:0, sm:'12px'}
                            }}>Chiedi all'Amministratore</Typography>
                        }
                        {!isAdmin && !WA.active &&
                            <Typography sx={{textAlign:'right', letterSpacing:'-0.2', color:'primary.main', textDecorationLine:'underline',
                                fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'1.1', marginRight:{xs:0, sm:'12px'}
                            }}>ATTIVO</Typography>
                        }
                    </Box>
                </Box>
                {isAdmin &&
                    <IntegrazioneWA
                        WA={WA}
                        isEditor={isEditor}
                        conferma={confermaDatiWA}
                        loading={altro.loading === 2}
                    ></IntegrazioneWA>
                }
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'16px', 
                margin:{xs:'16px 0 0', sm:'24px 0 0', lg:'24px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'flex' : 'none', boxSizing:'border-box', padding:{xs:'12px 12px', sm:'12px 12px'},
                flexDirection:'row', alignItems:'center', justifyContent:'space-between'
            }}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Box sx={{width:{xs:'45px', sm:'65px'}, height:{xs:'45px', sm:'65px'}, borderRadius:'16px', backgroundColor:'#f0f0f0', 
                        display:'flex', alignItems:'center', justifyContent:'center', boxSizing:'border-box', padding:{xs:'5px', sm:'8px'}
                    }}>
                        <Box component='img' alt='' src={require('../../images/wp.png')} sx={{width:'85%', height:'auto'}} />
                    </Box>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', marginLeft:{xs:'12px', sm:'20px'}
                    }}>Integra con Wordpress</Typography>
                </Box>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <BottoneSalva
                        loading={false}
                        text={'Integra'}
                        Icon={PowerIcon}
                        isPiccolo={true}
                        onClick={() => goToCondividi('wordpress')}
                    ></BottoneSalva>
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'white', borderRadius:'16px', 
                margin:{xs:'16px 0 0', sm:'24px 0 0', lg:'24px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'flex' : 'none', boxSizing:'border-box', padding:{xs:'12px 12px', sm:'12px 12px'},
                flexDirection:'row', alignItems:'center', justifyContent:'space-between'
            }}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Box sx={{width:{xs:'45px', sm:'65px'}, height:{xs:'45px', sm:'65px'}, borderRadius:'16px', backgroundColor:'#f0f0f0', 
                        display:'flex', alignItems:'center', justifyContent:'center', boxSizing:'border-box', padding:{xs:'5px', sm:'8px'}
                    }}>
                        <Box component='img' alt='' src={require('../../images/shopi.png')} sx={{width:'85%', height:'auto'}} />
                    </Box>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'normal', marginLeft:{xs:'12px', sm:'20px'}
                    }}>Integra con Shopify</Typography>
                </Box>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <BottoneSalva
                        loading={false}
                        text={'Integra'}
                        Icon={PowerIcon}
                        isPiccolo={true}
                        onClick={() => goToCondividi('shopify')}
                    ></BottoneSalva>
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'#dcdcdc', borderRadius:'16px', 
                margin:{xs:'16px 0 0', sm:'24px 0 0', lg:'24px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'flex' : 'none', boxSizing:'border-box', padding:{xs:'12px 12px', sm:'12px 12px'},
                flexDirection:'row', alignItems:'center', justifyContent:'space-between'
            }}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Box sx={{width:{xs:'45px', sm:'65px'}, height:{xs:'45px', sm:'65px'}, borderRadius:'16px', backgroundColor:'#f0f0f0', 
                        display:'flex', alignItems:'center', justifyContent:'center', boxSizing:'border-box', padding:{xs:'5px', sm:'8px'}
                    }}>
                        <Box component='img' alt='' src={require('../../images/messenger.png')} sx={{width:'85%', height:'auto', opacity:0.2}} />
                    </Box>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'1.1', marginLeft:{xs:'12px', sm:'20px'}
                    }}>Integra con Facebook Messenger</Typography>
                </Box>
                <Box sx={{display:'flex', alignItems:'center', marginRight:{xs:0, sm:'12px'}}}>
                    <Typography sx={{textAlign:'right', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'1.1'
                    }}>Disponibile Prossimamente</Typography>
                </Box>
            </Box>

            <Box sx={{width:{xs:'100%', lg:'calc(100% - 80px)'}, backgroundColor:'#dcdcdc', borderRadius:'16px', 
                margin:{xs:'16px 0 0', sm:'24px 0 0', lg:'24px 40px 0'}, overflow:'hidden', border:'1px solid #dcdcdc', 
                display: visible ? 'flex' : 'none', boxSizing:'border-box', padding:{xs:'12px 12px', sm:'12px 12px'},
                flexDirection:'row', alignItems:'center', justifyContent:'space-between'
            }}>
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Box sx={{width:{xs:'45px', sm:'65px'}, height:{xs:'45px', sm:'65px'}, borderRadius:'16px', backgroundColor:'#f0f0f0', 
                        display:'flex', alignItems:'center', justifyContent:'center', boxSizing:'border-box', padding:{xs:'5px', sm:'8px'}
                    }}>
                        <Box component='img' alt='' src={require('../../images/slack.png')} sx={{width:'85%', height:'auto', opacity:0.2}} />
                    </Box>
                    <Typography sx={{textAlign:'left', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'16px', sm:'20px'}, fontWeight:'600', lineHeight:'1.1', marginLeft:{xs:'12px', sm:'20px'}
                    }}>Integra con Slack Workspace</Typography>
                </Box>
                <Box sx={{display:'flex', alignItems:'center', marginRight:{xs:0, sm:'12px'}}}>
                    <Typography sx={{textAlign:'right', letterSpacing:'-0.2', color:'black',
                        fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500', lineHeight:'1.1'
                    }}>Disponibile Prossimamente</Typography>
                </Box>
            </Box>

            <AppAlert
                open={altro.openAlert === 1}
                chiudiAlert={chiudiAlert}
                severity={"success"}
                message={"WhatsApp aggiornato con successo!"}
            ></AppAlert>
            <AppAlert
                open={altro.errore}
                chiudiAlert={chiudiAlert}
                severity={"error"}
                message={altro.errore}
            ></AppAlert>
        </Box>
    );
}

export default ZonaIntegrazioni;