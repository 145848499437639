import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

function BottoneLogin({text, loading, onClick, disabled}) {

    return (
        <LoadingButton
            type="submit"
            color={"primary"}
            variant="contained"
            sx={{
                width:'100%', padding:{xs:'8px 24px', sm:'12px 40px'},
                borderRadius:'12px', textTransform: 'none', border:'1px solid #dcdcdc'
            }}
            disableElevation={true}
            onClick={onClick}
            loading={loading}
            loadingPosition={'center'}
            disabled={disabled ? true : false}
        >
            <Typography component='span' sx={{fontSize:{xs:'13px', sm:'16px'}, fontWeight:'500',
                lineHeight:'normal', letterSpacing:'-0.2', textAlign:'left'
            }}>{text}</Typography>
        </LoadingButton>
    );
}

export default BottoneLogin;